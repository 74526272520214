export const tableColumnsWidths = {
  actionsXlMax: 180,
  selectionMin: 48,
  selection: 48,
  selectionMax: 48,
  courseNameAndPicMin: 420,
  courseNameAndPic: 420,
  courseNameAndPicMax: 420,
  courseNameAndPicBundleMin: 350,
  courseNameAndPicBundleMax: 350,
  courseNameMin: 180,
  courseNameMax: 180,
  userNameAndPicMin: 180,
  userNameAndPicMax: 180,
  coursePriceDateMin: 120,
  coursePriceDateMax: 120,
  courseVideosCountMin: 80,
  courseVideosCountMax: 80,
  transactionHashMin: 150,
  transacitonHashMax: 150,
  purchaseStatus: 180,
  contextMenu: 48,
  reportDataMin: 420,
  reportDataMax: 420,
  reportTotalSoldMin: 120,
  reportTotalSoldMax: 120
};
