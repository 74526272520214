import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Sun } from 'assets/icons/sun.svg';
import { ReactComponent as Moon } from 'assets/icons/moon.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as Chevron } from 'assets/icons/arrowShort.svg';
import { popupContext } from 'context/popupContext';
import useComponentVisible from 'hooks/useComponentVisible';
import Button from 'components/buttons/Button';
import Divider from 'components/divider/Divider';
import Avatar from 'components/avatar/Avatar';
import { userContext } from 'context/userContext';
import { clearTokenFromStorage } from 'utils/storage';
import { USER_KYC_STATUS } from 'query/acc-module/dto';
import { generateCSV } from 'query/course-module/queries';
import { appContext } from 'context/appContext';
import Badge from 'components/badge/Badge';
import Tooltip from 'components/tooltip/Tooltip';
import PlatformFeePopup from 'components/popup/PlatformFeePopup';
import VideoFeePopup from 'components/popup/VideoFeePopup';
import VerifiedBadge from 'components/badge/VerifiedBadge';
import { formatNames } from 'utils/format';
import classes from './UserMenu.module.scss';

interface IUserMenuRow {
  text: string;
  amount?: number;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconRotate?: number;
  iconPosition?: 'left' | 'right';
  onClick: () => void;
}

const UserMenuRow = ({
  text,
  amount,
  icon,
  onClick,
  iconRotate,
  iconPosition
}: IUserMenuRow) => {
  const Icon = icon;
  const renderIcon = () =>
    !!Icon && (
      <Icon
        width={24}
        height={24}
        style={{
          transform: iconRotate ? `rotate(${iconRotate}deg)` : ''
        }}
      />
    );

  return (
    <div className={classes['user-menu-row']} onClick={onClick}>
      <div className={classes['user-menu-row--text']}>
        {!!Icon && iconPosition === 'left' && renderIcon()}
        <div>{text}</div>
      </div>
      {!!Icon && iconPosition === 'right' && renderIcon()}
      {!!amount && (
        <div className={classes['user-menu-row--amount']}>{amount}</div>
      )}
    </div>
  );
};

UserMenuRow.defaultProps = {
  iconPosition: 'right'
};

export const UserMenu = () => {
  const { userData, clearUser, newCertificates } = useContext(userContext);
  const { theme, switchTheme } = useContext(appContext);
  const { setPopup } = useContext(popupContext);
  const { isComponentVisible, setIsComponentVisible, ref } =
    useComponentVisible(false);
  const navigate = useNavigate();

  const logOutHandler = () => {
    clearUser();
    clearTokenFromStorage();
    navigate('/login');
  };

  const { first_name, last_name, pic, kyc_status, isAdmin } = userData;

  return (
    <div
      className={classes['user']}
      ref={ref}
      onMouseEnter={() => setIsComponentVisible(!isComponentVisible)}
      onMouseLeave={() => setIsComponentVisible(!isComponentVisible)}
    >
      <div
        className={`
          ${classes['avatar']}  
          ${
            !isAdmin &&
            classes[
              `avatar__${
                kyc_status === USER_KYC_STATUS.NOT_STARTED ||
                kyc_status === USER_KYC_STATUS.CREATED ||
                kyc_status === USER_KYC_STATUS.REJECTED
                  ? 'error'
                  : kyc_status === USER_KYC_STATUS.PROCESSED ||
                    kyc_status === USER_KYC_STATUS.UPDATED
                  ? 'warning'
                  : ''
              }`
            ]
          }`}
      >
        <Avatar
          src={pic}
          size="sm"
          isBorderless
          onClick={() => navigate('/user')}
          hasNotifications
        />
      </div>
      {isComponentVisible && (
        <div className={classes['user-menu-wrapper']}>
          <div className={classes['accessibility-support']} />
          <div className={classes['user-menu']}>
            <Avatar src={pic} onClick={() => navigate('/user')} />
            <div className={classes['info']}>
              <div className={`${classes['u-semiBold']} ${classes['u-h5']}`}>
                {formatNames(first_name + ' ' + last_name)}
              </div>
              {!isAdmin && (
                <>
                  {(kyc_status === USER_KYC_STATUS.NOT_STARTED ||
                    kyc_status === USER_KYC_STATUS.CREATED ||
                    kyc_status === USER_KYC_STATUS.REJECTED) && (
                    <Tooltip
                      id="not-verified"
                      text={
                        kyc_status === USER_KYC_STATUS.REJECTED
                          ? 'Your account verification has been rejected.'
                          : "In order to purchase or publish courses, you'll need to verify your account."
                      }
                      position="bottom"
                    >
                      <Badge
                        text="Profile not Verified"
                        badgeType="error"
                        type="kyc"
                        onClick={() =>
                          navigate('/user/about/main-info', {
                            state: {
                              isVerifyOpened: true
                            }
                          })
                        }
                      />
                    </Tooltip>
                  )}
                  {(kyc_status === USER_KYC_STATUS.PROCESSED ||
                    kyc_status === USER_KYC_STATUS.UPDATED) && (
                    <Tooltip
                      id="not-verified"
                      text="Please wait until your verification is processed."
                      position="bottom"
                    >
                      <Badge
                        text="Verification Pending"
                        badgeType="warning"
                        type="kyc"
                      />
                    </Tooltip>
                  )}
                  {kyc_status === USER_KYC_STATUS.APPROVED && (
                    <VerifiedBadge
                      isVisible={kyc_status === USER_KYC_STATUS.APPROVED}
                    />
                  )}
                </>
              )}
            </div>
            <Divider />
            {!userData?.isAdmin && (
              <>
                <div className={classes['rows-container']}>
                  {/* TODO:
                  <UserMenuRow
                    text="Wishlist"
                    onClick={() => navigate('')}
                  /> 
                */}
                  <UserMenuRow
                    text="About"
                    amount={newCertificates.length || 0}
                    onClick={() => navigate('/user/about')}
                  />
                  <UserMenuRow
                    text="Purchased Courses"
                    onClick={() => {
                      setIsComponentVisible(false);
                      navigate('/user/purchased-courses/activated');
                    }}
                  />
                  <UserMenuRow
                    text="Certificates"
                    amount={newCertificates.length || 0}
                    onClick={() => navigate('/user/certificates')}
                  />
                  <UserMenuRow
                    text="Created Courses"
                    onClick={() => {
                      setIsComponentVisible(false);
                      navigate('/user/created-courses/published');
                    }}
                  />
                  <UserMenuRow
                    text="Transactions"
                    onClick={() => {
                      setIsComponentVisible(false);
                      navigate(
                        userData?.is_creator ? '/user/sales' : '/user/purchases'
                      );
                    }}
                  />
                </div>
              </>
            )}
            {userData?.isAdmin && (
              <div className={classes['rows-container']}>
                <UserMenuRow
                  text="Change Platform Fee"
                  onClick={() => setPopup(<PlatformFeePopup />)}
                  icon={Chevron}
                  iconRotate={90}
                />
                <UserMenuRow
                  text="Set Video Price"
                  onClick={() => setPopup(<VideoFeePopup />)}
                  icon={Chevron}
                  iconRotate={90}
                />
                <UserMenuRow
                  text="Static Pages"
                  onClick={() => navigate('/admin/static-pages')}
                />
              </div>
            )}
            <Divider />
            <Button
              className={classes['theme-btn']}
              variant="link-neutral"
              size="sm"
              icon={theme === 'dark' ? Sun : Moon}
              onClick={switchTheme}
              iconPosition="left"
              minWidth="full"
              align="left"
            >
              {theme === 'dark' ? 'Light' : 'Dark'} Theme
            </Button>
            <Divider />
            {!isAdmin && (
              <Button
                onClick={() => {
                  navigate('/user');
                  setIsComponentVisible(false);
                }}
                minWidth="full"
                size="sm"
              >
                Open Profile
              </Button>
            )}
            {isAdmin && (
              <Button icon={Download} variant="neutral" onClick={generateCSV}>
                Export Users as CSV
              </Button>
            )}
            <Button
              variant="link-neutral"
              size="sm"
              onClick={logOutHandler}
              withPadding={false}
            >
              Log out
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
