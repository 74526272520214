import { ReactComponent as ShowIcon } from 'assets/icons/show-purple.svg';
import { ReactComponent as HideIcon } from 'assets/icons/hide-purple.svg';
import Button from 'components/buttons/Button';
import Divider from 'components/divider/Divider';
import classes from './StepRow.module.scss';

interface IStepRow {
  index: number;
  step: {
    step: string;
    guide: string;
    action?: string;
    actionWithLink?: string;
  };
  selected: number;
  setSelected: (index: number) => void;
}

const StepRow = ({ index, step, selected, setSelected }: IStepRow) => {
  const isSelected = index === selected;

  return (
    <div>
      <div className={classes['step-field']}>
        <div
          className={classes['step-field__name']}
          onClick={() => setSelected(isSelected ? -1 : index)}
        >
          <Button
            variant="link-neutral"
            icon={isSelected ? HideIcon : ShowIcon}
            onClick={() => setSelected(isSelected ? -1 : index)}
          />
          <h5 className={`${classes['u-body1']} ${classes['u-semiBold']}`}>{`${
            index + 1
          }. ${step.step}`}</h5>
        </div>
      </div>
      {isSelected && (
        <div className={classes['guide-field']}>
          <div className={classes['divider']}>
            <Divider dir="vertical" />
          </div>
          <div>
            <h5
              className={`${classes['u-body1']} ${classes['u-text--content']} `}
            >
              {step.guide}
            </h5>
            <br />
            {!!step.actionWithLink ? (
              <h5
                className={`${classes['u-body1']} ${classes['u-text--content']} `}
                dangerouslySetInnerHTML={{ __html: step.actionWithLink }}
              />
            ) : (
              <h5
                className={`${classes['u-body1']} ${classes['u-text--content']} `}
              >
                {step.action}
              </h5>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StepRow;
