import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import moment from 'moment';
import { useAccount } from 'wagmi';
import RefundPopup from 'routes/dashboard/courses/single/components/popups/RefundPopup';
import { ReactComponent as USDC } from 'assets/images/usdc.svg';
// import { IndeterminateCheckbox } from './InterdeterminateCheckbox';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import WalletAddress from 'components/header/wallet/WalletAddress';
import Button from 'components/buttons/Button';
import Tooltip from 'components/tooltip/Tooltip';
import ContextMenu from 'components/buttons/ContextMenu';
import ClaimFundsPopup from 'components/popup/ClaimFundsPopup';
import ManualRefundPopup from 'components/popup/ManualRefundPopup';
import { IPurchasedItem } from 'query/course-module/dto';
import { dateFormatUrl } from 'utils/static';
import { formatAddress } from 'utils/format';
import {
  REACT_APP_BLOCK_EXPLORER_URL,
  TRANSACTION_STATUS
} from 'utils/constants';
import { tableColumnsWidths } from './utils/table-utils';
import { IndeterminateCheckbox } from './InterdeterminateCheckbox';
import { Web3Context } from 'context/web3Context';
import { popupContext } from 'context/popupContext';
import classes from './PurchasesCols.module.scss';

interface IPurchasesCols {
  hideActionBtn: boolean;
  isClaimPage?: boolean;
  isSelectable?: boolean;
  isDashboardPage?: boolean;
  isAdmin?: boolean;
  isPurchasesPage?: boolean;
}

export const PurchasesCols = ({
  hideActionBtn,
  isClaimPage,
  isSelectable,
  isDashboardPage,
  isPurchasesPage,
  isAdmin
}: IPurchasesCols) => {
  const cols: Column[] = [
    {
      Header: 'Name',
      accessor: 'name',
      maxWidth: tableColumnsWidths.courseNameMax,
      minWidth: tableColumnsWidths.courseNameMin,
      Cell: ({ row }: { row: { original: IPurchasedItem } }) => {
        console.log(row.original);
        const isBundle = !!row?.original?.bundle;
        const slug = !!row?.original?.course
          ? row.original.course.slug
          : row.original.bundle.slug;
        return (
          <Link
            to={
              isBundle
                ? `/bundles/${row.original?.bundle?.slug}?changes=0`
                : isDashboardPage || isAdmin
                ? `/admin/courses/${slug}?changes=0`
                : `/courses/${slug}?changes=0`
            }
          >
            <TextWithCharLimit
              text={row.original.course_name || row.original.bundle_name || ''}
              limit={30}
              hideBtn
            />
          </Link>
        );
      }
    },
    {
      Header: 'Creator',
      accessor: 'creator',
      Cell: ({ row }: { row: { original: IPurchasedItem } }) => (
        <Link
          to={`/user/${
            row.original.course_creator_slug || row.original.bundle_creator_slug
          }/about/main-info`}
        >
          <TextWithCharLimit
            text={
              row.original.course_creator_name ||
              row.original.bundle_creator_name
            }
            limit={20}
            hideBtn
          />
        </Link>
      )
    },
    {
      Header: 'Creator Wallet',
      accessor: 'creator_wallet',
      minWidth: tableColumnsWidths.transactionHashMin,
      maxWidth: tableColumnsWidths.transacitonHashMax,
      Cell: ({ row }: { row: { original: IPurchasedItem } }) => (
        <WalletAddress
          address={
            row.original.course_creator_wallet ||
            row.original.bundle_creator_wallet
          }
        />
      )
    },
    {
      Header: 'Date Purchased',
      accessor: 'date_purchased',
      minWidth: tableColumnsWidths.coursePriceDateMin,
      maxWidth: tableColumnsWidths.coursePriceDateMax,
      Cell: ({ row }: { row: { original: IPurchasedItem } }) => (
        <>{moment(row.original.buy_tx_confirmed_at).format(dateFormatUrl)}</>
      )
    },
    {
      Header: 'Price',
      accessor: 'price',
      minWidth: tableColumnsWidths.coursePriceDateMin,
      maxWidth: tableColumnsWidths.coursePriceDateMax,
      Cell: ({ row }: { row: { original: IPurchasedItem } }) => (
        <div className={classes['price']}>
          {(!!row.original.course_price || !!row.original.bundle_price) && (
            <Tooltip id="course_price" text="USDC">
              <USDC height={24} width={24} />
            </Tooltip>
          )}
          {row.original?.course_price?.toFixed(2) ||
            row.original?.bundle_price?.toFixed(2) ||
            '-'}
        </div>
      )
    },
    {
      Header: 'Transaction Hash',
      accessor: 'hash',
      minWidth: tableColumnsWidths.transactionHashMin,
      maxWidth: tableColumnsWidths.transacitonHashMax,
      Cell: ({ row }: { row: { original: IPurchasedItem } }) => (
        <Link
          to={`${REACT_APP_BLOCK_EXPLORER_URL}/tx/${row.original.buy_tx_hash}`}
          target="_blank"
        >
          {formatAddress(row.original.buy_tx_hash)}
        </Link>
      )
    }
  ];

  if (!hideActionBtn)
    cols.push({
      Header: 'Status',
      accessor: 'status',
      maxWidth: tableColumnsWidths.purchaseStatus,
      minWidth: tableColumnsWidths.purchaseStatus,
      Cell: ({ row }: { row: { original: IPurchasedItem } }) => {
        const { setPopup } = useContext(popupContext);
        const { isCorrectChain, isWalletUnique } = useContext(Web3Context);
        const { isConnected } = useAccount();

        let component;
        switch (row.original.action) {
          case TRANSACTION_STATUS.CLAIMED:
            component = (
              <div
                className={
                  classes[
                    `${
                      isClaimPage && row.original.can_manually_refund
                        ? 'status__manual-refund'
                        : 'status__manual-completed'
                    }`
                  ]
                }
              >
                <h5 className={classes['status__claimed']}>Claimed</h5>
                {row.original.is_manually_refunded && (
                  <h5
                    className={`${classes['u-body3']} ${classes['status__manual']}`}
                  >
                    Manually Refunded
                  </h5>
                )}
              </div>
            );
            break;
          case TRANSACTION_STATUS.REFUNDED:
            component = (
              <h5 className={classes['status__refund']}>Refund Complete</h5>
            );
            break;
          case TRANSACTION_STATUS.CLAIM:
            component = isDashboardPage ? (
              <h5 className={classes['status__ready']}>Ready To Claim</h5>
            ) : // Manually Refunded status
            row.original.is_manually_refunded ? (
              <div className={classes['status__manual-refund']}>
                <h5
                  className={`${classes['u-body3']} ${classes['status__manual']}`}
                >
                  Manually Refunded
                </h5>
              </div>
            ) : // If the course is not active, show only claim funds button
            !row.original.active || isAdmin ? (
              <Tooltip
                id="claim-button"
                text={
                  !isConnected || !isCorrectChain || !isWalletUnique
                    ? `In order to Claim your funds, please 
              ${
                !isConnected
                  ? 'connect your wallet first.'
                  : !isCorrectChain
                  ? 'switch to the Polygon network.'
                  : !isWalletUnique
                  ? 'switch your wallet. This one is associated with another account.'
                  : ''
              }`
                    : ''
                }
              >
                <Button
                  isWeb3
                  variant="neutral"
                  isLoading={
                    // Claiming state
                    isAdmin
                      ? !!row.original?.admin_fee_collect_tx_started_at &&
                        !row.original?.admin_fee_collect_tx_confirmed_at
                      : !!row.original?.creator_fee_claim_tx_started_at &&
                        !row.original?.creator_fee_claim_tx_confirmed_at
                  }
                  onClick={() =>
                    setPopup(
                      <ClaimFundsPopup
                        data={[row.original]}
                        isAdminPage={isAdmin}
                      />
                    )
                  }
                >
                  Claim Funds
                </Button>
              </Tooltip>
            ) : (
              <></>
            );
            break;
          case TRANSACTION_STATUS.REFUND:
            component = (
              <Tooltip
                id="refund-button"
                text={
                  !isConnected || !isCorrectChain || !isWalletUnique
                    ? `In order to get refund, please 
          ${
            !isConnected
              ? 'connect your wallet first.'
              : !isCorrectChain
              ? 'switch to the Polygon network.'
              : !isWalletUnique
              ? 'switch your wallet. This one is associated with another account.'
              : ''
          }`
                    : ''
                }
              >
                <Button
                  isWeb3
                  size="sm"
                  minWidth="sm"
                  variant="neutral"
                  isLoading={!!row.original?.refund_tx_started_at}
                  onClick={() =>
                    setPopup(
                      <RefundPopup
                        data={{
                          purchaseId: row.original._id,
                          receiptId: row.original.receipt_id,
                          activation_nft_image:
                            row.original?.course?.activation_nft_image ||
                            row.original?.bundle?.activation_nft_image ||
                            ''
                        }}
                      />
                    )
                  }
                >
                  Refund
                </Button>
              </Tooltip>
            );
            break;
          case TRANSACTION_STATUS.LOCKED:
            component = (
              <div
                className={
                  classes[
                    `${
                      row.original.can_manually_refund
                        ? 'status__manual-refund'
                        : 'status__manual-completed'
                    }`
                  ]
                }
              >
                <h5
                  className={
                    classes[
                      isPurchasesPage ? 'status__purchased' : 'status__locked'
                    ]
                  }
                >
                  {isPurchasesPage ? 'Purchased' : 'Locked'}
                </h5>
                {row.original.is_manually_refunded && (
                  <h5
                    className={`${classes['u-body3']} ${classes['status__manual']}`}
                  >
                    Manually Refunded
                  </h5>
                )}
              </div>
            );
            break;
        }
        return <div className={classes['status']}>{component}</div>;
      }
    });

  if (isClaimPage) {
    cols[1] = {
      Header: 'Buyer',
      accessor: 'buyer',
      Cell: ({ row }: { row: { original: IPurchasedItem } }) => (
        <Link to={`/user/${row.original.buyer_slug}/about/main-info`}>
          <TextWithCharLimit
            text={row.original.buyer_name}
            limit={20}
            hideBtn
          />
        </Link>
      )
    };

    cols[2] = {
      Header: 'Buyer Wallet',
      accessor: 'buyer_wallet',
      minWidth: tableColumnsWidths.transactionHashMin,
      maxWidth: tableColumnsWidths.transacitonHashMax,
      Cell: ({ row }: { row: { original: IPurchasedItem } }) => (
        <WalletAddress address={row.original.purchased_by_wallet} />
      )
    };

    cols.splice(4, 0, {
      Header: 'Creator Revenue',
      accessor: 'creator_revenue',
      minWidth: tableColumnsWidths.coursePriceDateMin,
      maxWidth: tableColumnsWidths.coursePriceDateMax,
      Cell: ({ row }: { row: { original: IPurchasedItem } }) => (
        <div className={classes['price']}>
          {!!row.original.creator_revenue && (
            <Tooltip id="course_revenue" text="USDC">
              <USDC height={24} width={24} />
            </Tooltip>
          )}
          {row.original.creator_revenue?.toFixed(2) || '-'}
        </div>
      )
    });
  }

  if (isAdmin) {
    cols.splice(4, 0, {
      Header: 'Platform Fee',
      accessor: 'platform_fee',
      minWidth: tableColumnsWidths.coursePriceDateMin,
      maxWidth: tableColumnsWidths.coursePriceDateMax,
      Cell: ({ row }: { row: { original: IPurchasedItem } }) => (
        <div className={classes['price']}>
          {!!row.original.platform_fee && (
            <Tooltip id="platform_fee" text="USDC">
              <USDC height={24} width={24} />
            </Tooltip>
          )}
          {row.original.platform_fee?.toFixed(2) || '-'}
        </div>
      )
    });
  }

  if (isAdmin || isClaimPage) {
    cols.push({
      Header: '',
      accessor: 'context-menu',
      maxWidth: tableColumnsWidths.contextMenu,
      minWidth: tableColumnsWidths.contextMenu,
      Cell: ({ row }) => {
        const { setPopup } = useContext(popupContext);
        const { isCorrectChain, isWalletUnique } = useContext(Web3Context);
        const { isConnected } = useAccount();

        let component;
        switch (row.original.action) {
          case TRANSACTION_STATUS.CLAIMED:
            // Manual refund is possible only if the course is active and has not been refunded
            if (row.original.can_manually_refund) {
              component = (
                <ContextMenu>
                  <Button
                    variant="neutral"
                    onClick={() =>
                      setPopup(
                        <ManualRefundPopup
                          id={
                            row.original?.course_id || row.original?.bundle_id
                          }
                          userId={row.original.purchased_by_user_id}
                          walletAddress={row.original.purchased_by_wallet}
                          isBundle={!!row.original?.bundle_id}
                        />
                      )
                    }
                  >
                    Manual Refund
                  </Button>
                </ContextMenu>
              );
            }
            break;
          case TRANSACTION_STATUS.CLAIM:
            component =
              // Manual refund is possible only if the course is active and has not been refunded
              row.original.can_manually_refund ? (
                <div className={classes['context-menu__actions']}>
                  <ContextMenu>
                    <Tooltip
                      id="claim-button"
                      text={
                        !isConnected || !isCorrectChain || !isWalletUnique
                          ? `In order to Claim your funds, please 
                    ${
                      !isConnected
                        ? 'connect your wallet first.'
                        : !isCorrectChain
                        ? 'switch to the Polygon network.'
                        : !isWalletUnique
                        ? 'switch your wallet. This one is associated with another account.'
                        : ''
                    }`
                          : ''
                      }
                    >
                      <Button
                        isWeb3
                        variant="neutral"
                        isLoading={
                          // Claiming state
                          isAdmin
                            ? !!row.original?.admin_fee_collect_tx_started_at &&
                              !row.original?.admin_fee_collect_tx_confirmed_at
                            : !!row.original?.creator_fee_claim_tx_started_at &&
                              !row.original?.creator_fee_claim_tx_confirmed_at
                        }
                        onClick={() =>
                          setPopup(
                            <ClaimFundsPopup
                              data={[row.original]}
                              isAdminPage={isAdmin}
                            />
                          )
                        }
                      >
                        Claim Funds
                      </Button>
                    </Tooltip>
                    <Button
                      variant="neutral"
                      onClick={() =>
                        setPopup(
                          <ManualRefundPopup
                            id={
                              row.original?.course_id || row.original?.bundle_id
                            }
                            userId={row.original.purchased_by_user_id}
                            walletAddress={row.original.purchased_by_wallet}
                            isBundle={!!row.original?.bundle_id}
                          />
                        )
                      }
                    >
                      Manual Refund
                    </Button>
                  </ContextMenu>
                </div>
              ) : row.original.is_manually_refunded ? (
                <ContextMenu>
                  <Tooltip
                    id="claim-button"
                    text={
                      !isConnected || !isCorrectChain || !isWalletUnique
                        ? `In order to Claim your funds, please 
                    ${
                      !isConnected
                        ? 'connect your wallet first.'
                        : !isCorrectChain
                        ? 'switch to the Polygon network.'
                        : !isWalletUnique
                        ? 'switch your wallet. This one is associated with another account.'
                        : ''
                    }`
                        : ''
                    }
                  >
                    <Button
                      isWeb3
                      variant="neutral"
                      isLoading={
                        // Claiming state
                        isAdmin
                          ? !!row.original?.admin_fee_collect_tx_started_at &&
                            !row.original?.admin_fee_collect_tx_confirmed_at
                          : !!row.original?.creator_fee_claim_tx_started_at &&
                            !row.original?.creator_fee_claim_tx_confirmed_at
                      }
                      onClick={() =>
                        setPopup(
                          <ClaimFundsPopup
                            data={[row.original]}
                            isAdminPage={isAdmin}
                          />
                        )
                      }
                    >
                      Claim Funds
                    </Button>
                  </Tooltip>
                </ContextMenu>
              ) : (
                <></>
              );
            break;
          case TRANSACTION_STATUS.LOCKED:
            // Manual refund is possible only if the course is active and has not been refunded
            if (row.original.can_manually_refund) {
              component = (
                <ContextMenu>
                  <Button
                    variant="neutral"
                    onClick={() =>
                      setPopup(
                        <ManualRefundPopup
                          id={
                            row.original?.course_id || row.original?.bundle_id
                          }
                          userId={row.original.purchased_by_user_id}
                          walletAddress={row.original.purchased_by_wallet}
                          isBundle={!!row.original?.bundle_id}
                        />
                      )
                    }
                  >
                    Manual Refund
                  </Button>
                </ContextMenu>
              );
            }
            break;
        }
        return <div className={classes['context-menu']}>{component}</div>;
      }
    });
  }

  if (isSelectable) {
    cols.unshift({
      id: 'selection',
      Header: ({ toggleRowSelected, isAllPageRowsSelected, page, rows }) => {
        const modifiedOnChange = (event: any) => {
          page.forEach((row: any) => {
            // check each row if it is not disabled
            !row.original.disabled &&
              row.original.action === TRANSACTION_STATUS.CLAIM &&
              // Claiming state
              (isClaimPage
                ? !(
                    !!row.original?.creator_fee_claim_tx_started_at &&
                    !row.original?.creator_fee_claim_tx_confirmed_at
                  )
                : !(
                    !!row.original?.admin_fee_collect_tx_started_at &&
                    !row.original?.admin_fee_collect_tx_confirmed_at
                  )) &&
              toggleRowSelected(row.id, event.currentTarget.checked);
          });
        };

        // Count number of selectable and selected rows in the current page
        // to determine the state of select all checkbox
        let selectableRowsInCurrentPage = 0;
        let selectedRowsInCurrentPage = 0;
        rows.forEach((row: any) => {
          row.isSelected && selectedRowsInCurrentPage++;
          row.original.action === TRANSACTION_STATUS.CLAIM &&
            // Claiming state
            (isClaimPage
              ? !(
                  !!row.original?.creator_fee_claim_tx_started_at &&
                  !row.original?.creator_fee_claim_tx_confirmed_at
                )
              : !(
                  !!row.original?.admin_fee_collect_tx_started_at &&
                  !row.original?.admin_fee_collect_tx_confirmed_at
                )) &&
            selectableRowsInCurrentPage++;
        });

        // If there are no selectable rows in the current page
        // select all checkbox will be disabled -> see page 2
        const disabled = selectableRowsInCurrentPage === 0;
        const checked =
          (isAllPageRowsSelected ||
            selectableRowsInCurrentPage === selectedRowsInCurrentPage) &&
          !disabled;

        return (
          <div>
            <IndeterminateCheckbox
              onChange={modifiedOnChange}
              checked={checked}
              disabled={disabled}
            />
          </div>
        );
      },
      maxWidth: tableColumnsWidths.selectionMax,
      minWidth: tableColumnsWidths.selectionMin,
      width: tableColumnsWidths.selection,
      Cell: ({ row }) => (
        <div>
          <IndeterminateCheckbox
            {...row.getToggleRowSelectedProps()}
            disabled={
              row.original.action !== TRANSACTION_STATUS.CLAIM ||
              // Claiming state
              (isClaimPage
                ? !!row.original?.creator_fee_claim_tx_started_at &&
                  !row.original?.creator_fee_claim_tx_confirmed_at
                : !!row.original?.admin_fee_collect_tx_started_at &&
                  !row.original?.admin_fee_collect_tx_confirmed_at)
            }
          />
        </div>
      )
    });
  }

  return cols;
};
