import React from 'react';
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  Editor,
  EditorProvider,
  HtmlButton,
  Separator,
  Toolbar
} from 'react-simple-wysiwyg';
import { IFormik } from './CreateBlogForm';
import { InputMedia } from 'components/input/InputMedia';
import { handleMediaUpload } from 'utils/processing';
import classes from './CreateBlogForm.module.scss';

interface IStep2
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldTouched'
    | 'setFieldError'
    | 'handleBlur'
  > {}

export const Step2 = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setFieldError
}: IStep2) => {
  return (
    <div className={classes['blog-form-step']}>
      <InputMedia
        name="image"
        title="Upload Post Blog Image"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleMediaUpload(
            e,
            'image',
            setFieldValue,
            setFieldTouched,
            setFieldError
          );
        }}
        onClear={async () => {
          setFieldValue('image', '');
        }}
        hasTooltip
        tooltipText={
          'Upload Blog Post image. It will be visible on the Single Blog page.'
        }
        isRequired
        pic={values.image}
        error={errors.image && touched.image ? errors.image : ''}
      />
      <div>
        <h5 className={`${classes['u-body3']} ${classes['u-semiBold']}`}>
          Post Content
        </h5>
        <EditorProvider>
          <Editor
            value={values.content}
            containerProps={{
              style: {
                resize: 'vertical',
                overflow: 'scroll',
                minHeight: '330px'
              }
            }}
            onChange={(e: any) => setFieldValue('content', e.target.value)}
          >
            <Toolbar>
              <BtnUndo />
              <BtnRedo />
              <Separator />
              <BtnBold />
              <BtnItalic />
              <BtnUnderline />
              <BtnStrikeThrough />
              <Separator />
              <BtnNumberedList />
              <BtnBulletList />
              <Separator />
              <BtnLink />
              <BtnClearFormatting />
              <HtmlButton />
              <Separator />
              <BtnStyles />
            </Toolbar>
          </Editor>
        </EditorProvider>
      </div>
    </div>
  );
};

export default Step2;
