import { IUser } from 'context/userContext';
import { IData } from 'query/course-module/dto';
import { IEditAuthFormFields, IEditProfileFormFields } from 'utils/yupSchemas';

export interface IHiddenFields {
  email: boolean;
  about: boolean;
  website: boolean;
  twitter: boolean;
  instagram: boolean;
  facebook: boolean;
}

export enum USER_KYC_STATUS {
  NOT_STARTED = 0,
  CREATED = 1,
  PROCESSED = 2,
  UPDATED = 3,
  APPROVED = 4,
  REJECTED = 5
}

export interface IMeDataDto {
  _id: string;
  email: string;
  active: boolean;
  registered: boolean;
  is_admin: boolean;
  first_name: string;
  last_name: string;
  wallet: string;
  pic: string;
  role_id: string;
  role_flag: number;
  kyc_status: USER_KYC_STATUS;
  is_creator?: boolean;
  slug: string;
  last_watched_course: string;
  last_watched_video: string;
  role: {
    _id: string;
    name: string;
    role_access: string;
    sub_roles: any[];
    role_flag: number;
    __v: number;
  };
  additional_info: {
    banner: string;
    about: string;
    website: string;
    twitter: string;
    instagram: string;
    facebook: string;
  };
  hidden_fields: IHiddenFields;
  wallet_edu: string;
  marketing_info?: IOnboardingDto;
}

export interface IRegisterDto {
  email: string;
  password: string;
  confirm_password: string;
  first_name: string;
  last_name: string;
  pic?: string;
}

export interface IOnboardingDto {
  role?: string;
  interests?: string[];
  occupation?: string;
  platform_usage_reasons?: string[];
}

export interface IUserInfo {
  _id: string;
  value: string;
  icon?: string;
  type: MARKETING_INFO_TYPE;
}

export interface IOnboardingDtoExtended extends IOnboardingDto {
  userInfo: IUserInfo[];
}

export interface ILoginDto extends Pick<IRegisterDto, 'email' | 'password'> {
  remember: boolean;
}

export interface IForgotPasswordDto extends Pick<ILoginDto, 'email'> {}

export interface IResetPasswordDto {
  new_pass: string;
}

export interface IVerifyAccountDto {
  uid: string;
  hash: string;
}

export interface IVerifyNewsletterDto {
  sub_id: string;
  hash: string;
}

export interface IResetPasswordExtendedDto
  extends IResetPasswordDto,
    IVerifyAccountDto {}

export interface IChangePasswordDto extends IEditAuthFormFields {}

export interface IEditProfileDto
  extends Omit<IEditProfileFormFields, 'banner' | 'about'> {
  additional_info_edu: {
    banner?: string;
    about?: string;
  };
}

export interface IEditSocialDto {
  website?: string;
  twitter?: string;
  instagram?: string;
  facebook?: string;
}

// Creator has all user non-sensitive fields
export interface ICreator
  extends Pick<
    IUser,
    | '_id'
    | 'active'
    | 'first_name'
    | 'last_name'
    | 'email'
    | 'pic'
    | 'slug'
    | 'is_creator'
    | 'additional_info'
  > {}

export interface ICreatorsData extends Omit<IData, 'minCourse' | 'maxCourse'> {
  result: ICreator[];
}

export enum MARKETING_INFO_TYPE {
  INTEREST = 0,
  OCCUPATION = 1,
  ROLE = 2,
  PLATFORM_USAGE_REASON = 3
}
