import { useNavigate } from 'react-router-dom';
import { ReactComponent as Certification } from 'assets/icons/certification.svg';
import { ReactComponent as Learning } from 'assets/icons/learning-icon.svg';
import { ReactComponent as Arrows } from 'assets/icons/arrows-icon.svg';
import { ReactComponent as Search } from 'assets/icons/search-icon.svg';
import { ReactComponent as Movie } from 'assets/icons/movie-icon.svg';
import { ReactComponent as Users } from 'assets/icons/users-icon.svg';
import LandingScreen from 'assets/images/landing-page-scr.png';
import VisionImage from 'assets/images/vision-image.png';
import Button from 'components/buttons/Button';
import CustomBreadcrumbs from 'components/breadcrumbs/CustomBreadcrumbs';
import { gtmEventHandler } from 'utils/google-tag-manager';
import classes from './AboutUs.module.scss';

const AboutUs = () => {
  const navigate = useNavigate();
  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'About us', link: '/about-us' }
  ];

  return (
    <div className={classes['about-us-wrapper']}>
      <CustomBreadcrumbs crumbs={crumbs} />
      <div className={`${classes['header']} ${classes['header-items']}`}>
        <h5
          className={`${classes['link-contrast']} ${classes['u-body1']} ${classes['u-semiBold']}`}
        >
          About OpusEdu
        </h5>
        <h2 className={classes['u-semiBold']}>
          Rewriting the rules of education
        </h2>
        <h5 className={`${classes['u-text--content']}`}>
          Learn more about the platform and the idea behind it.
        </h5>
      </div>
      <div className={classes['welcome-container']}>
        <div className={classes['content']}>
          <h4 className={classes['u-bold']}>Welcome</h4>
          <h5 className={classes['u-body1']}>
            Welcome to a world where learning is simple, inclusive, and
            limitless. Our blockchain-powered learning platform connects
            learners and creators globally.
          </h5>
          <h5 className={classes['u-body1']}>
            By leveraging next-generation technology and a mission to break-down
            barriers, we are creating a space where knowledge is accessible to
            everyone, everywhere.
          </h5>
        </div>
        <div className={classes['img-wrapper']}>
          <img src={LandingScreen} alt="landing-page" />
        </div>
      </div>
      <div className={`${classes['header-items']} ${classes['features']}`}>
        <h5
          className={`${classes['link-contrast']} ${classes['u-body1']} ${classes['u-semiBold']}`}
        >
          Features
        </h5>
        <h3 className={classes['u-semiBold']}>
          Accessible learning. Verified credentials. Limitless potential
        </h3>
        <div className={classes['cards']}>
          <div className={classes['card']}>
            <Certification />
            <h5 className={`${classes['u-semiBold']}`}>
              Immutable certifications
            </h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Earn NFT-backed certifications that serve as permanent, verifiable
              proof of your skills. These credentials stay with you forever,
              ready to showcase employers, clients, and communities.
            </h5>
          </div>
          <div className={classes['card']}>
            <Users />
            <h5 className={`${classes['u-semiBold']}`}>
              Borderless learning community
            </h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              No borders. No limits. Access education or share your expertise
              from anywhere in the world.
            </h5>
          </div>
          <div className={classes['card']}>
            <Arrows />
            <h5 className={`${classes['u-semiBold']}`}>
              Transparent transactions
            </h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Say goodbye to hidden fees. Our blockchain-powered system ensures
              secure payments for creators and transparent pricing for learners.
            </h5>
          </div>
          <div className={classes['card']}>
            <Search />
            <h5 className={`${classes['u-semiBold']}`}>
              Diverse course options
            </h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Accessible learning. <br /> Verified credentials. <br /> Limited
              potential.
            </h5>
          </div>
        </div>
      </div>
      <div className={classes['vision-wrapper']}>
        <div className={classes['data-container']}>
          <h5
            className={`${classes['link-contrast']} ${classes['u-body1']} ${classes['u-semiBold']}`}
          >
            Our Vision
          </h5>
          <h4 className={`${classes['u-semiBold']} ${classes['u-italic']}`}>
            The future of education isn't confined to classrooms - it's global,
            digital, and powered by blockchain.
          </h4>
          <h5 className={`${classes['u-text--content']} ${classes['u-body1']}`}>
            At OpusEdu, we are a decentralized platform that makes education
            accessible, inclusive, and impactful, empowering creators and
            learners to achieve more together.
          </h5>
        </div>
        <div className={classes['img-wrapper']}>
          <img src={VisionImage} alt="vision-image" />
        </div>
      </div>
      <div className={`${classes['header-items']} ${classes['values']}`}>
        <h5
          className={`${classes['link-contrast']} ${classes['u-body1']} ${classes['u-semiBold']}`}
        >
          Our Values
        </h5>
        <h3 className={classes['u-semiBold']}>
          The core beliefs that guide us
        </h3>
        <div className={classes['info-wrapper']}>
          <div className={classes['info-card']}>
            <h5 className={`${classes['u-semiBold']}`}>Education for all</h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              We believe knowledge should be accessible to everyone, everywhere.
              By breaking down barriers like borders and banking limitations,
              we're making learning a right, not a privilege.
            </h5>
          </div>
          <div className={classes['info-card']}>
            <h5 className={`${classes['u-semiBold']}`}>Transparency</h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Honesty and clarity are at the heart of what we do. From secure
              blockchain payments to NFT certifications, we ensure every process
              is seamless, secure, and easy to understand.
            </h5>
          </div>
          <div className={classes['info-card']}>
            <h5 className={`${classes['u-semiBold']}`}>Diversity</h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              OpusEdu celebrates the richness of global culture. With courses in
              multiple languages and topics ranging from tech to business, we
              foster a community that values connection through shared
              knowledge.
            </h5>
          </div>
        </div>
      </div>
      <div className={`${classes['footer']}`}>
        <div className={`${classes['header-items']}`}>
          <h3 className={`${classes['u-semiBold']}`}>
            Start your journey with OpusEdu
          </h3>
          <h5 className={`${classes['u-text--content']} ${classes['u-body1']}`}>
            Let's build the future of education - together.
          </h5>
        </div>
        <div className={classes['footer-cards']}>
          <div className={classes['card']}>
            <Learning />
            <h5 className={`${classes['u-semiBold']}`}>For learners</h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Learn it. Prove it. Own it. Get blockchain-certified credentials
              that validate your true skills.
            </h5>
            <Button
              size="sm"
              variant="neutral"
              className="gtm-button"
              onClick={() => {
                gtmEventHandler('click_start_learning');
                navigate('/courses');
              }}
            >
              Start learning
            </Button>
          </div>
          <div className={classes['card']}>
            <Movie />
            <h5 className={`${classes['u-semiBold']}`}>For creators</h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Share what you know, earn what you deserve. Teach globally and
              transform your expertise into income on OpusEdu.
            </h5>
            <Button
              size="sm"
              variant="neutral"
              className="gtm-button"
              onClick={() => {
                gtmEventHandler('click_create_course');
                navigate('/courses/create');
              }}
            >
              Create a course
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
