import React, { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import Button from 'components/buttons/Button';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { popupContext } from 'context/popupContext';
import { toastContext } from 'context/toastContext';
import { OnboardingSchema } from 'utils/yupSchemas';
import { editUserMarketingInfo } from 'query/acc-module/mutations';
import { IOnboardingDto, IOnboardingDtoExtended } from 'query/acc-module/dto';
import classes from './OnboardingForm.module.scss';

export interface IFormik {
  errors: FormikErrors<IOnboardingDto>;
  touched: FormikTouched<IOnboardingDto>;
  values: IOnboardingDto;
  setFieldValue: (
    fieldName: string,
    value: string | boolean | any[] | null | undefined
  ) => void;
  setFieldError: (fieldName: string, value: string) => void;
  setFieldTouched: (fieldName: string, value: boolean) => void;
  submitForm: () => void;
  handleBlur: (e: React.FocusEvent<HTMLElement>) => void;
}

interface IRenderStep extends IFormik {
  step: number;
}

interface IOnboardingForm {
  steps: number;
  currentStep: number;
  setStep: (step: number) => void;
  defaultFormData: IOnboardingDtoExtended;
}

const OnboardingForm = ({
  steps,
  setStep,
  currentStep,
  defaultFormData
}: IOnboardingForm) => {
  const queryClient = useQueryClient();
  const { clearPopup } = useContext(popupContext);
  const { setToast, clearToast } = useContext(toastContext);

  const { mutate: handleEdit } = useMutation({
    ...editUserMarketingInfo(),
    onError: (err: Error) =>
      setToast({
        type: 'error',
        msg: err.message
      })
  });

  const renderPrevBtn = () => (
    <Button
      type="button"
      minWidth="lg"
      variant="neutral"
      onClick={() => {
        clearToast();
        setStep(currentStep - 1);
      }}
      icon={Arrow}
      iconRotate={270}
      iconPosition="left"
    >
      Previous Step
    </Button>
  );

  const renderStep = ({
    step,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleBlur,
    submitForm
  }: IRenderStep) => {
    if (step === 0) {
      return (
        <Step1
          steps={steps}
          setStep={setStep}
          currentStep={currentStep}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          setFieldError={setFieldError}
          handleBlur={handleBlur}
          clearPopup={clearPopup}
          defaultFormData={defaultFormData}
        />
      );
    }
    if (step === 1) {
      return (
        <Step2
          steps={steps}
          setStep={setStep}
          currentStep={currentStep}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          handleBlur={handleBlur}
          defaultFormData={defaultFormData}
          renderPrevBtn={renderPrevBtn}
        />
      );
    }
    if (step === 2) {
      return (
        <Step3
          submitForm={submitForm}
          steps={steps}
          setStep={setStep}
          currentStep={currentStep}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          handleBlur={handleBlur}
          defaultFormData={defaultFormData}
          renderPrevBtn={renderPrevBtn}
        />
      );
    }
  };

  return (
    <Formik
      initialValues={defaultFormData}
      validationSchema={OnboardingSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const data: IOnboardingDto = {
          role: values.role,
          interests: values.interests,
          occupation: values.occupation,
          platform_usage_reasons: values.platform_usage_reasons
        };

        handleEdit(
          { ...data },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: ['me-data']
              });
              setToast({
                type: 'success',
                title: 'Successfull',
                msg: 'Successfully sumbitted.',
                position: 'top',
                autoClose: true
              });
              clearPopup();
            }
          }
        );
        setSubmitting(false);
      }}
      validateOnBlur
      validateOnMount
      enableReinitialize
    >
      {(props) => (
        <Form className={`${classes['form']} ${classes['form--popup']}`}>
          {renderStep({
            step: currentStep,
            ...props
          })}
        </Form>
      )}
    </Formik>
  );
};

export default OnboardingForm;
