import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { windowContext } from 'context/windowsContext';
import CourseForm from '../components/CourseForm';
import { ICourseFormFields } from 'utils/yupSchemas';
import { expirationOptions } from '../components/Step3';
import { difficultyOptions } from '../components/Step1';
import classes from './CreateCourse.module.scss';

const formSteps = [
  'Main Information',
  'Thumbnails & Preview',
  'Pricing & Time Limit',
  'Choose Certificate',
  'Confirm Creation'
];

const CreateCourse = () => {
  const { state: locationState } = useLocation();
  const [currentStep, setCurrentStep] = useState<number>(
    locationState?.savedFormData?.step || 0
  );
  const [finishedSteps, setFinishedSteps] = useState<number>(
    locationState?.savedFormData?.step || 0
  );
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);

  // If the cached from data is a create form, use the cached data
  // if not, use the empty form
  // eslint-disable-next-line
  const [defaultFormData, setDefaultFormData] = useState<ICourseFormFields>(
    locationState?.savedFormData || {
      name: '',
      category: null,
      subcategory: null,
      short_description: '',
      description: '',
      thumbnail: '',
      activation_nft_image: '',
      video_preview: '',
      hover_play: true,
      show_video_preview: true,
      expiration: [expirationOptions[0]],
      difficulty_level: [difficultyOptions[0]],
      requirements: '',
      time_to_complete: null,
      agenda: '',
      skills: [],
      currency: null,
      price: '',
      cpe: [],
      template_id: [],
      not_finished: false,
      isThumbnailPlaceholder: true
    }
  );

  return (
    <div id="course-form__wrapper" className={classes['wrapper']}>
      <div className={classes['container']}>
        {!isLgMobile && (
          <h3
            className={`${classes['u-semiBold']} ${classes['u-text--center']}`}
          >
            Create Course
          </h3>
        )}
        <CourseForm
          steps={formSteps}
          finishedSteps={finishedSteps}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          setStep={(step: number) => {
            setCurrentStep(step);
            setFinishedSteps(step === 0 ? 0 : step);
          }}
          defaultFormData={defaultFormData}
        />
      </div>
    </div>
  );
};

export default CreateCourse;
