import { useContext, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as Activate } from 'assets/icons/activate.svg';
import { popupContext } from 'context/popupContext';
import Button from 'components/buttons/Button';
import GenericPopup from 'components/popup/GenericPopup';
import { verifyRegMutation } from 'query/acc-module/mutations';
import classes from './VerifyReg.module.scss';

const VerifyReg = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uid = searchParams.get('u_id') || '';
  const hash = searchParams.get('hash') || '';
  const { setPopup } = useContext(popupContext);

  // Verify mutation
  const { isLoading, mutate: handleVerify } = useMutation({
    ...verifyRegMutation(),
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          isClosable={false}
          redirectPath="/login"
          buttonName="Go to login"
          buttonVariant="neutral"
        />
      );
    }
  });

  useEffect(() => {
    handleVerify({ uid, hash });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes['wrapper']}>
      <div className={classes['container']}>
        <Activate width={156} height={156} />
        <div>
          <h3 className={classes['u-bold']}>Email Confirmation Successful</h3>
          <h5 className={classes['u-body2']}>
            Your account is almost ready! Complete your onboarding and explore
            all features, enroll in courses, or create your own!
          </h5>
        </div>
        <Button
          type="button"
          minWidth="lg"
          variant="contrast"
          isLoading={isLoading}
          onClick={() => navigate('/login')}
        >
          Personalise your experience
        </Button>
      </div>
    </div>
  );
};

export default VerifyReg;
