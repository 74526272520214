import { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as Check } from 'assets/icons/check-circle.svg';
import { popupContext } from 'context/popupContext';
import Summary from '../../components/Summary';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import { currencyOptions, expirationOptions } from '../../components/Step3';
import { cpeOptions } from '../../components/Step4';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Button from 'components/buttons/Button';
import GenericPopup from 'components/popup/GenericPopup';
import ApprovePopup from 'components/popup/ApprovePopup';
import { difficultyOptions } from '../../components/Step1';
import {
  adminSingleCourseDataQuery,
  singleTemplateDataQuery
} from 'query/course-module/queries';
import {
  rejectCourseMutation,
  approveCourseMutation,
  takedownCourseMutation
} from 'query/course-module/mutations';
import { COURSE_CHANGES, ICourse, ITemplateDto } from 'query/course-module/dto';
import { ICourseFormFields } from 'utils/yupSchemas';
import {
  COURSE_STATUS,
  REACT_APP_FILES_EDU_PUBLIC_DOMAIN
} from 'utils/constants';
import { isCourseStatus } from 'utils/helpers';
import classes from './CourseReview.module.scss';

const CourseReview = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const { setPopup, clearPopup, setPopupLoading } = useContext(popupContext);
  const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery<boolean, Error, ICourse>(
    adminSingleCourseDataQuery(slug as string, `?&changes=${changes}`)
  );

  const isSubmittedDraft = !!data && isCourseStatus('isSubmittedDraft', data);
  const isSubmitted = !!data && isCourseStatus('isSubmitted', data);
  const isPublished = !!data && isCourseStatus('isPublished', data);
  const isPaused = !!data && isCourseStatus('isPaused', data);

  const {
    isLoading: templateIsLoading,
    error: templateError,
    data: templateData
  } = useQuery<boolean, Error, ITemplateDto>({
    ...singleTemplateDataQuery(data?.template_id as string),
    enabled: !!data?.template_id
  });

  const { mutate: approveMutation } = useMutation({
    ...approveCourseMutation(data?._id as string, setPopupLoading),
    onSuccess: (id: string) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          msg="You have successfully approved the course. The creator can now publish it."
          buttonVariant="contrast"
          buttonName="Go to Course"
          buttonAction={() =>
            navigate(
              `/admin/courses/${slug}?&changes=${
                data?.status === COURSE_STATUS.DRAFT
                  ? COURSE_CHANGES.NO_CHANGES
                  : COURSE_CHANGES.ALL
              }`
            )
          }
          bellowBtnComp={
            <Button
              variant="link-neutral"
              onClick={clearPopup}
              minWidth="full"
              withPadding={false}
            >
              Close
            </Button>
          }
        />
      );
      // Invalidate original published course
      queryClient.invalidateQueries([
        'admin-courses-data',
        {
          id: slug,
          changes: changes
        }
      ]);
      // Remove query of draft-of-published, as it no longer exists
      queryClient.removeQueries([
        'admin-courses-data',
        {
          id: data?._id,
          searchParams: `?&changes=${COURSE_CHANGES.ONLY_CHANGES}`
        }
      ]);
    },
    onError: (e: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={e.message}
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    }
  });

  const { mutate: rejectMutation } = useMutation({
    ...rejectCourseMutation(data?._id as string, setPopupLoading),
    onSuccess: (id: string) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          msg="You have successfully rejected the course."
          buttonVariant="contrast"
          buttonName="Go to Course"
          buttonAction={() =>
            navigate(`/admin/courses/${slug}?&changes=${changes}`)
          }
          bellowBtnComp={
            <Button
              variant="link-neutral"
              onClick={clearPopup}
              minWidth="full"
              withPadding={false}
            >
              Close
            </Button>
          }
        />
      );
      queryClient.invalidateQueries([
        'admin-courses-data',
        {
          id: slug,
          changes: changes
        }
      ]);
    },
    onError: (e: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={e.message}
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    }
  });

  const { mutate: takedownMutation } = useMutation({
    ...takedownCourseMutation({
      id: data?._id as string,
      isTakedown: isPaused ? false : true,
      setPopupLoading
    }),
    onSuccess: (data: { id: string; isTakedown: boolean }) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          msg={`You have successfully ${
            data.isTakedown ? 'taken down' : 'restored'
          } the course. It is ${
            data.isTakedown
              ? 'no longer publicly listed.'
              : 'now visible in the Explore Courses page.'
          }`}
          buttonVariant="contrast"
          buttonName="Go to Course"
          buttonAction={() =>
            navigate(`/admin/courses/${slug}?&changes=${changes}`)
          }
          bellowBtnComp={
            <Button
              variant="link-neutral"
              onClick={clearPopup}
              minWidth="full"
              withPadding={false}
            >
              Close
            </Button>
          }
        />
      );
      queryClient.invalidateQueries([
        'admin-courses-data',
        {
          id: slug,
          changes: changes
        }
      ]);
      queryClient.invalidateQueries(['admin-course-history']);
    },
    onError: (e: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={e.message}
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    }
  });

  const approveActionHandler = (
    action: 'approve' | 'reject',
    secondAction?: 'takedown' | 'restore'
  ) => {
    const isRestoreCourse =
      action === 'approve' && secondAction === 'restore' && isPaused;
    const isApproveCourse =
      action === 'approve' && (isSubmittedDraft || isPublished || isPaused);
    const isTakeDownCourse =
      action === 'reject' &&
      secondAction === 'takedown' &&
      isPublished &&
      changes === COURSE_CHANGES.NO_CHANGES;

    const text = isRestoreCourse
      ? 'Restore'
      : isApproveCourse
      ? 'Approve'
      : isTakeDownCourse
      ? 'Take down'
      : 'Reject';

    setPopup(
      <ApprovePopup
        size="md"
        buttonVariant="contrast"
        title={`${text} Course`}
        buttonName={`${text} Course`}
        buttonAction={(reason?: string) =>
          isRestoreCourse
            ? takedownMutation(data?._id as string)
            : isApproveCourse
            ? approveMutation(data?._id as string)
            : isTakeDownCourse
            ? takedownMutation(reason as string)
            : rejectMutation(reason as string)
        }
        course={data as ICourse}
        type={action}
      />
    );
  };

  const prepareData = () => {
    if (!data) return;
    return {
      ...data,
      expiration: expirationOptions.filter(
        (opt) => opt.value === data?.expiration
      ),
      difficulty_level: difficultyOptions.filter(
        (opt) => opt.value === data?.difficulty_level
      ),
      cpe: data?.cpe
        ? []
        : cpeOptions.filter((opt) => opt.value === data.cpe.toString()),
      template_id: [
        {
          label: templateData?.name,
          value: templateData?._id,
          img: templateData?.thumbnail
        }
      ],
      category: { label: data.category.name, value: data.category._id },
      subcategory: !!data?.subcategory
        ? {
            label: data.subcategory.name,
            value: data.subcategory._id
          }
        : null,
      currency: currencyOptions[0],
      activation_nft_image:
        REACT_APP_FILES_EDU_PUBLIC_DOMAIN + data.activation_nft_image,
      thumbnail: REACT_APP_FILES_EDU_PUBLIC_DOMAIN + data.thumbnail,
      video_preview: !!data.video_preview
        ? REACT_APP_FILES_EDU_PUBLIC_DOMAIN + data.video_preview
        : ''
    };
  };

  if (isLoading || (!!data?.template_id && templateIsLoading))
    return <Loader />;
  if (error || (!!data?.template_id && templateError))
    return <Error error={error || templateError} />;

  const dataIs = prepareData();

  return (
    <>
      <Breadcrumbs skips={[0, 1, 2]} />
      <div className={classes['review']}>
        <div className={classes['review__container']}>
          <h3 className={classes['u-semiBold']}>Course Review</h3>
          <div className={classes['review__summary']}>
            <Summary data={dataIs as ICourseFormFields} errors={null} />
          </div>

          <div className={classes['review__btns-container']}>
            {/* Initially submitted courses */}
            {isSubmitted && !isPublished && !isPaused && (
              <>
                <Button
                  className={`${classes['btns-row__btn']} ${classes['btns-row__btn--error']}`}
                  variant="error"
                  icon={Cross}
                  onClick={() => approveActionHandler('reject')}
                >
                  Reject Course
                </Button>
                <Button
                  className={`${classes['btns-row__btn']} ${classes['btns-row__btn--success']}`}
                  variant="success"
                  icon={Check}
                  onClick={() => approveActionHandler('approve')}
                >
                  Approve Course
                </Button>
              </>
            )}
            {/* Submitted changes for published course */}
            {isSubmitted &&
              (isPublished || isPaused) &&
              changes === COURSE_CHANGES.ALL && (
                <>
                  <Button
                    className={`${classes['btns-row__btn']} ${classes['btns-row__btn--error']}`}
                    variant="error"
                    icon={Cross}
                    onClick={() => approveActionHandler('reject')}
                  >
                    Reject Changes
                  </Button>
                  <Button
                    className={`${classes['btns-row__btn']} ${classes['btns-row__btn--success']}`}
                    variant="success"
                    icon={Check}
                    onClick={() => approveActionHandler('approve')}
                  >
                    Approve Changes
                  </Button>
                </>
              )}
            {/* Published course without changes */}
            {(isPublished || isPaused) &&
              changes === COURSE_CHANGES.NO_CHANGES &&
              !data?.is_draft_copy && (
                <>
                  {isPaused && (
                    <Button
                      className={`${classes['btns-row__btn']} ${classes['btns-row__btn--success']}`}
                      variant="success"
                      icon={Check}
                      onClick={() => approveActionHandler('approve', 'restore')}
                    >
                      Restore Course
                    </Button>
                  )}
                  {!isPaused && (
                    <Button
                      className={`${classes['btns-row__btn']} ${classes['btns-row__btn--error']}`}
                      variant="error"
                      icon={Cross}
                      onClick={() => approveActionHandler('reject', 'takedown')}
                    >
                      Take Down
                    </Button>
                  )}
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseReview;
