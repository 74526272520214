import { fetchApi } from 'utils/requests';
import { PROJECT_ID } from 'utils/constants';

// Me query - get logged in profile info
export const meDataQuery = () => ({
  queryKey: ['me-data'],
  queryFn: async () =>
    await fetchApi('account', '/user/me', {
      method: 'GET',
      auth: true
    })
});

export const publicProfileDataQuery = (id: string) => ({
  queryKey: ['profile-data', { id }],
  queryFn: async () =>
    await fetchApi('account', `/user/public/${PROJECT_ID.OPUS_EDU}/${id}`, {
      method: 'GET',
      auth: true
    })
});

export const userMarketingInfoQuery = () => ({
  queryKey: ['user-marketing-info'],
  queryFn: async () =>
    await fetchApi('account', `/user/marketing-fields?type=0,1,2,3`, {
      method: 'GET',
      auth: true
    })
});
