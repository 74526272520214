import { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import Button from 'components/buttons/Button';
import GenericPopup from 'components/popup/GenericPopup';
import ApprovePopup from 'components/popup/ApprovePopup';
import Tooltip from 'components/tooltip/Tooltip';
import { popupContext } from 'context/popupContext';
import { toastContext } from 'context/toastContext';
import { ICourseBundle } from 'query/course-module/dto';
import { deleteBundleMutation } from 'query/course-module/mutations';
import { BUNDLE_STATUS } from 'utils/constants';
import classes from './Buttons.module.scss';

interface IBundleCreatorButtonsProps {
  bundle: ICourseBundle;
}

const BundleCreatorButtons = ({ bundle }: IBundleCreatorButtonsProps) => {
  const queryClient = useQueryClient();
  const { slug } = useParams();
  const navigate = useNavigate();
  const { search: urlParams } = useLocation();
  const { setToast } = useContext(toastContext);
  const { changes, is_public_view } = queryString.parse(urlParams);
  const { setPopup, clearPopup, setPopupLoading } = useContext(popupContext);

  const { _id, status } = bundle;

  const isPublished = status === BUNDLE_STATUS.PUBLISHED;
  const isPublishing = status === BUNDLE_STATUS.PRE_LIST;

  const isCreatorPublicView = is_public_view === 'true';

  const {
    // eslint-disable-next-line
    isLoading: deleteLoading,
    mutate: deleteMutation
  } = useMutation({
    mutationFn: async () => await deleteBundleMutation(_id).mutationFn(),
    onSuccess: () => {
      setPopupLoading(false);
      setToast({
        type: 'success',
        msg: 'Successfully deleted.',
        position: 'top',
        autoClose: true
      });
      navigate('/user/bundles');
      queryClient.invalidateQueries(['bundles-data']);
      queryClient.invalidateQueries(['bundles-data', { id: _id }]);
    },
    onError: (e: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={e.message}
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    }
  });

  return (
    <>
      <div className={classes['btns-row']}>
        <div className={classes['btns-row__primary']}>
          <Button
            minWidth="full"
            variant="error"
            isLoading={deleteLoading}
            className={`${classes['btns-row__btn']} ${classes['btns-row__btn--error']}`}
            icon={Cross}
            onClick={() =>
              setPopup(
                <ApprovePopup
                  size="md"
                  title="Delete Bundle"
                  msg="Are you sure you want to delete your bundle?"
                  course={bundle}
                  buttonVariant="error"
                  buttonName="Delete"
                  buttonAction={() => deleteMutation()}
                />
              )
            }
          >
            Delete Bundle
          </Button>
        </div>
      </div>
      {!isCreatorPublicView && (
        <Tooltip
          id="tooltip-publish"
          text={
            isPublishing
              ? `You cannot edit a bundle 
            ${isPublishing ? 'while it is publishing' : ''}`
              : ''
          }
        >
          <Button
            className={classes['btns-row__btn']}
            onClick={() => {
              localStorage.removeItem('selectedCourses');
              navigate(`/bundle/${slug}/edit`);
            }}
            icon={Edit}
            iconPosition="left"
            variant="link-neutral"
            isDisabled={isPublishing}
            minWidth="full"
          >
            Edit Bundle Details
          </Button>
        </Tooltip>
      )}
      {/* {isPublished && (
        <div>
          <Button
            className={classes['btns-row__btn']}
            variant="neutral"
            icon={isCreatorPublicView ? Cross : Eye}
            iconPosition="left"
            onClick={() =>
              navigate(
                `/courses/${slug}?is_public_view=${
                  is_public_view === 'true' ? 'false' : 'true'
                }&changes=${changes}`
              )
            }
            minWidth="full"
          >
            {isCreatorPublicView ? 'Close Preview' : 'Preview as Guest'}
          </Button>
        </div>
      )} */}
    </>
  );
};

export default BundleCreatorButtons;
