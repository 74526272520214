import { useContext, useMemo } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useAccount, useSwitchNetwork } from 'wagmi';

import ThumbnailPlaceholder from 'assets/images/thumbnail-placeholder.png';
import { ReactComponent as Switch } from 'assets/icons/switch.svg';
import { Web3Context } from 'context/web3Context';
import { userContext } from 'context/userContext';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import Badge from 'components/badge/Badge';
import Button from 'components/buttons/Button';
import InfoBox from 'components/info-box/InfoBox';
import SEO from 'components/SEO/SEO';
import {
  COURSE_CHANGES,
  COURSE_RELATIONSHIP,
  ICourse,
  ICourseBundle
} from 'query/course-module/dto';
import {
  COURSE_EXPIRY,
  REACT_APP_CHAIN_ID,
  REACT_APP_FILES_EDU_PUBLIC_DOMAIN
} from 'utils/constants';
import { getPriceInUsdc } from 'utils/helpers';
import { dateFormatUrl } from 'utils/static';
import { formatNames } from 'utils/format';
import { currencyObj } from '..';
import { singleBundleDataQuery } from 'query/course-module/queries';
import Tabs from 'components/tabs/Tabs';
import Table from 'components/table/Table';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import { BrowseCoursesCols } from 'components/table/BrowseCoursesCols';
import BundleButtons from './button-views/BundleButtonsView';
import BundleCreatorButtons from './button-views/BundleCreatorButtons';
import classes from './BundleView.module.scss';

interface ICourseViewProps {
  tab: 'courses';
}

const SingleBundle = ({ tab }: ICourseViewProps) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { userData, isTokenExpired } = useContext(userContext);
  const { isCorrectChain } = useContext(Web3Context);

  // Web3 hooks
  const { isConnected } = useAccount();
  const { switchNetwork } = useSwitchNetwork();
  const { data } = useQuery<boolean, Error, ICourseBundle>(
    singleBundleDataQuery(slug as string)
  );

  const cols = useMemo(
    () => BrowseCoursesCols({ isSelectable: false, isBundlePage: true }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const tabs = [
    {
      id: 'courses',
      name: 'Courses',
      route: `bundles/${slug}`,
      component: () => (
        <Table
          columns={cols}
          data={(data?.courses as ICourse[]) || []}
          onClickRow={(row: ICourse) =>
            navigate({
              pathname: `/courses/${row.slug}`,
              search: `${createSearchParams({
                changes: row.is_draft_copy
                  ? COURSE_CHANGES.ONLY_CHANGES
                  : COURSE_CHANGES.NO_CHANGES
              })}`
            })
          }
        />
      )
    }
  ];

  return (
    <div className={classes['wrapper']}>
      <SEO
        type="product"
        title={data?.name}
        desc={data?.description}
        image={data?.thumbnail}
      />
      <Breadcrumbs skips={[0]} />
      <div className={classes['wrapper__main']}>
        <div className={classes['wrapper__content']}>
          <div className={classes['bundle-data']}>
            <div className={classes['wrapper__content__img-wrapper']}>
              {data?.thumbnail ? (
                <img
                  src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + data?.thumbnail}
                  alt={!!data?.name ? data?.name : 'Bundle Name'}
                />
              ) : (
                <img src={ThumbnailPlaceholder} alt={'OpusEDU Create Course'} />
              )}
            </div>
            <div className={classes['content']}>
              <h1 className={`${classes['u-semiBold']} ${classes['u-h3']}`}>
                {data?.name}
              </h1>
              <Badge text={data?.category.name} badgeType="primary" size="sm" />
              <div className={classes['content__created-by']}>
                <span className={classes['title']}>Created by </span>
                <Button
                  variant="link-contrast"
                  withPadding={false}
                  onClick={() => navigate(`/user/${slug}/about/main-info`)}
                  className={classes['u-underline']}
                >
                  {formatNames(
                    data?.creator.first_name + ' ' + data?.creator.last_name
                  )}
                </Button>
              </div>
              <div className={classes['content__row']}>
                <div className={classes['title']}>Bundle Description</div>
                <div className={classes['content__details']}>
                  <TextWithCharLimit
                    text={data?.description as string}
                    limit={300}
                  />
                </div>
              </div>
              <div className={classes['content__details']}>
                <div className={classes['title']}>Bundle Price</div>
                <div className={classes['content__details__price']}>
                  {data?.price ? (
                    <>
                      <img
                        src={currencyObj[data.currency as any].symbol}
                        alt="Symbol"
                        width={32}
                        height={32}
                      />
                      <span>
                        <span
                          className={classes['content__details__price--number']}
                        >
                          {' '}
                          {getPriceInUsdc(+data.price)}
                        </span>{' '}
                        {data?.currency
                          ? currencyObj[data.currency as any].name
                          : ''}
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              <div className={classes['content__row']}>
                <div className={classes['title']}>Bundle Time Limit</div>
                <div className={classes['content__details']}>
                  {(data?.expiration as COURSE_EXPIRY) ===
                  COURSE_EXPIRY.INDEFINITELY
                    ? 'Indefinitely'
                    : `1 Year (Expiring on ${moment(
                        new Date(
                          Date.parse(data?.date_created as string)
                        ).setFullYear(
                          new Date(
                            Date.parse(data?.date_created as string)
                          ).getFullYear() + 1
                        )
                      ).format(dateFormatUrl)})`}
                </div>
              </div>
              {data?.bundle_relationship === COURSE_RELATIONSHIP.CREATOR && (
                <BundleCreatorButtons bundle={data} />
              )}
              {data &&
                !userData.isAdmin &&
                data?.bundle_relationship !== COURSE_RELATIONSHIP.CREATOR && (
                  <BundleButtons bundle={data} />
                )}
            </div>
            {!isTokenExpired && isConnected && !isCorrectChain && (
              <InfoBox
                type="warning"
                msg="Unsupported network."
                button={{
                  text: 'Switch Network',
                  variant: 'outline',
                  icon: Switch,
                  onClick: () =>
                    !!switchNetwork && switchNetwork(+REACT_APP_CHAIN_ID)
                }}
              />
            )}
          </div>
          <div className={classes['tabs-wrapper']}>
            <Tabs
              tabs={tabs}
              activeTabIndex={tabs.findIndex((t) => t.id === tab)}
              size={'sm'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBundle;
