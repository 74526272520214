import { useNavigate } from 'react-router-dom';
import ThumbnailPlaceholder from 'assets/images/thumbnail-placeholder.png';
import Badge from '../badge/Badge';
import Button from '../buttons/Button';
import Tooltip from 'components/tooltip/Tooltip';
import { gtmEventHandler } from 'utils/google-tag-manager';
import classes from 'components/card/Card.module.scss';

export const createCourseEmptyRow = {
  name: 'Your New Course',
  thumbnail: ThumbnailPlaceholder,
  creator: {
    first_name: 'Become a course creator today!',
    last_name: ''
  },
  video_count: '-',
  isEmptyCard: true
};

const CreateCourseEmptyCard = () => {
  const navigate = useNavigate();

  return (
    <div
      className={`${classes['card']} ${classes['card--clickable']}`}
      onClick={() => navigate('/courses/create')}
    >
      <Tooltip
        id="Become a creator!"
        text="Begin your creator journey today with OpusEdu!"
      >
        <div className={`${classes['card__img-wrapper']} `}>
          <img src={ThumbnailPlaceholder} alt={'OpusEdu Create Course'} />
        </div>
      </Tooltip>
      <div className={`${classes['card__content']} ${classes['card__empty']}`}>
        <Badge text="Category" size="sm" variant="outline" />
        <div className={classes['card__title']}>Your New Course</div>
        <div className={classes['card__text']}>
          Become a course creator today and share your knowledge with the world!
        </div>
        <Button
          className="gtm-button"
          onClick={() => {
            gtmEventHandler('click_create_course');
            navigate('/courses/create');
          }}
          variant="contrast"
        >
          Create Courses
        </Button>
      </div>
    </div>
  );
};

export default CreateCourseEmptyCard;
