import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import NavIsland from 'routes/dashboard/courses/components/NavIsland';
import Step1 from './Step1';
import Step2 from './Step2';
import GenericPopup from 'components/popup/GenericPopup';
import FormSteps from 'components/forms/formSteps/FormSteps';
import { CircularProggress } from 'components/progress/CircularProgress';
import { IOption } from 'components/input/InputChoice';
import { popupContext } from 'context/popupContext';
import { toastContext } from 'context/toastContext';
import { windowContext } from 'context/windowsContext';
import {
  createBlogMutation,
  editBlogMutation
} from 'query/course-module/mutations';
import { BlogSchema, IBlogDataFormFields } from 'utils/yupSchemas';
import { IBlogData } from 'query/course-module/dto';
import classes from './CreateBlogForm.module.scss';

export interface IFormik {
  errors: FormikErrors<IBlogDataFormFields>;
  touched: FormikTouched<IBlogDataFormFields>;
  values: IBlogDataFormFields;
  setFieldValue: (
    fieldName: string,
    value: string | boolean | IOption[] | null
  ) => void;
  setFieldError: (fieldName: string, value: string) => void;
  setFieldTouched: (fieldName: string, value: boolean) => void;
  submitForm: () => void;
  handleBlur: (e: React.FocusEvent<HTMLElement>) => void;
  isValid: boolean;
}

interface IRenderStep extends IFormik {
  step: number;
}

export interface IBlogForm {
  steps: string[];
  finishedSteps: number;
  setCurrentStep: (step: number) => void;
  currentStep: number;
  setStep: (step: number) => void;
  defaultFormData: IBlogDataFormFields;
  isEdit?: boolean;
  blog?: IBlogData;
}

const CreateBlogForm = ({
  steps,
  finishedSteps,
  setCurrentStep,
  currentStep,
  setStep,
  defaultFormData,
  isEdit,
  blog
}: IBlogForm) => {
  const { setToast } = useContext(toastContext);
  const { setPopup } = useContext(popupContext);
  const { appWrapperElement } = useContext(windowContext);
  const navigate = useNavigate();
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);

  const setErrorPopup = (msg: string) =>
    setPopup(
      <GenericPopup
        type="error"
        msg={msg}
        buttonName="Close"
        buttonVariant="neutral"
        isClosable={false}
      />
    );

  // Edit blog mutation
  const { isLoading: isLoadingEdit, mutate: handleEdit } = useMutation({
    ...editBlogMutation(blog?._id as number),
    onError: (err: Error) => setErrorPopup(err.message)
  });

  const { isLoading: isLoadingCreate, mutate: handleCreate } = useMutation({
    mutationFn: async (data: IBlogDataFormFields) =>
      await createBlogMutation().mutationFn(data),
    onSuccess: (receipt: any) => {
      setToast({
        type: 'success',
        title: 'Successfuly created',
        msg: 'Your blog is now published',
        position: 'top',
        autoClose: true
      });
      navigate(`/blog/${receipt.slug}`);
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup type="error" msg={err.message} buttonName="Close" />
      );
    }
  });

  const fieldsByStep: { [key: number]: string[] } = {
    1: ['title', 'thumbnail', 'topic', 'isTopPost', 'tags'],
    2: ['image', 'content']
  };

  const handleStepClick = (
    step: number,
    fields: string[],
    setFieldTouched: (fieldName: string, value: boolean) => void
  ) => {
    if (step > 0) fields.forEach((f: string) => setFieldTouched(f, true));
    setStep(step <= 0 ? 0 : step);
  };

  const renderStep = ({
    step,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleBlur
  }: IRenderStep) => {
    if (step === 0) {
      return (
        <Step1
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          setFieldError={setFieldError}
        />
      );
    }
    if (step === 1) {
      return (
        <Step2
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          setFieldError={setFieldError}
        />
      );
    }
  };

  useEffect(() => {
    appWrapperElement?.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <Formik
      initialValues={defaultFormData}
      validationSchema={BlogSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (isLoadingCreate || isLoadingEdit) return;
        if (isEdit) {
          handleEdit(
            { ...values },
            {
              onSuccess: (data) => {
                setToast({
                  type: 'success',
                  title: 'Successfuly updated',
                  msg: 'Your blog has been updated',
                  position: 'top',
                  autoClose: true
                });
                navigate(`/blog/${data.slug}`);
                setStep(0);
              }
            }
          );
        } else {
          handleCreate(values);
        }
        setSubmitting(false);
      }}
      validateOnBlur
      validateOnMount
    >
      {(props) => (
        <Form className={classes['form-wrapper']}>
          {!isLgMobile && (
            <FormSteps
              steps={steps}
              currentStep={currentStep}
              finishedSteps={finishedSteps}
              setCurrentStep={(step: number) => setCurrentStep(step)}
              errors={props.errors}
              fieldsByStep={fieldsByStep}
              onStepClick={(i) => {
                handleStepClick(i, fieldsByStep[i], props.setFieldTouched);
              }}
            />
          )}
          <div className={classes['form']}>
            {isLgMobile ? (
              <div className={classes['progress-bar-wrapper']}>
                <CircularProggress
                  steps={steps.length}
                  step={currentStep + 1}
                  percentage={((currentStep + 1) / steps.length) * 100}
                />
                <div className={classes['progress-bar-wrapper__labels']}>
                  <h5
                    className={`${classes['u-semiBold']} ${classes['u-body1']}`}
                  >
                    {steps[currentStep]}
                  </h5>
                  {currentStep < steps.length - 1 && (
                    <h5
                      className={`${classes['u-text--content']} ${classes['u-body3']}`}
                    >
                      {`Next: ${steps[currentStep + 1]}`}
                    </h5>
                  )}
                </div>
              </div>
            ) : (
              <h4 className={classes['u-light']}>{steps[currentStep]}</h4>
            )}
            {renderStep({
              step: currentStep,
              ...props
            })}
            <NavIsland
              isBlog
              isEdit={!!isEdit}
              steps={steps.length}
              isValid={!!props.isValid}
              currentStep={currentStep}
              setStep={setStep}
              onNextStepClick={() =>
                handleStepClick(
                  currentStep + 1,
                  fieldsByStep[currentStep + 1],
                  props.setFieldTouched
                )
              }
              submitForm={props.submitForm}
              isSubmitLoading={isLoadingCreate || isLoadingEdit}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateBlogForm;
