import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { currencyObj } from 'routes/dashboard/courses/single';
import Loader from 'components/loader/Loader';
import ErrorComponent from 'components/error/Error';
import CourseBundleForm from '../components/CourseBundleForm';
import { ICourse, ICourseBundle } from 'query/course-module/dto';
import { singleBundleDataQuery } from 'query/course-module/queries';
import { ICourseBundleFormFields } from 'utils/yupSchemas';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import classes from './EditBundle.module.scss';

const formSteps = [
  'Select Courses',
  'Arrange Courses',
  'Name & Description',
  'Pricing & Time Limit',
  'Summary'
];

const EditCourseBundle = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [finishedSteps, setFinishedSteps] = useState<number>(0);
  const { slug } = useParams();
  const [defaultFormData, setDefaultFormData] =
    useState<ICourseBundleFormFields>();

  const { isLoading, error, data } = useQuery<boolean, Error, ICourseBundle>({
    ...singleBundleDataQuery(slug as string),
    onSuccess: (data) => {
      const {
        _id,
        name,
        price,
        thumbnail,
        courses,
        description,
        not_finished,
        category,
        currency
      } = data;

      const selected: any = {};
      courses
        .map((course: ICourse, index) => {
          selected[index] = true;
          return course._id;
        })
        .toString();
      localStorage.setItem('selectedCourses', JSON.stringify(selected));

      setDefaultFormData({
        _id,
        name,
        price,
        discount: (data as any).discount,
        courses,
        courseIds: courses.map((course) => course._id),
        category: {
          label: category.name,
          value: category._id
        },
        description,
        thumbnail: thumbnail
          ? REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail
          : '',
        expiration: null,
        currency: currencyObj[currency].name,
        not_finished
      });
    }
  });

  if (isLoading) return <Loader size="lg" hasText withPadding />;

  if (error) return <ErrorComponent error={error} />;

  return (
    <div id="course-form__wrapper" className={classes['wrapper']}>
      <div className={classes['container']}>
        <h3 className={`${classes['u-semiBold']} ${classes['u-text--center']}`}>
          Edit Bundle
        </h3>
        {!!defaultFormData && (
          <CourseBundleForm
            isEdit
            steps={formSteps}
            finishedSteps={finishedSteps}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            setStep={(step: number) => {
              setCurrentStep(step);
              setFinishedSteps(step === 0 ? 0 : step);
            }}
            defaultFormData={defaultFormData}
          />
        )}
      </div>
    </div>
  );
};

export default EditCourseBundle;
