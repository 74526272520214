import { defineChain } from 'viem';

declare global {
  interface Window {
    _env_: any;
  }
}

export enum PROJECT_ID {
  OPUS_NFT = 1,
  OPUS_EDU = 2
}

export enum COURSE_STATUS {
  DRAFT = 0,
  PUBLISHING = 1,
  PUBLISHED = 2,
  PAUSED = 3
}

export enum COURSE_REVIEW_STATUS {
  INITIAL = 0,
  IN_REVIEW = 1,
  APPROVED = 2,
  REJECTED = 3
}

export enum COURSE_RATING {
  ONE_STAR = 0,
  TWO_STARS = 1,
  THREE_STARS = 2,
  FOUR_STARS = 3,
  FIVE_STARS = 4
}

export enum COURSE_EXPIRY {
  INDEFINITELY = 0,
  YEAR = 365 * 24 * 60 * 60 * 1000
}

export enum DIFFICULTY_LEVEL {
  BEGGINER = 0,
  INTERMEDIATE = 1,
  ADVANCED = 2,
  EXPERT = 3,
  ALL_LEVELS = 4
}

export enum VIDEO_STATUS {
  NO_VIDEO_FILE = 0,
  INITIAL = 1, // uploaded to GCloud
  READY = 2, // cron should move it from gcloud to vimeo
  PUBLISHED = 3 // uploaded to vimeo
}

export enum VIDEO_EDIT_STATUS {
  NEW = 0,
  DELETED = 1,
  EDITED = 2,
  INITIAL = 3
}

export enum TRANSACTION_STATUS {
  CLAIMED = 1,
  CLAIM = 2,
  REFUNDED = 3,
  REFUND = 4,
  LOCKED = 5,
  MANUALLY_REFUNDED = 6
}

export enum BUNDLE_STATUS {
  PRE_LIST = 0,
  PUBLISHED = 1
}

export const polygonAmoy = defineChain({
  id: 80_002,
  name: 'Polygon Amoy',
  network: 'maticamoy',
  nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  rpcUrls: {
    alchemy: {
      http: ['https://polygon-amoy.g.alchemy.com/v2'],
      webSocket: ['wss://polygon-amoy.g.alchemy.com/v2']
    },
    infura: {
      http: ['https://polygon-amoy.infura.io/v3'],
      webSocket: ['wss://polygon-amoy.infura.io/ws/v3']
    },
    default: {
      http: ['https://rpc.ankr.com/polygon_amoy']
    },
    public: {
      http: ['https://rpc.ankr.com/polygon_amoy']
    }
  },
  blockExplorers: {
    etherscan: {
      name: 'PolygonScan',
      url: 'https://www.oklink.com/amoy'
    },
    default: {
      name: 'PolygonScan',
      url: 'https://www.oklink.com/amoy'
    }
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 25770160
    }
  },
  testnet: true
});

export const REACT_APP_ACCOUNT_MANAGEMENT_API_ENDPOINT =
  window._env_.REACT_APP_ACCOUNT_MANAGEMENT_API_ENDPOINT;

export const REACT_APP_COURSES_API_ENDPOINT =
  window._env_.REACT_APP_COURSES_API_ENDPOINT;

export const REACT_APP_VIDEOS_API_ENDPOINT =
  window._env_.REACT_APP_VIDEOS_API_ENDPOINT;

export const REACT_APP_FILES_PUBLIC_DOMAIN =
  window._env_.REACT_APP_FILES_PUBLIC_DOMAIN;

export const REACT_APP_FILES_EDU_PUBLIC_DOMAIN =
  window._env_.REACT_APP_FILES_EDU_PUBLIC_DOMAIN;

export const REACT_APP_BLOCK_EXPLORER_URL =
  window._env_.REACT_APP_BLOCK_EXPLORER_URL;

export const REACT_APP_DEFAULT_TEMPLATE_ID =
  window._env_.REACT_APP_DEFAULT_TEMPLATE_ID;

export const REACT_APP_PLANET_ID = window._env_.REACT_APP_PLANET_ID;

export const REACT_APP_PLANET2_ID = window._env_.REACT_APP_PLANET2_ID;

export const REACT_APP_CHAIN_ID = window._env_.REACT_APP_CHAIN_ID;

export const REACT_APP_METAMASK_DEEPLINK =
  window._env_.REACT_APP_METAMASK_DEEPLINK;

export const REACT_APP_PAYMENT_CONTRACT_ADDRESS =
  window._env_.REACT_APP_PAYMENT_CONTRACT_ADDRESS;

export const REACT_APP_ACCESS_CONTRACT_ADDRESS =
  window._env_.REACT_APP_ACCESS_CONTRACT_ADDRESS;

export const REACT_APP_CURRENCY_CONTRACT_ADDRESS =
  window._env_.REACT_APP_CURRENCY_CONTRACT_ADDRESS;

export const REACT_APP_MIN_COURSE_PRICE =
  window._env_.REACT_APP_MIN_COURSE_PRICE;

export const REACT_APP_DEFAULT_SLIPPAGE =
  window._env_.REACT_APP_DEFAULT_SLIPPAGE;

export const REACT_APP_OPUS_NFT_URL = window._env_.REACT_APP_OPUS_NFT_URL;

export const REACT_APP_SEO_LARGE_IMAGE = window._env_.REACT_APP_SEO_LARGE_IMAGE;

export const REACT_APP_FILE_UPLOAD_CHUNK_SIZE_MB =
  window._env_.REACT_APP_FILE_UPLOAD_CHUNK_SIZE_MB;

export const REACT_APP_GOOGLE_ANALYTICS_ID =
  window._env_.REACT_APP_GOOGLE_ANALYTICS_ID;
