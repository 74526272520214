import React, { useCallback, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer, Slide } from 'react-toastify';
import OnboardingPopup from 'routes/onboarding/OnboardingPopup';
import { ROLE_FLAG, userContext } from 'context/userContext';
import { popupContext } from './context/popupContext';
import { appContext } from './context/appContext';
import Menu from 'components/menu/Menu';
import SEO from 'components/SEO/SEO';
import { Router } from './routes/Routes';
import { REACT_APP_GOOGLE_ANALYTICS_ID } from 'utils/constants';
import classes from './App.module.scss';

!!REACT_APP_GOOGLE_ANALYTICS_ID &&
  ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_ID);

function App() {
  const { pathname, search } = useLocation();
  const { theme } = useContext(appContext);
  const { popupData, setPopup } = useContext(popupContext);
  const { userData } = useContext(userContext);

  const renderToastify = useCallback(() => {
    return <ToastContainer transition={Slide} />;
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: pathname + search
    });
  }, [pathname, search]);

  useEffect(() => {
    if (
      userData &&
      userData.role_flag &&
      userData.role_flag !== ROLE_FLAG.ADMIN &&
      userData.role_flag !== ROLE_FLAG.MARKETING_MANAGER &&
      (!userData?.marketing_info ||
        (userData.marketing_info &&
          !Object.keys(userData?.marketing_info).length))
    ) {
      setPopup(<OnboardingPopup />);
    }
  }, [userData]);

  return (
    <div
      id="app_wrapper"
      className={classes['app__wrapper']}
      data-theme={theme}
    >
      {renderToastify()}
      {popupData}
      <Menu />
      <HelmetProvider>
        <SEO />
        <Router />
      </HelmetProvider>
    </div>
  );
}

export default App;
