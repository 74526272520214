import React, { useContext, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AdminUsersActionPopup from 'routes/dashboard/admin/users/components/AdminUsersActionPopup';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Web } from 'assets/icons/web.svg';
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg';
import { ReactComponent as Instagram } from 'assets/icons/ig.svg';
import { ReactComponent as Facebook } from 'assets/icons/facebook.svg';
import { windowContext } from 'context/windowsContext';
import Banner from 'components/banner/Banner';
import Avatar from 'components/avatar/Avatar';
import Button from 'components/buttons/Button';
import Tooltip from 'components/tooltip/Tooltip';
import Badge from 'components/badge/Badge';
import EmailPopup from 'components/popup/EmailPopup';
import VerifiedBadge from 'components/badge/VerifiedBadge';
import VerifyAccountPopup from 'components/popup/VerifyAccountPopup';
import { formatNames, isFieldPublic, truncateText } from 'utils/format';
import { USER_KYC_STATUS } from 'query/acc-module/dto';
import { IUser, userContext } from 'context/userContext';
import { popupContext } from 'context/popupContext';
import classes from '../Profile.module.scss';

interface ITopSection {
  data: IUser;
  isPublic?: boolean;
}

const TopSection = ({ data, isPublic }: ITopSection) => {
  const { userData } = useContext(userContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { windowSize } = useContext(windowContext);
  const { isMdMobile } = windowSize;
  const { setPopup } = useContext(popupContext);
  const queryClient = useQueryClient();

  const {
    first_name,
    last_name,
    pic,
    additional_info,
    hidden_fields,
    kyc_status,
    is_creator
  } = data;

  const { banner, website, twitter, instagram, facebook } =
    additional_info || {};

  const isWebsitePublic = isFieldPublic(hidden_fields, 'website');
  const isTwitterPublic = isFieldPublic(hidden_fields, 'twitter');
  const isIgPublic = isFieldPublic(hidden_fields, 'instagram');
  const isFacebookPublic = isFieldPublic(hidden_fields, 'facebook');
  const hasPublicSocials =
    (!!website && isWebsitePublic) ||
    (!!twitter && isTwitterPublic) ||
    (!!instagram && isIgPublic) ||
    (!!facebook && isFacebookPublic);

  useEffect(() => {
    state?.isVerifyOpened && setPopup(<VerifyAccountPopup />);
  }, [state, setPopup]);

  return (
    <>
      <Banner src={banner} />
      <div className={classes['user-info']}>
        <Avatar
          size="xl"
          src={pic}
          name={formatNames(first_name + last_name)}
        />
        <div className={classes['user-info__wrapper']}>
          <div className={classes['user__main']}>
            <div>
              <h1 className={`${classes['u-semiBold']} ${classes['u-h3']}`}>
                {formatNames(first_name + ' ' + last_name)}
              </h1>
              {!userData?.isAdmin && (
                <>
                  {kyc_status === USER_KYC_STATUS.APPROVED && (
                    <VerifiedBadge
                      isVisible={kyc_status === USER_KYC_STATUS.APPROVED}
                    />
                  )}
                  {(kyc_status === USER_KYC_STATUS.NOT_STARTED ||
                    kyc_status === USER_KYC_STATUS.CREATED ||
                    kyc_status === USER_KYC_STATUS.REJECTED) &&
                    (data._id === userData._id || userData.isAdmin) && (
                      <Tooltip
                        id="not-verified"
                        text={
                          kyc_status === USER_KYC_STATUS.REJECTED
                            ? 'Your account verification has been rejected.'
                            : "In order to purchase or publish courses, you'll need to verify your account."
                        }
                        position="right"
                      >
                        <Badge
                          text="Profile not Verified"
                          badgeType="error"
                          type="kyc"
                          onClick={() =>
                            data._id === userData._id &&
                            navigate('/user/about/main-info', {
                              state: {
                                isVerifyOpened: true
                              }
                            })
                          }
                        />
                      </Tooltip>
                    )}
                  {(kyc_status === USER_KYC_STATUS.PROCESSED ||
                    kyc_status === USER_KYC_STATUS.UPDATED) && (
                    <Tooltip
                      id="not-verified"
                      text="Please wait until your verification is processed."
                      position="right"
                    >
                      <Badge
                        text="Verification Pending"
                        badgeType="warning"
                        type="kyc"
                      />
                    </Tooltip>
                  )}
                </>
              )}
            </div>
            {hasPublicSocials && (
              <div className={classes['user__socials']}>
                {!!website && isWebsitePublic && (
                  <div className={classes['user__socials__item']}>
                    <Link
                      to={website.includes('http') ? website : `//${website}`}
                      target="_blank"
                    >
                      {isMdMobile ? (
                        <Button icon={Web} variant="neutral" isIconBtn />
                      ) : (
                        <>
                          <Web />
                          {truncateText(website, 20)}
                        </>
                      )}
                    </Link>
                  </div>
                )}
                {!!twitter && isTwitterPublic && (
                  <div className={classes['user__socials__item']}>
                    <Link to={`https://twitter.com/${twitter}`} target="_blank">
                      {isMdMobile ? (
                        <Button icon={Twitter} variant="neutral" isIconBtn />
                      ) : (
                        <>
                          <Twitter />
                          {truncateText(twitter, 20)}
                        </>
                      )}
                    </Link>
                  </div>
                )}
                {!!instagram && isIgPublic && (
                  <div className={classes['user__socials__item']}>
                    <Link
                      to={`https://www.instagram.com/${instagram}`}
                      target="_blank"
                    >
                      {isMdMobile ? (
                        <Button icon={Instagram} variant="neutral" isIconBtn />
                      ) : (
                        <>
                          <Instagram />
                          {truncateText(instagram, 20)}
                        </>
                      )}
                    </Link>
                  </div>
                )}
                {!!facebook && isFacebookPublic && (
                  <div className={classes['user__socials__item']}>
                    <Link
                      to={`https://www.facebook.com/${facebook}`}
                      target="_blank"
                    >
                      {isMdMobile ? (
                        <Button icon={Facebook} variant="neutral" isIconBtn />
                      ) : (
                        <>
                          <Facebook />
                          {truncateText(facebook, 20)}
                        </>
                      )}
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* Profile actions - PLEASE DO NOT FORGET to apply same changes to mobile profile actions below */}
          {!isPublic && !userData?.isAdmin && (
            <div className={classes['user__actions']}>
              {kyc_status !== USER_KYC_STATUS.APPROVED && (
                <Button
                  onClick={() => {
                    queryClient.invalidateQueries(['me-data']);
                    setPopup(<VerifyAccountPopup />);
                  }}
                  variant="neutral"
                  minWidth="md"
                >
                  Verify Account
                </Button>
              )}
              {kyc_status === USER_KYC_STATUS.APPROVED && (
                <Button
                  variant="neutral"
                  onClick={() =>
                    navigate(is_creator ? '/user/sales' : '/user/purchases')
                  }
                  minWidth="md"
                >
                  My Transactions
                </Button>
              )}
              <Button
                onClick={() => navigate('/bundle/create')}
                variant="neutral"
                minWidth="md"
              >
                Create Bundle
              </Button>
              <Button
                onClick={() => navigate('/courses/create')}
                icon={Plus}
                minWidth="md"
              >
                Create Course
              </Button>
              {/* <Button
                onClick={() => {}}
                icon={Dots}
                isIconBtn
                variant="neutral"
              /> */}
            </div>
          )}
          {userData?.isAdmin && !data?.is_edu_admin && !data?.isAdmin && (
            <div className={classes['user__actions']}>
              <Button
                minWidth="sm"
                variant="neutral"
                onClick={() =>
                  setPopup(
                    <AdminUsersActionPopup
                      action={data.is_banned ? 'activate' : 'ban'}
                      users={[data]}
                    />
                  )
                }
              >
                {data.is_banned ? 'Activate' : 'Ban'}
              </Button>
              <Button
                minWidth="sm"
                variant="neutral"
                onClick={() => setPopup(<EmailPopup users={[data]} />)}
              >
                Send Email
              </Button>
            </div>
          )}
          {/* Mobile profile actions */}
          {userData?.isAdmin && !data?.is_edu_admin && !data?.isAdmin && (
            <div
              className={`${classes['user__actions']} ${classes['user__actions--mobile']}`}
            >
              <Button
                minWidth="sm"
                variant="neutral"
                onClick={() =>
                  setPopup(
                    <AdminUsersActionPopup
                      action={data.is_banned ? 'activate' : 'ban'}
                      users={[data]}
                    />
                  )
                }
              >
                {data.is_banned ? 'Activate' : 'Ban'}
              </Button>
              <Button
                minWidth="sm"
                variant="neutral"
                onClick={() => setPopup(<EmailPopup users={[data]} />)}
              >
                Send Email
              </Button>
            </div>
          )}
          {!isPublic && !userData?.isAdmin && (
            <div
              className={`${classes['user__actions']} ${classes['user__actions--mobile']}`}
            >
              {kyc_status !== USER_KYC_STATUS.APPROVED && (
                <Button
                  onClick={() => {
                    queryClient.invalidateQueries(['me-data']);
                    setPopup(<VerifyAccountPopup />);
                  }}
                  variant="neutral"
                  minWidth="md"
                >
                  Verify Account
                </Button>
              )}
              <div className={classes['user__actions__group']}>
                {kyc_status === USER_KYC_STATUS.APPROVED && (
                  <Button
                    onClick={() =>
                      navigate(is_creator ? '/user/sales' : '/user/purchases')
                    }
                    variant="neutral"
                  >
                    My Transactions
                  </Button>
                )}
                <Button
                  onClick={() => navigate('/bundle/create')}
                  variant="neutral"
                  minWidth="md"
                >
                  Create Bundle
                </Button>
                <Button icon={Plus} onClick={() => navigate('/courses/create')}>
                  Create Course
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TopSection;
