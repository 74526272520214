import React, { useContext } from 'react';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { appContext } from 'context/appContext';
import Divider from 'components/divider/Divider';
import Tooltip from 'components/tooltip/Tooltip';
import { ICourseFormFields } from 'utils/yupSchemas';
import { COURSE_EXPIRY } from 'utils/constants';
import { formatPrice } from 'utils/format';
import { REACT_APP_FILES_PUBLIC_DOMAIN } from 'utils/constants';
import Badge from 'components/badge/Badge';
import Player from 'components/player/Player';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import classes from './Form.module.scss';

interface ISummary {
  data: ICourseFormFields;
  // Intentionally set as any to prevent an error
  errors: any;
  setStep?: (step: number) => void;
}

const Summary = ({ data, errors, setStep }: ISummary) => {
  const {
    name,
    category,
    short_description,
    description,
    thumbnail,
    activation_nft_image,
    video_preview,
    expiration,
    currency,
    price,
    cpe,
    template_id,
    difficulty_level,
    time_to_complete,
    requirements,
    agenda,
    skills
  } = data;

  const mainInfo: {
    label: string;
    value: string | undefined;
    key: string;
  }[] = [
    { label: 'Course Name', value: name, key: 'name' },
    { label: 'Course Category', value: category?.label, key: 'category' },
    // {
    //   label: 'Course Subcategory',
    //   value: subcategory?.label,
    //   key: 'subcategory'
    // },
    {
      label: 'Course Short Description',
      value: short_description,
      key: 'short_description'
    },
    { label: 'Course Description', value: description, key: 'description' },
    {
      label: 'Course Difficulty Level',
      value: difficulty_level[0]?.label,
      key: 'difficulty'
    },
    {
      label: 'Course Requirements Level',
      value: requirements,
      key: 'requirements'
    },
    {
      label: 'Time to Complete',
      value: time_to_complete?.toString(),
      key: 'time_to_complete'
    },
    { label: 'Course Agenda', value: agenda, key: 'agenda' }
  ];

  const previewInfo = [
    { label: 'Course Thumbnail', value: thumbnail, key: 'thumbnail' },
    {
      label: 'Activation NFT',
      value: activation_nft_image,
      key: 'activation_nft_image'
    },
    {
      label: 'Course Video Preview',
      value: video_preview,
      key: 'video_preview'
    }
  ];

  const courseCurrency = currency ? currency.label : '';
  const currencySymbol = currency ? currency.symbol : '';
  const coursePrice = parseFloat(price) || 0;
  const {
    platformSettings: { platform_fee }
  } = useContext(appContext);
  const platformEarning = ((coursePrice * platform_fee) / 100).toFixed(2);
  const creatorEarning = ((coursePrice * (100 - platform_fee)) / 100).toFixed(
    2
  );

  const priceInfo = [
    {
      label: 'Course Time Limit',
      value: expiration[0].value,
      key: 'expiration'
    },
    {
      label: 'Course Price',
      value: coursePrice
        ? `${formatPrice(coursePrice)} ${courseCurrency}`
        : '-',
      key: 'price'
    }
  ];

  const templateInfo = !!template_id.length
    ? {
        id: template_id[0]?.value,
        name: template_id[0]?.label,
        img: template_id[0]?.img
      }
    : {};

  const renderFieldLabel = (key: string, label: string, step: number) => {
    if (!errors || !errors[key])
      return <div className={classes['row__title']}>{label}</div>;

    return (
      <div
        onClick={() => !!setStep && setStep(step)}
        className={`${classes['row__title']} ${classes['row__title--error']}`}
      >
        <Tooltip
          id={`tooltip-${label}`}
          text={`In order to proceed with course creation, please enter valid ${label}.`}
        >
          <span>
            {label}
            <Warning />
          </span>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={classes['summary-wrapper']}>
      <h4 className={classes['u-light']}>Main Information</h4>
      {mainInfo.map((field, i) => {
        return (
          <div key={`${field.label}-${i}`} className={classes['row']}>
            {renderFieldLabel(field.key, field.label, 0)}
            <div className={classes['row__desc']}>
              <TextWithCharLimit
                text={field.value || '-'}
                limit={10000}
                hideBtn
              />
            </div>
          </div>
        );
      })}
      {!!skills.length && (
        <div className={classes['badge-summary-container']}>
          <h5 className={`${classes['u-body2']} ${classes['u-semiBold']}`}>
            Skills
          </h5>
          <div className={`${classes['badge-wrapper-summary']}`}>
            {skills?.map((skill: string) => (
              <Badge
                key={skill}
                text={skill}
                variant="outline"
                badgeType="info"
              />
            ))}
          </div>
        </div>
      )}
      <Divider dir="horizontal" />
      <h4 className={classes['u-light']}>Thumbnails & Preview</h4>
      <div className={`${classes['row']} ${classes['row--flex']}`}>
        {previewInfo.map((field, i) => {
          const isVideo = field.label === 'Course Video Preview';
          return (
            <div
              key={`${field.label}-${i}`}
              className={classes['row__img-wrapper']}
            >
              {renderFieldLabel(field.key, field.label, 1)}
              {field.value ? (
                isVideo ? (
                  <div className={classes['row__img']}>
                    <Player src={field.value} height="128px" hasControls />
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundImage: `url(${field.value})`
                    }}
                    className={classes['row__img']}
                  />
                )
              ) : (
                '-'
              )}
            </div>
          );
        })}
      </div>
      <Divider dir="horizontal" />
      <h4 className={classes['u-light']}>Price Summary</h4>
      {priceInfo.map((field, i) => {
        const isPrice = field.label === 'Course Price';
        return (
          <div key={`${field.label}-${i}`} className={classes['row']}>
            {renderFieldLabel(field.key, field.label, 2)}
            <div
              className={`${classes['row__desc']} ${
                isPrice ? classes['row__desc--flex'] : ''
              }`}
            >
              {isPrice && currencySymbol && (
                <img src={currencySymbol} alt="Symbol" width={24} height={24} />
              )}
              {isPrice
                ? field.value
                : field.value === COURSE_EXPIRY.INDEFINITELY
                ? 'Indefinitely'
                : '1 Year'}
            </div>
          </div>
        );
      })}
      {!!coursePrice && (
        <div
          className={`${classes['price-box']} ${classes['price-box--bordered']}`}
        >
          <div className={classes['price-box__row']}>
            <div className={classes['price-box__title']}>Platform Fee</div>
            <div className={classes['price-box__value']}>{platform_fee}%</div>
          </div>
          <div className={classes['price-box__row']}>
            <div className={classes['price-box__title']}>Platform Earning</div>
            <div className={classes['price-box__value']}>
              {`${formatPrice(+platformEarning)} ${courseCurrency}` || '-'}
            </div>
          </div>
          <div className={classes['price-box__row']}>
            <div className={classes['price-box__title']}>Creator Earning</div>
            <div className={classes['price-box__value']}>
              {`${formatPrice(+creatorEarning)} ${courseCurrency}` || '-'}
            </div>
          </div>
        </div>
      )}
      {!cpe.length && (
        <>
          <Divider dir="horizontal" />
          <h4 className={classes['u-light']}>Certificate</h4>
          <div className={classes['row']}>
            {templateInfo.id ? (
              <div
                className={`${classes['row__img-wrapper']} ${classes['row__img-wrapper--flex']}`}
              >
                <img
                  src={`${REACT_APP_FILES_PUBLIC_DOMAIN + templateInfo.img}`}
                  style={{
                    objectFit: 'contain',
                    maxWidth: '192px'
                  }}
                  alt="cert"
                />
                <div className={classes['row__img-title']}>
                  {templateInfo.name}
                </div>
              </div>
            ) : (
              '-'
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Summary;
