import React from 'react';
import { ICard } from '../card/Card';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import classes from './Badge.module.scss';

interface IBadge extends Pick<ICard, 'badgeType'> {
  text: string;
  size?: 'sm' | 'lg';
  variant?: 'contrast' | 'outline';
  type?: 'general' | 'kyc';
  onClick?: () => void;
  isClosable?: boolean;
}

const Badge = ({
  text,
  size,
  variant,
  badgeType,
  type,
  isClosable,
  onClick
}: IBadge) => {
  return (
    <div
      className={`${classes['badge']} ${classes[`badge--${size}`]} ${
        classes[`badge--${variant}`]
      } ${classes[`badge--${badgeType}`]} ${
        classes[type === 'kyc' ? `badge--status` : '']
      } ${!!onClick ? classes['u-cursor--pointer'] : ''}`}
      onClick={onClick}
    >
      {text}
      {isClosable && <CloseIcon width={16} height={16} />}
      {type === 'kyc' && badgeType === 'warning' && <WarningIcon />}
      {type === 'kyc' && badgeType === 'error' && <InfoIcon />}
    </div>
  );
};

Badge.defaultProps = {
  text: 'Badge',
  size: 'lg',
  badgeType: 'info'
};

export default Badge;
