import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import Button from 'components/buttons/Button';
import { IFormik } from './OnboardingForm';
import {
  IOnboardingDtoExtended,
  IUserInfo,
  MARKETING_INFO_TYPE
} from 'query/acc-module/dto';
import classes from './OnboardingForm.module.scss';

interface IStep2
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldError'
    | 'handleBlur'
  > {
  steps: number;
  currentStep: number;
  setStep: (step: number) => void;
  renderPrevBtn: () => {};
  defaultFormData: IOnboardingDtoExtended;
}

export const Step2 = ({
  currentStep,
  setStep,
  renderPrevBtn,
  values,
  steps,
  errors,
  setFieldValue,
  defaultFormData
}: IStep2) => {
  const occupationFields = defaultFormData.userInfo.filter(
    (userInfo: IUserInfo) => {
      if (userInfo.type === MARKETING_INFO_TYPE.OCCUPATION) return userInfo;
    }
  );

  const roleFields = defaultFormData.userInfo.filter((userInfo: IUserInfo) => {
    if (userInfo.type === MARKETING_INFO_TYPE.ROLE) return userInfo;
  });

  return (
    <>
      <div className={classes['header']}>
        <h4 className={classes['u-semiBold']}>Tell us about your career</h4>
        <h5 className={`${classes['u-body2']} ${classes['u-text--content']}`}>
          We will use this in order to recommend the most suitable courses for
          you.
        </h5>
      </div>
      <div className={classes['user-info-fields']}>
        <h5 className={classes['u-body2']}>What is your occupation</h5>
        <div
          className={`${classes['user-info-fields__wrapper']} ${classes['occupation-wrapper']}`}
        >
          {occupationFields.map((userInfo: IUserInfo, index: number) => (
            <div
              key={index}
              className={`${classes['user-info-fields__wrapper__button']} ${
                classes[values.occupation === userInfo._id ? 'selected' : '']
              }`}
              onClick={() => setFieldValue('occupation', userInfo._id)}
            >
              {userInfo.value}
            </div>
          ))}
        </div>
        <h5 className={`${classes['u-body2']} ${classes['roles']}`}>
          What is your role
        </h5>
        <div className={classes['user-info-fields__wrapper']}>
          {roleFields?.map((userInfo: IUserInfo, index: number) => (
            <div
              key={index}
              className={`${classes['user-info-fields__wrapper__button']} ${
                classes[values.role === userInfo._id ? 'selected' : '']
              }`}
              onClick={() => setFieldValue('role', userInfo._id)}
            >
              {userInfo.value}
            </div>
          ))}
        </div>
      </div>
      <div className={classes['btns-container']}>
        <div className={classes['u-text--content']}>{`Step ${
          currentStep + 1
        } of ${steps}`}</div>
        <div className={classes['btns-wrapper']}>
          <>
            {renderPrevBtn()}
            <Button
              icon={Arrow}
              type="button"
              minWidth="lg"
              iconRotate={90}
              onClick={() => setStep(currentStep + 1)}
              isDisabled={!!errors.occupation || !!errors.role}
            >
              Next Step
            </Button>
          </>
        </div>
      </div>
    </>
  );
};

export default Step2;
