import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { windowContext } from 'context/windowsContext';
import Error from 'components/error/Error';
import BlogCard from './BlogCard';
import CardSkeleton from '../skeleton/CardSkeleton';
import CustomBlogCard from './CustomBlogCard';
import { IBlogData } from 'query/course-module/dto';
import classes from './BlogCardRow.module.scss';

interface IBlogCardRow {
  title?: string;
  actionBtnText?: string;
  queryData: {
    isLoading: boolean;
    error: Error | null;
    data: IBlogData[];
  };
  viewAllRoute?: string;
  isBlogPage?: boolean;
  page?: number;
}

const BlogCardRow = ({
  title,
  queryData,
  actionBtnText,
  viewAllRoute,
  isBlogPage,
  page
}: IBlogCardRow) => {
  const navigate = useNavigate();
  const { windowSize } = useContext(windowContext);
  const { isLgMobile, isSmMobile } = windowSize;
  const { data } = queryData;
  const blogData = data;
  const hasData = !!blogData && !!blogData.length;
  const topBlogPost: any = blogData.map((blog) => blog.isTopPost && blog)?.[0];

  const divideAndRoundToNextWholeNumber = (number: number) => {
    let result = number / 4;
    if (result % 1 !== 0) {
      // Check if result is not a whole number
      // Round up to the next whole number
      result = Math.ceil(result);
    }
    return result;
  };

  if (queryData.error) return <Error error={queryData.error} />;

  return (
    <div className={classes['wrapper']}>
      <div className={classes['card-row']}>
        {title && (
          <div className={classes['card-row__title']}>
            <h4 className={classes['u-bold']}>{title}</h4>
            {!!viewAllRoute && (
              <Link className={classes['u-text--content']} to={viewAllRoute}>
                {actionBtnText}
              </Link>
            )}
          </div>
        )}
        {/* Show custom blog card on the Blog Page only (Desktop version) */}
        {isBlogPage && !isLgMobile && page === 1 && topBlogPost && (
          <CustomBlogCard
            onClick={() => navigate(`/blog/${topBlogPost?.slug}`)}
            blog={topBlogPost}
            key={topBlogPost._id}
          />
        )}
        <div
          className={`${classes['card-row__row-container']} ${
            !hasData ? classes['card-row__row-container--empty'] : ''
          }`}
          style={{
            gridTemplateColumns: isLgMobile
              ? `repeat(${
                  isBlogPage ? (isSmMobile ? '1' : '2') : blogData?.length
                }, ${isBlogPage ? 'auto' : '320px'})`
              : `repeat(${isBlogPage ? '2' : '3'}, 1fr)`,
            gridTemplateRows: isLgMobile
              ? '1fr'
              : `repeat(${divideAndRoundToNextWholeNumber(
                  blogData?.length
                )}, auto)`
          }}
        >
          {queryData.isLoading && <CardSkeleton amount={4} variant="course" />}
          {hasData &&
            blogData.map(
              (blog) =>
                // Show top post if Blog Page && Desktop version
                (!isBlogPage ||
                  (isBlogPage && !isLgMobile && !blog.isTopPost) ||
                  (isBlogPage && isLgMobile)) && (
                  <BlogCard
                    blog={blog}
                    key={blog._id}
                    isBlogPage={!!isBlogPage}
                    onClick={() => navigate(`/blog/${blog.slug}`)}
                  />
                )
            )}
        </div>
      </div>
    </div>
  );
};

export default BlogCardRow;
