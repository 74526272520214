import React, { useContext } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import ThumbnailPlaceholder from 'assets/images/thumbnail-placeholder.png';
import GenericPopup from 'components/popup/GenericPopup';
import Button from 'components/buttons/Button';
import ContextMenu from 'components/buttons/ContextMenu';
import { popupContext } from 'context/popupContext';
import { ROLE_FLAG, userContext } from 'context/userContext';
import { IBlogData } from 'query/course-module/dto';
import { deleteBlogMutation } from 'query/course-module/mutations';
import { formatNames } from 'utils/format';
import { dateFormatUrl } from 'utils/static';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import classes from './CustomBlogCard.module.scss';

export interface IBlogCard {
  onClick?: () => void;
  blog: IBlogData;
}

const CustomBlogCard = ({ onClick, blog }: IBlogCard) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userData } = useContext(userContext);
  const { setPopup } = useContext(popupContext);
  const { _id, thumbnail, title, slug, topic, creator, date_created } = blog;

  // Delete blog mutation
  const { mutate: handleDelete } = useMutation({
    ...deleteBlogMutation(_id),
    onSuccess: () => {
      setPopup(<GenericPopup msg="You have successfully deleted a blog." />);
      queryClient.invalidateQueries({
        queryKey: ['blogs-data']
      });
    },
    onError: (err: Error) =>
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
          isClosable={false}
        />
      )
  });

  const showConfirmDeletePopup = () => {
    setPopup(
      <GenericPopup
        type="warning"
        title="Delete Blog?"
        msg="Are you sure that you want to delete this blog?"
        buttonName="Confirm"
        buttonAction={() => handleDelete({ id: _id })}
      />
    );
  };

  return (
    <div
      className={`${classes['card']} ${
        onClick ? classes['card--clickable'] : ''
      }`}
    >
      {userData.role_flag === ROLE_FLAG.MARKETING_MANAGER && (
        <div className={classes['context-wrapper']}>
          <ContextMenu
            children={
              <div className={classes['buttons-wrapper']}>
                <Button
                  icon={Edit}
                  iconPosition="left"
                  variant="link-neutral"
                  onClick={() => navigate(`/blog/edit/${slug}`)}
                >
                  Edit
                </Button>
                <Button
                  icon={DeleteIcon}
                  iconPosition="left"
                  variant="link-error"
                  onClick={() => {
                    showConfirmDeletePopup();
                  }}
                >
                  Delete
                </Button>
              </div>
            }
          />
        </div>
      )}
      <div
        className={classes['card__img-wrapper']}
        onClick={() => onClick && onClick()}
      >
        {!!thumbnail ? (
          <img
            alt={blog.title}
            src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail}
          />
        ) : (
          <img src={ThumbnailPlaceholder} alt={'OpusEDU'} />
        )}
      </div>
      <div
        className={classes['card__content']}
        onClick={() => onClick && onClick()}
      >
        <h4
          className={`${classes['card__title']} ${classes['u-semiBold']}
        `}
        >
          {title}
        </h4>
        <div className={classes['card__title']}>{topic}</div>
        <div className={classes['card__author']}>
          <UserIcon width={40} height={40} />
          <div>
            <div
              className={`${classes['card__title']} ${classes['u-body2']} ${classes['u-semiBold']} `}
            >
              {formatNames(creator?.first_name + ' ' + creator?.last_name)}
            </div>
            <div className={classes['card__title']}>
              {moment(date_created).format(dateFormatUrl)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CustomBlogCard.defaultProps = {
  onClick: null
};

export default CustomBlogCard;
