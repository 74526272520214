import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Play } from 'assets/icons/play.svg';
import { ReactComponent as PlayCircled } from 'assets/icons/play-circled.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { userContext } from 'context/userContext';
import Badge from 'components/badge/Badge';
import Progress from 'components/progress/Progress';
import Button from 'components/buttons/Button';
import { ICourse } from 'query/course-module/dto';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import { formatNames } from 'utils/format';
import classes from './HorizontalCard.module.scss';

interface IHorizontalCardProps {
  course: ICourse;
  withCloseBtn?: boolean;
}

const HorizontalCard = ({ course, withCloseBtn }: IHorizontalCardProps) => {
  const navigate = useNavigate();
  const { setShowContinueWatching } = useContext(userContext);

  const { thumbnail, slug, purchased, category, name, creator, progress } =
    course;

  return (
    <div className={classes['card']}>
      {withCloseBtn && (
        <Button
          className={classes['close-btn']}
          variant="neutral"
          isIconBtn
          icon={Cross}
          size="md"
          onClick={() => {
            setShowContinueWatching(false);
            localStorage.removeItem('showContinueWatching');
          }}
        />
      )}
      <div
        className={`${classes['card__thumb']} ${classes['u-cursor--pointer']}`}
        style={{
          backgroundImage: `url(${
            REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail
          })`
        }}
        onClick={() =>
          navigate(
            `/courses/${slug}/${purchased?.last_watched_video}/videos?changes=0`
          )
        }
      >
        <PlayCircled className={classes['play-icon']} height={96} width={96} />
      </div>
      <div className={classes['card__info']}>
        <div className={classes['card__info__details']}>
          <Badge
            text={category.name}
            variant="outline"
            size="sm"
            onClick={() => navigate(`/category/${category.slug}`)}
          />
          <div
            className={`${classes['u-semiBold']} ${classes['u-body1']} ${classes['u-cursor--pointer']}`}
            onClick={() => navigate(`/courses/${slug}?changes=0`)}
          >
            {name}
          </div>
          <div
            className={`${classes['u-text--content']} ${classes['u-cursor--pointer']}`}
            onClick={() => navigate(`/user/${creator.slug}/about/main-info`)}
          >
            {formatNames(creator.first_name + ' ' + creator.last_name)}
          </div>
        </div>
        <div className={classes['card__info__progress']}>
          <Progress progress={progress} className={classes['u-m16-bot']} />
          <Button
            variant="link-neutral"
            withPadding={false}
            icon={Play}
            iconPosition="left"
            onClick={() =>
              navigate(
                `/courses/${slug}/${purchased?.last_watched_video}/videos?changes=0`
              )
            }
          >
            Continue Watching
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HorizontalCard;
