import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CreateBlogForm from './CreateBlogForm';
import { windowContext } from 'context/windowsContext';
import { IBlogDataFormFields } from 'utils/yupSchemas';
import classes from './CreateBlog.module.scss';

const formSteps = ['Post Details', 'Blog Content'];

const CreateBlog = () => {
  const { state: locationState } = useLocation();
  const [currentStep, setCurrentStep] = useState<number>(
    locationState?.savedFormData?.step || 0
  );
  const [finishedSteps, setFinishedSteps] = useState<number>(
    locationState?.savedFormData?.step || 0
  );
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);

  const [defaultFormData, setDefaultFormData] = useState<IBlogDataFormFields>(
    locationState?.savedBundleFormData || {
      title: '',
      image: '',
      topic: '',
      tags: [],
      content: '',
      thumbnail: '',
      description: '',
      isTopPost: false
    }
  );

  return (
    <div id="course-form__wrapper" className={classes['wrapper']}>
      <div className={classes['container']}>
        {!isLgMobile && (
          <h3
            className={`${classes['u-semiBold']} ${classes['u-text--center']}`}
          >
            Create Blog
          </h3>
        )}
        <CreateBlogForm
          steps={formSteps}
          finishedSteps={finishedSteps}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          setStep={(step: number) => {
            setCurrentStep(step);
            setFinishedSteps(step === 0 ? 0 : step);
          }}
          defaultFormData={defaultFormData}
        />
      </div>
    </div>
  );
};

export default CreateBlog;
