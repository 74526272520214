import React, { useContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Actions from 'routes/dashboard/profile/components/Actions';
import { IOption } from 'components/input/InputWrapper';
import NoResults from 'components/noResults/NoResults';
import TableSkeleton from 'components/skeleton/TableSkeleton';
import { PurchasesCols } from 'components/table/PurchasesCols';
import Error from 'components/error/Error';
import Table from 'components/table/Table';
import { IFormik } from './CourseBundleForm';
import { BrowseCoursesCols } from 'components/table/BrowseCoursesCols';
import { appContext } from 'context/appContext';
import { windowContext } from 'context/windowsContext';
import {
  IFilterData,
  COURSE_CHANGES,
  ICourseCategoryData,
  ICourseData,
  IFilterOptions,
  ICourse
} from 'query/course-module/dto';
import {
  courseCategoriesDataQuery,
  creatorCoursesDataQuery
} from 'query/course-module/queries';
import { COURSE_STATUS } from 'utils/constants';
import { isSafari } from 'utils/helpers';
import classes from './Form.module.scss';

const sortOptions = [
  { label: 'Name A-Z', value: 'course_name' },
  { label: 'Name Z-A', value: '-course_name' },
  { label: 'Price ascending', value: 'course_price' },
  { label: 'Price descending', value: '-course_price' },
  { label: 'Date Purchased - ASC', value: 'buy_tx_confirmed_at' },
  { label: 'Date Purchased - DESC', value: '-buy_tx_confirmed_at' }
];

const categoriesFilter: IFilterData = {
  filterName: 'Category',
  filterOptions: []
};

interface IStep1
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldError'
    | 'handleBlur'
  > {}

export const Step1 = ({ setFieldValue }: IStep1) => {
  const { search: urlParams } = useLocation();
  const {
    page: pageNumber,
    search,
    sort,
    price,
    category,
    segment
  } = queryString.parse(urlParams);
  const pageSize: number = 11;
  const { showFilter, updateFilter } = useContext(appContext);
  const { windowSize } = useContext(windowContext);
  const { isMdMobile } = windowSize;
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  const [sortValue, setSortValue] = useState<string>((sort as string) || '');
  const [categoriesValue, setCategoriesValue] = useState<IOption[]>(
    categoriesFilter.filterOptions.filter((option: IFilterOptions) => {
      return category?.includes(option.value as string);
    })
  );

  const purchasesCols = useMemo(
    () =>
      PurchasesCols({
        hideActionBtn: false,
        isSelectable: true
      }),
    []
  );

  const queryParams = `?&limit=${pageSize}&page=${page}&status=${
    COURSE_STATUS.PUBLISHED
  }${searchValue.length > 1 ? `&search[name]=${searchValue}` : ''}${
    sortValue.length > 1 ? `&sort=${sortValue}` : ''
  }&changes=${COURSE_CHANGES.NO_CHANGES}${!!price ? `&price=${price}` : ''}${
    !!category ? `&category=${category}` : ''
  }${!!segment ? `&segment=${segment}` : ''}`;

  useQuery<boolean, Error, ICourseCategoryData>({
    ...courseCategoriesDataQuery(),
    onSuccess: (fetchedCategories) => {
      categoriesFilter.filterOptions = fetchedCategories.result
        .filter((category) => category.counter > 0)
        .map((category) => ({
          label: category.name,
          value: category.slug
        }));
      setCategoriesValue(
        categoriesFilter.filterOptions.filter(
          (option: IFilterOptions) => category?.includes(option.value as string)
        )
      );
    }
  });

  const { isLoading, error, data } = useQuery<boolean, Error, ICourseData>({
    ...creatorCoursesDataQuery(queryParams),
    onSuccess: (data) => {
      if (!price) {
        if (!!data?.maxCourse && !!data.minCourse) {
          localStorage.setItem('minCoursePrice', data.minCourse.toString());
          localStorage.setItem('maxCoursePrice', data.maxCourse.toString());
        } else {
          localStorage.removeItem('minCoursePrice');
          localStorage.removeItem('maxCoursePrice');
        }
        const event = new CustomEvent('coursePricesUpdated');
        document.dispatchEvent(event);
      }
    }
  });
  const { result: coursesData } = data || {};
  const hasData = !!coursesData && !!coursesData.length;

  const cols = useMemo(
    () => BrowseCoursesCols({ isSelectable: true, isBundlePage: true }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [coursesData]
  );

  const filterHandler = (value: IOption[], name: string) => {
    setPage(1);
    if (!name) {
      setCategoriesValue([]);
    }
    if (name === 'Category') {
      setCategoriesValue(value);
    }
  };

  return (
    <div
      className={`
      ${classes['course-bundle']} 
      ${showFilter ? classes['course-bundle--grid'] : ''}
    `}
    >
      {!!showFilter && (
        <Actions
          isInverted
          hasSort
          sortValue={sortValue}
          passSortValueUp={setSortValue}
          hasFilter
          filters={[{ ...categoriesFilter, filterValue: categoriesValue }]}
          passFilterValueUp={filterHandler}
          onFilterClick={updateFilter}
          showFilterMenu={showFilter}
          setPage={setPage}
          hasPriceFilter
        />
      )}
      {(!isMdMobile || (isMdMobile && !showFilter)) && (
        <div
          className={`${classes['course-bundle__table-wrapper']} 
          ${isSafari ? classes['safari-support-overflow'] : ''}`}
        >
          <Actions
            hasSearch
            isInverted
            isDebounced
            setPage={setPage}
            hasSort={!showFilter}
            sortValue={sortValue}
            hasFilter={!showFilter}
            sortOptions={sortOptions}
            searchValue={searchValue}
            onFilterClick={updateFilter}
            passSortValueUp={setSortValue}
            searchPlaceholder="Search Courses"
            passSearchValueUp={setSearchValue}
          />
          {!!data?.result.length && (
            <Table
              columns={cols}
              data={data.result}
              passSelectedRowsUp={(selectedRows) => {
                if (!!selectedRows.length) {
                  const results = data.result.map((course) => course._id);
                  const selected: any = {};
                  const courseIds = selectedRows
                    .map((course: ICourse) => {
                      const index = results.indexOf(course._id);
                      selected[index] = true;
                      return course._id;
                    })
                    .toString();

                  localStorage.setItem(
                    'selectedCourses',
                    JSON.stringify(selected)
                  );

                  setFieldValue('courses', selectedRows);
                  setFieldValue('courseIds', [courseIds]);
                } else {
                  localStorage.removeItem('selectedCourses');
                  setFieldValue('courses', []);
                  setFieldValue('courseIds', []);
                }
              }}
              initialStateProp={{
                selectedRowIds: localStorage.getItem('selectedCourses')
                  ? JSON.parse(localStorage.getItem('selectedCourses') as any)
                  : {}
              }}
              paginationProps={{
                activePage: page,
                setActivePage: setPage,
                pageSize: pageSize,
                totalCount: data.total_results as number,
                siblingCount: 1
              }}
            />
          )}
          {isLoading && (
            <TableSkeleton rows={pageSize} cols={purchasesCols.length} />
          )}
          {error && <Error error={error} />}
          {!hasData && !isLoading && !error && <NoResults />}
        </div>
      )}
    </div>
  );
};

export default IStep1;
