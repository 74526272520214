import { useLocation } from 'react-router-dom';
import { ReactComponent as ApproveIcon } from 'assets/icons/approve-icon.svg';
import { IResources } from '../components/Resources';
import Badge from 'components/badge/Badge';
import classes from './About.module.scss';

interface ISingleCourseAboutProps {
  courseProps: IResources['courseProps'];
}

const SingleCourseAbout = ({ courseProps }: ISingleCourseAboutProps) => {
  const { course } = courseProps;

  return (
    <div className={classes['about-container']}>
      <div className={classes['section']}>
        <h5 className={`${classes['u-body1']} ${classes['u-semiBold']}`}>
          Requirements
        </h5>
        <ul className={classes['requirements']}>
          {course.requirements
            ?.replace(/[\n\r\t]/gm, '')
            ?.split('.')
            .map(
              (el) =>
                !!el && (
                  <li className={classes['u-text--content']}>&#8226; {el}</li>
                )
            )}
        </ul>
      </div>
      <div className={classes['section']}>
        <h5 className={`${classes['u-body1']} ${classes['u-semiBold']}`}>
          Agenda
        </h5>
        <div className={classes['agenda-bullets']}>
          {course.agenda
            ?.replace(/[\n\r\t]/gm, '')
            ?.split('.')
            .map(
              (el) =>
                !!el && (
                  <div className={classes['bullet']}>
                    <span>
                      <ApproveIcon width={24} height={24} />
                    </span>
                    <h5
                      className={`${classes['u-text--content']} ${classes['u-body2']}`}
                    >
                      {el}
                    </h5>
                  </div>
                )
            )}
        </div>
      </div>
      <div className={classes['section']}>
        <h5 className={`${classes['u-body1']} ${classes['u-semiBold']}`}>
          Skills
        </h5>
        <div className={classes['badge-wrapper']}>
          {course?.skills?.map((skill) => (
            <Badge
              key={skill}
              text={skill}
              variant="outline"
              badgeType="info"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleCourseAbout;
