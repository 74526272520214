import React, { useContext } from 'react';
import { TransactionReceipt } from 'ethers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { popupContext } from 'context/popupContext';
import { toastContext } from 'context/toastContext';
import { Web3Context } from 'context/web3Context';
import {
  refundMutation,
  resetRefundMutation
} from 'query/course-module/mutations';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import Button from 'components/buttons/Button';
import {
  REACT_APP_BLOCK_EXPLORER_URL,
  REACT_APP_FILES_EDU_PUBLIC_DOMAIN
} from 'utils/constants';
import { getContractInstance } from 'contracts/contracts';
import { useEthersSigner } from 'hooks/useEthersSigner';
import { submitTx } from 'utils/requests';
import classes from './RefundPopup.module.scss';

interface IRefundPopupProps {
  data: {
    receiptId: number;
    purchaseId: string;
    activation_nft_image: string;
  };
}

const RefundPopup = ({ data }: IRefundPopupProps) => {
  const queryClient = useQueryClient();
  const { clearPopup } = useContext(popupContext);
  const { setToast } = useContext(toastContext);
  const { increasePendingTransactions, decreasePendingTransactions } =
    useContext(Web3Context);
  const { signer } = useEthersSigner();

  const { mutate: refundMutationHandler } = useMutation({
    ...refundMutation([data.purchaseId]),
    onSuccess: () => queryClient.invalidateQueries(['refund'])
  });

  const { mutate: resetRefundMutationHandler } = useMutation({
    ...resetRefundMutation([data.purchaseId]),
    onSuccess: () => queryClient.invalidateQueries(['refund-reset'])
  });
  const { isLoading, mutate: refundHandler } = useMutation({
    mutationFn: async () => {
      refundMutationHandler();
      increasePendingTransactions();

      const contract = getContractInstance('payment', signer);
      const txData = [data.receiptId];

      const receipt = await submitTx(contract, 'refund', txData);
      return receipt as TransactionReceipt;
    },
    onSuccess: (receipt: TransactionReceipt) => {
      clearPopup();
      decreasePendingTransactions();
      setToast({
        type: 'success',
        title: 'Successfull Refund',
        msg: 'Successfully refunded. View',
        linkMsg: 'transaction',
        linkTo: `${REACT_APP_BLOCK_EXPLORER_URL}/tx/${receipt.hash}`,
        position: 'top',
        autoClose: true
      });
    },
    onError: async (err: any) => {
      resetRefundMutationHandler();
      decreasePendingTransactions();
      setToast({
        type: 'error',
        position: 'top',
        title: 'Transaction Failed',
        msg: err.message || '',
        autoClose: true
      });
    }
  });

  return (
    <ModalWrapper size="md" onClose={() => clearPopup()}>
      <div className={classes['popup-container']}>
        <div className={classes['content']}>
          <h4 className={classes['u-semiBold']}>Refund Token</h4>
          <p className={classes['u-body2']}>
            Token Refunds are our commitment to provide a seamless refund
            experience for our valued customers. With Token Refunds, you can
            receive your money back promptly.
          </p>
        </div>
        {data?.activation_nft_image && (
          <img
            src={`${REACT_APP_FILES_EDU_PUBLIC_DOMAIN}${data.activation_nft_image}`}
            alt="Activation NFT"
          />
        )}
        <div className={classes['btns-container']}>
          <Button
            onClick={() => refundHandler()}
            isLoading={isLoading}
            variant="contrast"
            minWidth="full"
            isWeb3
          >
            Refund
          </Button>
          <Button variant="link-neutral" minWidth="full" onClick={clearPopup}>
            Cancel
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default RefundPopup;
