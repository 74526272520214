import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Loader from 'components/loader/Loader';
import ErrorComponent from 'components/error/Error';
import CreateBlogForm from '../create/CreateBlogForm';
import { IBlogData } from 'query/course-module/dto';
import { singleBlogDataQuery } from 'query/course-module/queries';
import { IBlogDataFormFields } from 'utils/yupSchemas';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import classes from './EditBlog.module.scss';

const formSteps = ['Post Details', 'Blog Content'];

const EditBlog = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [finishedSteps, setFinishedSteps] = useState<number>(0);
  const { slug } = useParams();
  const [defaultFormData, setDefaultFormData] = useState<IBlogDataFormFields>();

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    { blog: IBlogData }
  >({
    ...singleBlogDataQuery(slug as string),
    onSuccess: (data) => {
      const blog = data.blog;
      const {
        title,
        topic,
        image,
        content,
        description,
        thumbnail,
        isTopPost,
        tags
      } = blog;

      setDefaultFormData({
        title,
        topic,
        content,
        tags,
        isTopPost: Boolean(isTopPost),
        description,
        thumbnail: thumbnail
          ? REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail
          : '',
        image: image ? REACT_APP_FILES_EDU_PUBLIC_DOMAIN + image : ''
      });
    }
  });

  if (isLoading) return <Loader size="lg" hasText withPadding />;

  if (error) return <ErrorComponent error={error} />;

  return (
    <div id="course-form__wrapper" className={classes['wrapper']}>
      <div className={classes['container']}>
        <h3 className={`${classes['u-semiBold']} ${classes['u-text--center']}`}>
          Edit Blog
        </h3>
        {!!defaultFormData && (
          <CreateBlogForm
            steps={formSteps}
            finishedSteps={finishedSteps}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            setStep={(step: number) => {
              setCurrentStep(step <= 0 ? 0 : step >= 2 ? 2 : step);
              setFinishedSteps(step <= 0 ? 0 : step >= 2 ? 2 : step);
            }}
            blog={data.blog}
            defaultFormData={defaultFormData as IBlogDataFormFields}
            isEdit
          />
        )}
      </div>
    </div>
  );
};

export default EditBlog;
