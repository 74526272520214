import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Learn } from 'assets/icons/learning-icon.svg';
import { ReactComponent as Movie } from 'assets/icons/movie-icon.svg';
import { ReactComponent as Wallet } from 'assets/icons/wallet-icon.svg';
import { ReactComponent as Proof } from 'assets/icons/proof-icon.svg';
import LandingScreen from 'assets/images/landing-page-scr.png';
import VerifyScreen from 'assets/images/verify-image.png';
import InterestsScreen from 'assets/images/interests-scr.png';
import WalletScreen from 'assets/images/connect-wallet-image.png';
import CreateCourseScreen from 'assets/images/create-course-image.png';
import StepRow from './components/StepRow';
import Button from 'components/buttons/Button';
import CustomBreadcrumbs from 'components/breadcrumbs/CustomBreadcrumbs';
import { gtmEventHandler } from 'utils/google-tag-manager';
import classes from './HowToPage.module.scss';

const HowToPage = () => {
  const navigate = useNavigate();
  const [selectedStep, setSelectedStep] = useState(0);

  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'Getting Started', link: '/how-to' }
  ];

  const steps = [
    {
      step: 'Sign up and create your OpusEdu account',
      guide:
        "Kickstart your journey by creating an OpusEdu account. It's quick and easy - all you have to do is fill in your email, name and create a password.",
      action:
        'To activate your account, click the link in the confirmation email sent to your inbox.',
      image: LandingScreen
    },
    {
      step: 'Personalise your experience',
      guide:
        "Next, we'll guide you through a tailored discovery journey where you can explore and select the topics that excite you the most.",
      action:
        'This helps us personalize your experience, recommending courses that match your learning objectives. For creators, this helps us offer incentives over time to support your contributions.',
      image: InterestsScreen
    },
    {
      step: 'Verify your identity',
      guide:
        "You're now ready to verify your identity. To begin, make sure your device (mobile or desktop) has a camera and uses a compatible browser. Grant camera access to OpusEdu, and you're ready to start.",
      action:
        "You'll need to take a photo of your ID, passport, or driver's license. Follow the simple on-screen steps to capture a clear, well-lit image of your document. If the image isn't perfect, don't stress - you can retake it as many times as you need. \n  \n  Next, we'll ask for a quick selfie. Position your face in the frame on your screen and ensure your lighting is good. Follow the instructions to snap a photo that matches your preferred document. \n  \n  Once your images are submitted, the system will process your details. If everything checks out, you'll be ready to dive into OpusEdu in no time.",
      image: VerifyScreen
    },
    {
      step: 'Connect your wallet',
      guide:
        "To start learning or teaching on OpusEdu, connect your crypto wallet by selecting MetaMask, Coinbase, or WalletConnect. Follow the on-screen prompts, and you'll be ready to access courses or publish your own.",
      actionWithLink:
        'For a step-by-step guide, watch this <a href="https://player.vimeo.com/video/1018584267?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479" target="_blank">video</a> tutorial to ensure a seamless wallet connection experience.',
      image: WalletScreen
    },
    {
      step: 'Start exploring or creating',
      guide:
        'As a learner, browse courses and enroll with ease using your connected wallet for secure payments.',
      action:
        'As a creator, start building and publishing your courses, set your own prices, and begin earning securely through direct crypto payments. Your journey starts now!',
      image: CreateCourseScreen
    }
  ];

  return (
    <div className={classes['how-to-wrapper']}>
      <CustomBreadcrumbs crumbs={crumbs} />
      <div className={`${classes['header']} ${classes['header-items']}`}>
        <h5
          className={`${classes['link-contrast']} ${classes['u-body1']} ${classes['u-semiBold']}`}
        >
          Getting Started
        </h5>
        <h2 className={classes['u-semiBold']}>Getting Started With OpusEdu </h2>
      </div>
      <div className={`${classes['header-items']} ${classes['features']}`}>
        <h5
          className={`${classes['link-contrast']} ${classes['u-body1']} ${classes['u-semiBold']}`}
        >
          Features
        </h5>
        <h3 className={classes['u-semiBold']}>Why Join OpusEdu?</h3>
        <div className={classes['cards']}>
          <div className={classes['card']}>
            <Learn />
            <h5 className={`${classes['u-semiBold']}`}>
              Simple learning and teaching
            </h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              We've streamlined every step, whether you're mastering a new skill
              or building a global audience for your expertise.
            </h5>
          </div>
          <div className={classes['card']}>
            <Proof />
            <h5 className={`${classes['u-semiBold']}`}>
              Immutable proof of achievement
            </h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Earn and issue blockchain-verified certificates that are
              permanent, secure, and recognized worldwide.
            </h5>
          </div>
          <div className={classes['card']}>
            <Wallet />
            <h5 className={`${classes['u-semiBold']}`}>
              Transparency for everyone
            </h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Enjoy a seamless experience with no hidden fees, secure crypto
              payments, and total control over your growth.
            </h5>
          </div>
        </div>
      </div>
      <div className={`${classes['header-items']} ${classes['guide']}`}>
        <h3 className={classes['u-semiBold']}>How to get started</h3>
        <div className={classes['steps-section']}>
          <div className={classes['steps-wrapper']}>
            {steps.map((step, index) => (
              <StepRow
                step={step}
                index={index}
                selected={selectedStep}
                setSelected={setSelectedStep}
              />
            ))}
          </div>
          <div className={classes['img-wrapper']}>
            <img
              src={steps[selectedStep]?.image || steps[0].image}
              alt="image"
            />
          </div>
        </div>
      </div>
      <div className={`${classes['footer']}`}>
        <div className={`${classes['header-items']}`}>
          <h3 className={`${classes['u-semiBold']}`}>
            Start your journey with OpusEdu
          </h3>
          <h5 className={`${classes['u-text--content']} ${classes['u-body1']}`}>
            Let's build the future of education - together.
          </h5>
        </div>
        <div className={classes['footer-cards']}>
          <div className={classes['card']}>
            <Learn />
            <h5 className={`${classes['u-semiBold']}`}>For learners</h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Learn it. Prove it. Own it. Get blockchain-certified credentials
              that validate your true skills.
            </h5>
            <Button
              size="sm"
              variant="neutral"
              className="gtm-button"
              onClick={() => {
                gtmEventHandler('click_start_learning');
                navigate('/courses');
              }}
            >
              Start learning
            </Button>
          </div>
          <div className={classes['card']}>
            <Movie />
            <h5 className={`${classes['u-semiBold']}`}>For creators</h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Share what you know, earn what you deserve. Teach globally and
              transform your expertise into income on OpusEdu.
            </h5>
            <Button
              size="sm"
              variant="neutral"
              className="gtm-button"
              onClick={() => {
                gtmEventHandler('click_create_course');
                navigate('/courses/create');
              }}
            >
              Create a course
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToPage;
