import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import OnboardingForm from './OnboardingForm';
import { IOnboardingDto, IUserInfo } from 'query/acc-module/dto';
import { userMarketingInfoQuery } from 'query/acc-module/queries';
import classes from './OnboardingPopup.module.scss';

const formSteps = ['Interests', 'Occupation & Role', 'Reason'];

const OnboardingPopup = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { data } = useQuery<boolean, Error, { type: number }[]>({
    ...userMarketingInfoQuery()
  });

  const defaultFormData: IOnboardingDto = {
    role: '',
    occupation: '',
    interests: [],
    platform_usage_reasons: []
  };

  return (
    <ModalWrapper size="xl" isClosable={false}>
      <div className={classes['wrapper']}>
        <OnboardingForm
          steps={formSteps.length}
          currentStep={currentStep}
          setStep={(step: number) => {
            setCurrentStep(step);
          }}
          defaultFormData={{
            ...defaultFormData,
            userInfo: data as IUserInfo[]
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default OnboardingPopup;
