import React, { useCallback, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as Play } from 'assets/icons/play.svg';
import { ReactComponent as Upload } from 'assets/icons/upload.svg';
import { ReactComponent as Change } from 'assets/icons/change.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
// import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as DragAndDropIcon } from 'assets/icons/drag-and-drop.svg';
import { popupContext } from 'context/popupContext';
import { windowContext } from 'context/windowsContext';
import { toastContext } from 'context/toastContext';
import { userContext } from 'context/userContext';
import { deleteVideoMutation } from 'query/course-module/mutations';
import Badge from '../badge/Badge';
import Button from '../buttons/Button';
import Divider from '../divider/Divider';
import GenericPopup from '../popup/GenericPopup';
import Progress from '../progress/Progress';
import Loader from '../loader/Loader';
import Tooltip from 'components/tooltip/Tooltip';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import EditVideoDetailsPopup from 'routes/dashboard/courses/single/videos/add/EditVideoDetailsPopup';
import ChildWithIndicator from 'routes/dashboard/courses/single/components/ChildWithIndicator';
import PlayVideoPopup from 'routes/dashboard/courses/single/videos/popups/PlayVideoPopup';
import { IExtendedResourceVideo } from 'routes/dashboard/courses/single/videos/components/DragAndDrop';
import { IResources } from 'routes/dashboard/courses/single/components/Resources';
import { VideoChangesMenu } from 'routes/dashboard/courses/single/components/Changes/VideoChangesMenu';
import { COURSE_CHANGES, COURSE_RELATIONSHIP } from 'query/course-module/dto';
import {
  COURSE_STATUS,
  REACT_APP_FILES_EDU_PUBLIC_DOMAIN,
  VIDEO_EDIT_STATUS,
  VIDEO_STATUS
} from 'utils/constants';
import { isCourseStatus } from 'utils/helpers';
import classes from './VideoCard.module.scss';

interface IVideoCard {
  card: IExtendedResourceVideo;
  handleVideoChange: (id: string) => void;
  canDrag: boolean;
  courseProps: IResources['courseProps'];
}

const VideoCard = ({
  card,
  handleVideoChange,
  canDrag,
  courseProps
}: IVideoCard) => {
  const { videoId } = useParams();
  const navigate = useNavigate();
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const { setPopup, setMenu } = useContext(popupContext);
  const { windowSize } = useContext(windowContext);
  const { setToast } = useContext(toastContext);
  const { userData } = useContext(userContext);
  const { isLgMobile } = windowSize;
  const queryClient = useQueryClient();

  const { course, isPublic, isCourseActivated, isVideoPage, isAdminPage } =
    courseProps;
  const {
    _id: courseId,
    has_draft_copy,
    is_draft_copy,
    slug,
    course_relationship
  } = course;
  const canEditVideoDoc =
    !isCourseStatus('isSubmitted', course) &&
    !isCourseStatus('isApprovedInitial', course) &&
    !isCourseStatus('isPublishing', course) &&
    !isCourseStatus('isApprovedForRepublish', course) &&
    !isCourseStatus('isRepublishing', course);

  const {
    _id: id,
    title,
    description,
    thumbnail,
    status,
    progress,
    exam,

    file_count = 0,
    path,
    file_changes,
    videosCheckpoints,
    videosWatched
  } = card;

  const isUploading = progress < 100;
  const isRestricted = !(
    isAdminPage ||
    course.course_relationship === COURSE_RELATIONSHIP.CREATOR ||
    (course.course_relationship === COURSE_RELATIONSHIP.PURCHASED &&
      course.purchased.active)
  );

  // Delete video mutation
  const { isLoading: isLoadingDelete, mutate: handleDelete } = useMutation({
    ...deleteVideoMutation(id, courseId, `?&changes=${changes}`),
    onSuccess: () => {
      setPopup(<GenericPopup msg="You have successfully deleted a video." />);
      queryClient.invalidateQueries({
        queryKey: ['course-resources-data', { id: courseId }]
      });
      navigate(
        `/courses/${slug}?&changes=${
          course.status === COURSE_STATUS.DRAFT
            ? COURSE_CHANGES.NO_CHANGES
            : COURSE_CHANGES.ONLY_CHANGES
        }`
      );
    },
    onError: (err: Error) =>
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
          isClosable={false}
        />
      )
  });

  const renderBadge = () => {
    // When admin views public course page, don't show badge
    if (userData.isAdmin && !isAdminPage) return;
    if (
      (course_relationship === COURSE_RELATIONSHIP.PURCHASED ||
        course_relationship === COURSE_RELATIONSHIP.NONE) &&
      !!videosWatched
    ) {
      if (!!videosWatched[id])
        return <Badge text="WATCHED" badgeType="success" size="sm" />;
      if (!!videosCheckpoints[id] && !videosWatched[id])
        return <Badge text="STARTED" badgeType="warning" size="sm" />;
      return <Badge text="NOT WATCHED" badgeType="disabled" size="sm" />;
    }
    if (
      userData.isAdmin ||
      course.course_relationship === COURSE_RELATIONSHIP.CREATOR
    ) {
      if (!isCourseActivated && !userData?.isAdmin)
        return <Badge text="LOCKED" badgeType="disabled" size="sm" />;
      if (status === VIDEO_STATUS.INITIAL)
        return <Badge text="READY" badgeType="success" size="sm" />;
      if (status === VIDEO_STATUS.NO_VIDEO_FILE)
        return <Badge text="ADD INFO" badgeType="warning" size="sm" />;
      if (status === VIDEO_STATUS.READY)
        return <Badge text="PROCESSING" badgeType="info" size="sm" />;
      return <Badge text="READY" badgeType="success" size="sm" />;
    }
  };

  const isExamFinished = useCallback(
    () => {
      return Object.keys(course.purchased.tests_completed).some(
        (key) => key === id
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [course, videoId]
  );

  const showConfirmDeletePopup = () => {
    setPopup(
      <GenericPopup
        type="info"
        title="Delete Video?"
        msg="Are you sure that you want to delete this video?"
        buttonName="Confirm"
        buttonAction={() => handleDelete({ videoId: id, courseId })}
      />
    );
  };

  const clickCardHandler = () => {
    if (!hasVideo) return;
    if (
      isAdminPage ||
      course.course_relationship === COURSE_RELATIONSHIP.CREATOR
    )
      return setPopup(
        <PlayVideoPopup
          courseId={course._id}
          videoId={id}
          isAdmin={isAdminPage}
        />
      );
    if (!isRestricted)
      navigate(`/courses/${slug}/${id}/videos?&changes=${changes}`);
  };

  // const showConfirmCancelPopup = () => {
  //   setPopup(
  //     <GenericPopup
  //       type="info"
  //       title="Cancel upload?"
  //       msg="Are you sure that you want to cancel uploading this video?"
  //       buttonName="Confirm"
  //       // TODO - still not working correctly, please ping Niki
  //       buttonAction={() => stream.cancel()}
  //     />
  //   );
  // };

  const examLength = exam?.length;
  const hasVideo = !!path;

  return (
    <div
      className={`${classes['card']} 
      ${id === videoId ? classes['card--active'] : ''}
      ${!isRestricted && hasVideo ? classes['u-cursor--pointer'] : ''}
      `}
      onClick={clickCardHandler}
    >
      {canDrag && (
        <div className={classes['card__dnd']}>
          <DragAndDropIcon />
        </div>
      )}
      <div
        className={`${classes['card__img-wrapper']} ${
          !thumbnail || isUploading ? classes['card__img-wrapper--empty'] : ''
        }`}
      >
        {!isUploading && (
          <div className={classes['badge-wrapper']}>{renderBadge()}</div>
        )}
        {thumbnail && !isUploading ? (
          <img
            src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail}
            alt={`Video thumbnail${title ? `: ${title}` : ''}`}
            className={
              !isCourseActivated && !userData?.isAdmin
                ? classes['disabled']
                : ''
            }
          />
        ) : isUploading ? (
          <Loader size="sm" />
        ) : !!path ? (
          <Play />
        ) : (
          <div className={classes['empty-state']}>
            <Tooltip
              id="no-video"
              text="You can add a video file to this video document, or delete it."
            >
              <Info />
            </Tooltip>
            No Video Attached
          </div>
        )}
        {path && (isCourseActivated || userData?.isAdmin) && !isUploading && (
          <span
            className={classes['card__img__action-overlay']}
            onClick={clickCardHandler}
          >
            <Play />
          </span>
        )}
        {!isCourseActivated && (
          <span
            className={`${classes['card__img__action-overlay']} ${classes['card__img__action-overlay--enabled']}`}
          />
        )}
      </div>
      <div className={classes['card__content']}>
        <div className={classes['card__content--top']}>
          <ChildWithIndicator
            indicatorType={
              course.status === COURSE_STATUS.DRAFT
                ? VIDEO_EDIT_STATUS.NEW
                : !!course?.diffs?.videos_diff &&
                  !!course?.diffs?.videos_diff[id] &&
                  course?.diffs?.videos_diff[id]?.change
            }
            showIndicator={
              isAdminPage &&
              !!course?.diffs?.videos_diff &&
              !!course?.diffs?.videos_diff[id]
            }
            onClick={() =>
              setMenu(<VideoChangesMenu course={course} video={card} />)
            }
          >
            <div className={classes['card__title']}>
              {status === VIDEO_STATUS.NO_VIDEO_FILE
                ? 'Please add a video.'
                : title}
            </div>
          </ChildWithIndicator>
          {!isUploading && (
            <>
              {!!description && !isLgMobile && (
                <div className={classes['card__text--primary']}>
                  <TextWithCharLimit
                    text={description}
                    limit={300}
                    hideBtn={
                      !userData?.isAdmin &&
                      course_relationship !== COURSE_RELATIONSHIP.CREATOR &&
                      !course?.purchased?.active
                    }
                  />
                </div>
              )}
              <div className={classes['card__text--secondary']}>
                {!!examLength && (
                  <span>{`${examLength} Question${
                    examLength > 1 ? 's' : ''
                  }`}</span>
                )}
                {!isLgMobile && !!examLength && file_count > 0 && (
                  <Divider dir="vertical" />
                )}
                {file_count > 0 && (
                  <span>{`${file_count} Attached Document${
                    file_count > 1 ? 's' : ''
                  }`}</span>
                )}
                {!!file_changes && !!Object.keys(file_changes)?.length && (
                  <>
                    {!isLgMobile && <Divider dir="vertical" />}
                    <span className={classes['u-text--warning']}>
                      {Object.keys(file_changes)?.length} Document
                      {Object.keys(file_changes)?.length > 1 ? 's' : ''} Updated
                    </span>
                  </>
                )}
                {isVideoPage &&
                  course.course_relationship ===
                    COURSE_RELATIONSHIP.PURCHASED &&
                  !!exam?.length && (
                    <>
                      {<Divider dir="vertical" />}
                      <Button
                        variant="link-contrast"
                        withPadding={false}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `/courses/${course.slug}/${id}/test-questions?changes=${changes}`,
                            { state: { openTest: true } }
                          );
                        }}
                      >
                        {isExamFinished() ? 'View Results' : 'Take Test'}
                      </Button>
                    </>
                  )}
              </div>
            </>
          )}
        </div>
        {isUploading && (
          <div className={classes['card__content--progress']}>
            <Progress progress={progress} />
          </div>
        )}
        {!isPublic && !isVideoPage && (
          <div className={classes['card__content--bottom']}>
            {!isUploading && (
              <Button
                size="sm"
                icon={hasVideo ? Change : Upload}
                iconPosition="left"
                onClick={(e) => {
                  e.stopPropagation();
                  handleVideoChange(id);
                }}
                variant="link-contrast"
                isDisabled={!canEditVideoDoc}
              >
                {hasVideo ? 'Change Video' : 'Add Video'}
              </Button>
            )}
            <Button
              size="sm"
              icon={Edit}
              iconPosition="left"
              onClick={(e) => {
                e.stopPropagation();
                // If the video is part of a draft-of-published course, navigate to the draft-of-published course
                // on opening the edit form in order to populate it with the draft-of-published course data
                if (has_draft_copy && !is_draft_copy) {
                  setToast({
                    type: 'warning',
                    title: 'Redirecting...',
                    msg: 'Navigating to draft',
                    autoClose: true
                  });
                  navigate(
                    `/courses/${slug}?&changes=${
                      course.status === COURSE_STATUS.DRAFT
                        ? COURSE_CHANGES.NO_CHANGES
                        : COURSE_CHANGES.ONLY_CHANGES
                    }`
                  );
                }
                setPopup(
                  <EditVideoDetailsPopup videoId={id} course={course} />
                );
              }}
              variant="link-contrast"
              isDisabled={!canEditVideoDoc}
            >
              Edit Details
            </Button>
            {!isUploading && (
              <Button
                size="sm"
                icon={Trash}
                iconPosition="left"
                onClick={(e) => {
                  e.stopPropagation();
                  showConfirmDeletePopup();
                }}
                variant="link-contrast"
                isLoading={isLoadingDelete}
                isDisabled={!canEditVideoDoc}
              >
                Delete Video
              </Button>
            )}
            {/* {isUploading && (
              <Button
                size="sm"
                icon={Cross}
                iconPosition="left"
                onClick={showConfirmCancelPopup}
                variant="link-error"
                //isLoading={isLoadingDelete}
              >
                Cancel Upload
              </Button>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCard;
