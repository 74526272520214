import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { InputWrapper } from 'components/input/InputWrapper';
import ReactSelectWrapper from 'components/select/ReactSelectWrapper';
import { IFormik } from './CourseForm';
import Divider from 'components/divider/Divider';
import Badge from 'components/badge/Badge';
import Button from 'components/buttons/Button';
import { InputChoice } from 'components/input/InputChoice';
import { courseCategoriesDataQuery } from 'query/course-module/queries';
import { ICourseCategoryData, ICourseCategory } from 'query/course-module/dto';
import { DIFFICULTY_LEVEL } from 'utils/constants';
import classes from './Form.module.scss';

interface IStep1
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldError'
    | 'handleBlur'
  > {}

export const difficultyOptions = [
  { value: DIFFICULTY_LEVEL.BEGGINER, label: 'Begginer' },
  { value: DIFFICULTY_LEVEL.INTERMEDIATE, label: 'Intermediate' },
  { value: DIFFICULTY_LEVEL.ADVANCED, label: 'Advanced' },
  { value: DIFFICULTY_LEVEL.EXPERT, label: 'Expert' },
  { value: DIFFICULTY_LEVEL.ALL_LEVELS, label: 'All levels' }
];

export const Step1 = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
  handleBlur
}: IStep1) => {
  const [skill, setSkill] = useState('');

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    ICourseCategoryData
  >(courseCategoriesDataQuery());

  // const {
  //   isLoading: subcategoryIsLoading,
  //   error: subcategoryError,
  //   data: subcategoryData
  // } = useQuery<boolean, Error, ICourseCategory[]>({
  //   ...courseSubcategoriesDataQuery(values.category?.value as string),
  //   enabled: !!values.category?.value
  // });

  useEffect(() => {
    if (!!error) setFieldError('category', error.message);
    // if (!!subcategoryError)
    //   setFieldError('subcategory', subcategoryError.message);
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildOptions = (options: ICourseCategory[]) => {
    return options.map((category) => ({
      label: category.name,
      value: category._id
    }));
  };

  return (
    <>
      <InputWrapper
        name="name"
        type="text"
        variant="outline"
        onChange={(e) => setFieldValue('name', e)}
        onBlur={(e) => handleBlur(e)}
        value={values.name}
        title="Course Name"
        placeholder="Enter Course Name"
        isRequired
        error={errors.name && touched.name ? errors.name : ''}
        limit={60}
        subtitle={`${values.name?.length} of 60 characters used`}
      />
      <ReactSelectWrapper
        name="category"
        title="Course Category"
        placeholder="Select Category"
        isRequired
        hasTooltip
        tooltipText="Selecting a course category and subcategory will make it easier for you course to be found."
        options={data ? buildOptions(data.result) : []}
        isLoading={isLoading}
        isSearchable
        onChange={(e) => {
          setFieldValue('category', e);
          setFieldValue('subcategory', null);
        }}
        onBlur={(e) => handleBlur(e)}
        error={
          errors.category && touched.category ? (errors.category as string) : ''
        }
        value={values.category}
        isClearable
      />
      {/* <ReactSelectWrapper
        name="subcategory"
        title="Course Subcategory"
        placeholder="Select Subcategory"
        hasTooltip
        tooltipText="Selecting a course category and subcategory will make it easier for you course to be found."
        options={
          !!subcategoryData && values.category
            ? buildOptions(subcategoryData)
            : []
        }
        isLoading={!!values.category && subcategoryIsLoading}
        isSearchable
        isDisabled={!values.category}
        onChange={(e) => setFieldValue('subcategory', e)}
        onBlur={(e) => handleBlur(e)}
        error={
          errors.subcategory && touched.subcategory
            ? (errors.subcategory as string)
            : ''
        }
        value={values.subcategory}
        isClearable
      /> */}
      <InputWrapper
        name="short_description"
        type="textarea"
        variant="outline"
        onChange={(e) => setFieldValue('short_description', e)}
        onBlur={(e) => handleBlur(e)}
        value={values.short_description}
        title="Course Short Description"
        subtitle={`${values.short_description?.length} of 200 characters used`}
        placeholder="Enter Course Short Description"
        hasTooltip
        tooltipText="A short description of your Course. This description will be visible on hover state of your Course card."
        error={
          errors.short_description && touched.short_description
            ? errors.short_description
            : ''
        }
      />
      <InputWrapper
        name="description"
        type="textarea"
        variant="outline"
        onChange={(e) => setFieldValue('description', e)}
        onBlur={(e) => handleBlur(e)}
        value={values.description}
        title="Course Description"
        placeholder="Enter Course Description"
        hasTooltip
        tooltipText="A longer description of your Course. This description will be visible on your Course detailed page."
        error={
          errors.description && touched.description ? errors.description : ''
        }
        subtitle={`${values.description?.length} of 500 characters used`}
      />
      <Divider />
      <h4 className={classes['u-light']}>Course Details</h4>
      <InputChoice
        name="expiration"
        type="radio"
        onChange={(e) => setFieldValue('difficulty_level', e)}
        title="Course Time Limit"
        subtitle="After the limit expires, no one will be able to buy this course."
        isRequired
        options={difficultyOptions}
        selectedOptions={values.difficulty_level}
      />
      <InputWrapper
        name="requirements"
        type="textarea"
        variant="outline"
        onChange={(e) => setFieldValue('requirements', e)}
        onBlur={(e) => handleBlur(e)}
        value={values.requirements}
        title="Course Requirements"
        subtitle={`${values.requirements?.length} of 500 characters used`}
        placeholder="Enter Course Requirements"
        isRequired
        error={
          errors.requirements && touched.requirements ? errors.requirements : ''
        }
      />
      <InputWrapper
        name="time_to_complete"
        type="number"
        variant="outline"
        onChange={(e) => setFieldValue('time_to_complete', e)}
        onBlur={(e) => handleBlur(e)}
        title="Time to Complete"
        subtitle="Set estimated number of weeks needed to complete the course"
        placeholder="Enter number of weeks"
        value={values.time_to_complete}
        error={
          errors.time_to_complete && touched.time_to_complete
            ? errors.time_to_complete
            : ''
        }
        isRequired
      />
      <InputWrapper
        name="agenda"
        type="textarea"
        variant="outline"
        onChange={(e) => setFieldValue('agenda', e)}
        onBlur={(e) => handleBlur(e)}
        value={values.agenda}
        title="Course Agenda"
        subtitle="Set each topic on new line"
        placeholder="Enter Course Agenda"
        isRequired
        error={errors.agenda && touched.agenda ? errors.agenda : ''}
      />
      <div className={classes['input-wrapper']}>
        <InputWrapper
          type="text"
          value={skill}
          name="skills"
          title="Skills"
          variant="outline"
          placeholder="Enter skill"
          onChange={(e) => setSkill(e)}
          onBlur={(e) => handleBlur(e)}
        />
        <Button
          size="sm"
          variant="neutral"
          onClick={() => {
            setSkill('');
            if (skill) {
              if (!!values?.skills) {
                setFieldValue('skills', [...values?.skills, skill]);
              } else {
                setFieldValue('skills', [skill as any]);
              }
            }
          }}
        >
          Add
        </Button>
      </div>
      {!!values?.skills?.length && (
        <div className={classes['badge-wrapper']}>
          {values?.skills?.map((skill: string, index: number) => (
            <Badge
              key={skill}
              text={skill}
              variant="outline"
              badgeType="info"
              isClosable
              onClick={() => {
                const updated = [...values.skills];
                updated.splice(index, 1);

                setFieldValue('skills', updated);
              }}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default IStep1;
