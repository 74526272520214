import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import usdc from 'assets/images/usdc.svg';
import { InputWrapper } from 'components/input/InputWrapper';
import ReactSelectWrapper from 'components/select/ReactSelectWrapper';
import { InputGroup } from 'components/input/InputGroup';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { IFormik } from './CourseBundleForm';
import CourseRowCard from './CourseRowCard';
import Divider from 'components/divider/Divider';
import { InputChoice } from 'components/input/InputChoice';
import { appContext } from 'context/appContext';
import { COURSE_EXPIRY } from 'utils/constants';
import { getPriceInUsdc } from 'utils/helpers';
import { formatPrice } from 'utils/format';
import { dateFormatUrl } from 'utils/static';
import classes from './Form.module.scss';

interface IStep4
  extends Pick<
    IFormik,
    'values' | 'errors' | 'touched' | 'setFieldValue' | 'handleBlur'
  > {}

export const currencyOptions = [{ label: 'USDC', value: 'usdc', symbol: usdc }];

export const expirationOptionsData = [
  {
    value: '',
    label: `Courses expiration date`
  },
  {
    value: '1',
    label: 'Custom'
  }
];

export const Step4 = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleBlur
}: IStep4) => {
  const [selectedCustom, setSelectedCustom] = useState(false);
  const [earliestExpiration, setEarliestExpiration] = useState(0);
  const [expirationOptions, setExpirationOptions] = useState(
    expirationOptionsData
  );
  const [dateRange, setDateRange] = useState<any[]>(['', '']);
  const [startDateRange, endDateRange] = dateRange;

  const prices = values.courses.map((course) => +course.price);
  const bundlePrice = prices.reduce((acc, value) => acc + value, 0);
  const bundleCurrency = values.currency ? values.currency.label : '';
  const {
    platformSettings: { platform_fee }
  } = useContext(appContext);
  const platformEarning = (
    ((bundlePrice - (Number(values.discount) / 100) * bundlePrice) *
      platform_fee) /
    100
  ).toFixed(2);
  const creatorEarning = (
    ((bundlePrice - (Number(values.discount) / 100) * bundlePrice) *
      (100 - platform_fee)) /
    100
  ).toFixed(2);

  // Set currency in form values
  useEffect(() => {
    setFieldValue('currency', currencyOptions[0] as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFieldValue('price', bundlePrice.toString());
  }, []);

  useEffect(() => {
    if (values.courses.length > 0) {
      values.courses.forEach((course) => {
        const expirationFormatted = new Date(
          Date.parse(course.date_created)
        ).setFullYear(
          new Date(Date.parse(course.date_created)).getFullYear() + 1
        );
        if (course.expiration !== COURSE_EXPIRY.INDEFINITELY) {
          if (
            earliestExpiration === 0 ||
            expirationFormatted < earliestExpiration
          )
            setEarliestExpiration(expirationFormatted);
        }
      });

      const updated = [...expirationOptions];
      updated[0] = {
        value: earliestExpiration.toString(),
        label: `Courses expiration date (${moment(earliestExpiration).format(
          dateFormatUrl
        )})`
      };
      if (values.expiration && values.expiration[0]?.label === 'Custom') {
        updated[1] = {
          value: values.expiration[0].value.toString(),
          label: 'Custom'
        };
        setDateRange([Date.now(), +values.expiration[0].value]);
      }
      setExpirationOptions(updated);

      if (!values?.expiration?.length && earliestExpiration > 0) {
        setFieldValue('expiration', [updated[0]]);
      }
    }
  }, [values.courses, earliestExpiration, values.expiration]);

  return (
    <div className={classes['bundle-form-step']}>
      <div>
        <h5 className={`${classes['u-body3']} ${classes['u-semiBold']}`}>
          Courses
        </h5>
        {values.courses.map((course, index) => (
          <div className={classes['row-card']}>
            {index > 0 && <PlusCircle />}
            <CourseRowCard course={course} />
          </div>
        ))}
      </div>
      <InputChoice
        name="expiration"
        type="radio"
        onChange={(e) => setFieldValue('expiration', e)}
        title="Course Time Limit"
        subtitle="After the limit expires, no one will be able to buy this course."
        isRequired
        options={expirationOptions}
        selectedOptions={values.expiration || expirationOptions}
      />
      {(values?.expiration?.[0]?.label === 'Custom' || selectedCustom) && (
        <InputWrapper
          type="datepicker-range"
          name="date_filter"
          title="Date of application"
          onChange={(e) => {
            setSelectedCustom(true);

            if (e)
              setFieldValue('expiration', [
                {
                  value: e[1].toString(),
                  label: 'Custom'
                }
              ]);
            const updated = [...expirationOptions];
            updated[1] = {
              value: e[1].toString(),
              label: 'Custom'
            };
            setExpirationOptions(updated);
            setDateRange(e);
          }}
          maxDate={new Date(earliestExpiration)}
          startDateRange={Date.now()}
          endDateRange={endDateRange}
          clearFilter={() => {
            setFieldValue('expiration', earliestExpiration.toString());
            setDateRange(['', '']);
          }}
        />
      )}
      <InputGroup
        name="price-group"
        title="Bundle Price"
        subtitle="Select a token and enter the price amount of the bundle."
        isRequired
        type="1-2"
      >
        <ReactSelectWrapper
          name="currency"
          placeholder="Select"
          isRequired
          options={currencyOptions}
          isCurrency
          onChange={(e) => setFieldValue('currency', e)}
          onBlur={(e) => handleBlur(e)}
          value={currencyOptions[0]}
          error={
            errors.currency && touched.currency
              ? (errors.currency as string)
              : ''
          }
          noChevron
          noDropdown
        />
        <InputWrapper
          name="price"
          type="number"
          variant="outline"
          onChange={(e) => setFieldValue('price', getPriceInUsdc(e))}
          onBlur={(e) => handleBlur(e)}
          isDisabled
          isRequired
          value={bundlePrice}
          error={errors.price && touched.price ? errors.price : ''}
        />
      </InputGroup>
      <InputGroup
        name="price-group"
        title="Discount"
        subtitle="Set percentage"
        type="1-2"
      >
        <InputWrapper
          name="discount"
          type="number"
          variant="outline"
          onChange={(e) => setFieldValue('discount', getPriceInUsdc(e))}
          placeholder="0%"
          onBlur={(e) => handleBlur(e)}
          value={values.discount}
          error={errors.discount && touched.discount ? errors.discount : ''}
        />
      </InputGroup>
      <div className={classes['price-box']}>
        <div className={classes['price-box__row']}>
          <div className={classes['price-box__title']}>Bundle Price</div>
          <div className={classes['price-box__value']}>
            {`${formatPrice(bundlePrice)} ${bundleCurrency}` || '-'}
          </div>
        </div>
        <div className={classes['price-box__row']}>
          <div className={classes['price-box__title']}>Discount</div>
          <div className={classes['price-box__value']}>
            {`${!!values.discount ? values.discount + ' %' : '-'}`}
          </div>
        </div>
        <Divider dir="horizontal" />
        <div className={classes['price-box__row']}>
          <div className={classes['price-box__title']}>Platform Fee</div>
          <div
            className={classes['price-box__value']}
          >{`${platform_fee}%`}</div>
        </div>
        <div className={classes['price-box__row']}>
          <div className={classes['price-box__title']}>Platform Earning</div>
          <div className={classes['price-box__value']}>
            {`${formatPrice(+platformEarning)} ${bundleCurrency}` || '-'}
          </div>
        </div>
        <div className={classes['price-box__row']}>
          <div className={classes['price-box__title']}>Creator Earning</div>
          <div className={classes['price-box__value']}>
            {`${formatPrice(+creatorEarning)} ${bundleCurrency}` || '-'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;
