import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import Button from 'components/buttons/Button';
import { IFormik } from './OnboardingForm';
import {
  IOnboardingDtoExtended,
  IUserInfo,
  MARKETING_INFO_TYPE
} from 'query/acc-module/dto';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import classes from './OnboardingForm.module.scss';

interface IStep1
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldTouched'
    | 'setFieldError'
    | 'handleBlur'
  > {
  steps: number;
  currentStep: number;
  setStep: (step: number) => void;
  defaultFormData: IOnboardingDtoExtended;
  clearPopup: () => void;
}

export const Step1 = ({
  steps,
  setStep,
  currentStep,
  values,
  errors,
  setFieldValue,
  defaultFormData
}: IStep1) => {
  return (
    <>
      <div className={`${classes['header']} ${classes['first-step']}`}>
        <h4 className={classes['u-semiBold']}>What inspires you?</h4>
        <h5 className={`${classes['u-body2']} ${classes['u-text--content']}`}>
          We will use this in order to recommend the most suitable courses for
          you.
        </h5>
      </div>
      <div className={classes['user-info-fields']}>
        <h5 className={classes['u-body2']}>Please select your interests</h5>
        <div className={classes['user-info-fields__wrapper']}>
          {defaultFormData?.userInfo?.map(
            (userInfo: IUserInfo, index: number) =>
              userInfo.type === MARKETING_INFO_TYPE.INTEREST && (
                <div
                  key={index}
                  className={`${classes['user-info-fields__wrapper__button']} ${
                    classes[
                      values.interests?.includes(userInfo._id) ? 'selected' : ''
                    ]
                  }`}
                  onClick={() => {
                    if (values.interests) {
                      if (values.interests.includes(userInfo._id)) {
                        const updated = [...values.interests];
                        updated.splice(
                          values.interests.indexOf(userInfo._id),
                          1
                        );

                        setFieldValue('interests', updated);
                      } else {
                        const updated = [...values.interests];
                        updated.push(userInfo._id);

                        setFieldValue('interests', updated);
                      }
                    }
                  }}
                >
                  {userInfo?.icon && (
                    <img
                      src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + userInfo.icon}
                      alt={`user-${userInfo.value}`}
                    />
                  )}
                  {userInfo.value}
                </div>
              )
          )}
        </div>
      </div>
      <div
        className={`${classes['btns-container']} ${classes['btns-container__first-step']}`}
      >
        <div className={classes['u-text--content']}>{`Step ${
          currentStep + 1
        } of ${steps}`}</div>
        <div className={classes['btns-wrapper']}>
          <Button
            icon={Arrow}
            type="button"
            minWidth="lg"
            iconRotate={90}
            onClick={() => setStep(currentStep + 1)}
            isDisabled={!!errors.interests}
          >
            Next Step
          </Button>
        </div>
      </div>
    </>
  );
};

export default Step1;
