import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { appContext } from 'context/appContext';
import { windowContext } from 'context/windowsContext';
import Error from 'components/error/Error';
import NoResults from 'components/noResults/NoResults';
import Pagination from 'components/pagination/Pagination';
import Actions from '../profile/components/Actions';
import { IOption } from 'components/input/InputWrapper';
import CardSkeleton from 'components/skeleton/CardSkeleton';
import BundleCard from 'components/card/BundleCard';
import SEO from 'components/SEO/SEO';
import {
  ICourseCategoryData,
  IFilterData,
  IFilterOptions,
  ICourseBundleData
} from 'query/course-module/dto';
import {
  courseCategoriesDataQuery,
  userBundlesQuery
} from 'query/course-module/queries';
import { isSafari } from 'utils/helpers';
import classes from './Bundles.module.scss';

const categoriesFilter: IFilterData = {
  filterName: 'Category',
  filterOptions: []
};

const Bundles = () => {
  const { search: urlParams } = useLocation();
  const {
    page: pageNumber,
    search,
    sort,
    price,
    category
  } = queryString.parse(urlParams);
  const pageSize: number = 8;

  const { showFilter, updateFilter } = useContext(appContext);
  const { windowSize } = useContext(windowContext);
  const { isMdMobile } = windowSize;
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  const [sortValue, setSortValue] = useState<string>((sort as string) || '');
  const [categoriesValue, setCategoriesValue] = useState<IOption[]>(
    categoriesFilter.filterOptions.filter((option: IFilterOptions) => {
      return category?.includes(option.value as string);
    })
  );

  const queryParams = `?&limit=${pageSize}&page=${page}${
    searchValue.length > 1 ? `&search[name]=${searchValue}` : ''
  }${sortValue.length > 1 ? `&sort=${sortValue}` : ''}${
    !!price ? `&price=${price}` : ''
  }${!!category ? `&category=${category}` : ''}`;

  useQuery<boolean, Error, ICourseCategoryData>({
    ...courseCategoriesDataQuery(),
    onSuccess: (fetchedCategories) => {
      categoriesFilter.filterOptions = fetchedCategories.result
        .filter((category) => category.counter > 0)
        .map((category) => ({
          label: category.name,
          value: category.slug
        }));
      setCategoriesValue(
        categoriesFilter.filterOptions.filter(
          (option: IFilterOptions) => category?.includes(option.value as string)
        )
      );
    }
  });

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    ICourseBundleData
  >({
    ...userBundlesQuery(queryParams),
    onSuccess: (data) => {
      if (!price) {
        if (!!data?.maxBundle && !!data.minBundle) {
          localStorage.setItem('minBundlePrice', data.minBundle.toString());
          localStorage.setItem('maxBundlePrice', data.maxBundle.toString());
        } else {
          localStorage.removeItem('minBundlePrice');
          localStorage.removeItem('maxBundlePrice');
        }
        const event = new CustomEvent('coursePricesUpdated');
        document.dispatchEvent(event);
      }
    }
  });
  const { result: bundlesData, total_results } = data || {};
  const hasData = !!bundlesData && !!bundlesData.length;

  const filterHandler = (value: IOption[], name: string) => {
    setPage(1);
    if (!name) {
      setCategoriesValue([]);
    }
    if (name === 'Category') {
      setCategoriesValue(value);
    }
  };

  return (
    <>
      <div
        className={`
      ${classes['browse-bundles']} 
      ${showFilter ? classes['browse-bundles__grid'] : ''}`}
      >
        <SEO title={'Explore Bundles'} />
        {!!showFilter && (
          <Actions
            hasSort
            hasFilter
            isInverted
            hasPriceFilter
            sortValue={sortValue}
            passSortValueUp={setSortValue}
            filters={[{ ...categoriesFilter, filterValue: categoriesValue }]}
            passFilterValueUp={filterHandler}
            onFilterClick={updateFilter}
            showFilterMenu={showFilter}
            setPage={setPage}
          />
        )}

        {(!isMdMobile || (isMdMobile && !showFilter)) && (
          <div
            className={`${classes['browse-bundles__table-wrapper']}  
          ${isSafari ? classes['safari-support-overflow'] : ''}`}
          >
            <Actions
              isInverted
              hasSearch
              passSearchValueUp={setSearchValue}
              searchValue={searchValue}
              searchPlaceholder="Search Bundles"
              hasSort={!showFilter}
              passSortValueUp={setSortValue}
              sortValue={sortValue}
              hasFilter={!showFilter}
              onFilterClick={updateFilter}
              setPage={setPage}
              isDebounced
              isBundle
            />
            {isLoading && (
              <div className={`${classes['cards']} ${classes['cards--two']}`}>
                <CardSkeleton amount={pageSize + 1} variant="course" />
              </div>
            )}
            {!!error && <Error error={error} />}
            {!isLoading && !error && hasData && (
              <>
                <h4 className={classes['u-semiBold']}>All Bundles</h4>
                <div className={`${classes['cards']} ${classes['cards--two']}`}>
                  {bundlesData.map((bundle) => (
                    <BundleCard
                      key={bundle._id}
                      bundle={bundle}
                      isFiltered={!!showFilter}
                    />
                  ))}
                </div>
                <Pagination
                  currentPage={page}
                  totalCount={total_results as number}
                  siblingCount={1}
                  pageSize={pageSize}
                  onPageChange={(page) => setPage(page)}
                />
              </>
            )}
            {!isLoading && !error && !hasData && <NoResults />}
          </div>
        )}
      </div>
    </>
  );
};

export default Bundles;
