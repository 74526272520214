import Tabs from 'components/tabs/Tabs';
import PurchasedBundles from './PurchasedBundles';
import CreatedBundles from './CreatedBundles';
import { IProfile } from '../../index';

interface IBundlesProps extends Pick<IProfile, 'subTab'> {}

const Bundles = ({ subTab }: IBundlesProps) => {
  const tabs = [
    {
      id: 'purchased',
      name: 'Purchased Bundles',
      route: 'user/bundles/purchased',
      component: () => <PurchasedBundles />
    },
    {
      id: 'created',
      name: 'Created Bundles',
      route: 'user/bundles/created',
      component: () => <CreatedBundles />
    }
  ];
  return (
    <Tabs
      tabs={tabs}
      activeTabIndex={tabs.findIndex((t) => t.id === subTab)}
      size={'sm'}
      isVertical
    />
  );
};

export default Bundles;
