import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Student from 'assets/images/hero-image-1.png';
import Creator from 'assets/images/hero-image-2.png';
import { windowContext } from 'context/windowsContext';
import Button from 'components/buttons/Button';
import { gtmEventHandler } from 'utils/google-tag-manager';
import classes from './Carousel.module.scss';

interface ISlideProps {
  title: string;
  subtitle: string;
  buttonName: string;
  buttonAction: () => void;
  img: string;
}

const Slide = ({
  title,
  subtitle,
  buttonName,
  buttonAction,
  img
}: ISlideProps) => {
  const {
    windowSize: { isSmMobile }
  } = useContext(windowContext);

  return (
    <div className={classes['section__hero']}>
      <div className={classes['section__hero__info-col']}>
        {isSmMobile ? (
          <h1 className={`${classes['u-bold']} ${classes['u-h3']}`}>{title}</h1>
        ) : (
          <h1 className={`${classes['u-bold']} ${classes['u-h2']}`}>{title}</h1>
        )}
        <div className={classes['section__hero__info-col__subtitle']}>
          {subtitle}
        </div>
        <Button className="gtm-button" onClick={buttonAction} minWidth="lg">
          {buttonName}
        </Button>
      </div>
      <div className={classes['section__hero__img-col']}>
        <img className={classes['student-img']} src={img} alt="hero-student" />
      </div>
    </div>
  );
};

const Carousel = () => {
  const navigate = useNavigate();

  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<div class="' + className + '"></div>';
    }
  };

  return (
    <Swiper
      className={classes['swiper']}
      modules={[Autoplay, Pagination]}
      slidesPerView={1}
      loop={true}
      autoplay={{ delay: 5000 }}
      pagination={pagination}
    >
      <SwiperSlide>
        <Slide
          title="UNLOCK YOUR POTENTIAL  ANYWHERE, ANYTIME"
          subtitle="Explore accessible and engaging courses designed to help you gain skills, grow your career, and achieve your dreams—no matter where you are."
          buttonName="Start Learning Today"
          buttonAction={() => {
            gtmEventHandler('click_start_learning_today');
            navigate('/courses');
          }}
          img={Student}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Slide
          title="SHARE YOUR KNOWLEDGE, EARN YOUR WAY"
          subtitle="Reach a global audience with your expertise. Create, share, and monetize your courses with full control and a transparent, crypto-powered platform."
          buttonName="Start Creating Today"
          buttonAction={() => {
            gtmEventHandler('click_start_creating_today');
            navigate('/courses/create');
          }}
          img={Creator}
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default Carousel;
