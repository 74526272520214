import { useNavigate } from 'react-router-dom';
import { currencyObj } from 'routes/dashboard/courses/single';
import Badge from 'components/badge/Badge';
import { ICourseBundle } from 'query/course-module/dto';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import { formatNames } from 'utils/format';
import { getPriceInUsdc } from 'utils/helpers';
import classes from './BundleCard.module.scss';

interface IBundleCardProps {
  isFiltered: boolean;
  bundle: ICourseBundle;
}

const BundleCard = ({ bundle, isFiltered }: IBundleCardProps) => {
  const navigate = useNavigate();

  const { name, slug, price, creator, category, currency, thumbnail, courses } =
    bundle;

  return (
    <div
      className={`${classes['card']} ${
        classes[isFiltered ? 'card__filtered' : '']
      } ${classes['u-cursor--pointer']}`}
      onClick={() => navigate(`/bundles/${slug}`)}
    >
      <div
        className={`${classes['card__thumb']} `}
        style={{
          backgroundImage: `url(${
            REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail
          })`
        }}
      />
      <div className={classes['card__info']}>
        <div className={classes['card__info__details']}>
          <Badge size="sm" variant="outline" text={category.name} />
          <div
            className={`${classes['u-semiBold']} ${classes['u-body1']} ${classes['u-cursor--pointer']}`}
          >
            {name}
          </div>
          <div
            className={`${classes['u-semiBold']} ${classes['u-body2']} ${classes['link-contrast']}`}
          >
            {`${courses.length} Course Series`}
          </div>
          <div
            className={`${classes['u-text--content']} ${classes['u-cursor--pointer']}`}
          >
            {formatNames(creator.first_name + ' ' + creator.last_name)}
          </div>
        </div>
        <div className={classes['card__info__price']}>
          <img
            width={32}
            height={32}
            src={currencyObj[currency]?.symbol}
            alt={currency ? currencyObj[currency]?.name : 'USDC'}
          />
          <span>
            <span
              className={`${classes['card__price--number']} ${classes['u-semiBold']}`}
            >
              {getPriceInUsdc(+price)}
            </span>{' '}
            {currency ? currencyObj[currency]?.name : ''}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BundleCard;
