import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import NoCourses from 'assets/images/no-courses.svg';
import Card from 'components/card/Card';
import ErrorComponent from 'components/error/Error';
import EmptyState from 'components/emptyState/EmptyState';
import Pagination from 'components/pagination/Pagination';
import CardSkeleton from 'components/skeleton/CardSkeleton';
import Actions from '../Actions';
import { userBundlesQuery } from 'query/course-module/queries';
import { ICourseBundleData } from 'query/course-module/dto';
import { userContext } from 'context/userContext';
import classes from './CreatedBundles.module.scss';

const CreatedBundles = () => {
  const navigate = useNavigate();
  const { search: urlParams } = useLocation();
  const { userData } = useContext(userContext);
  const { page: pageNumber, search, sort } = queryString.parse(urlParams);
  const pageSize: number = 9;
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);

  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  const [sortValue, setSortValue] = useState<string>((sort as string) || '');

  const queryParams = `?&limit=${pageSize}&page=${page}&creator_id=${
    userData._id
  }${searchValue.length > 1 ? `&search[name]=${searchValue}` : ''}${
    sortValue.length > 1 ? `&sort=${sortValue}` : ''
  }`;

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    ICourseBundleData
  >(userBundlesQuery(queryParams));

  const { result, total_results } = data || {};

  const hasData = !!result && result?.length > 0;

  return (
    <div className={classes['wrapper']}>
      <h5>Created Bundles</h5>
      {(hasData ||
        (!hasData && searchValue) ||
        (!hasData && sortValue) ||
        page > 1 ||
        isLoading) && (
        <Actions
          searchPlaceholder="Search by Bundle Name"
          passSearchValueUp={setSearchValue}
          passSortValueUp={setSortValue}
          searchValue={searchValue}
          sortValue={sortValue}
          setPage={setPage}
          isDebounced
          isInverted
          hasSearch
          isBundle
          hasSort
        />
      )}
      {isLoading && (
        <div className={`${classes['cards']} ${classes['cards--three']}`}>
          <CardSkeleton amount={3} variant="course" />
        </div>
      )}
      {error && <ErrorComponent error={error} />}
      {hasData && !isLoading && (
        <>
          <div className={`${classes['cards']} ${classes['cards--three']}`}>
            {(result as any).map((bundle: any) => {
              return (
                <Card
                  key={bundle._id}
                  hasPrice
                  course={bundle}
                  onClick={() => navigate(`/bundles/${bundle.slug}`)}
                />
              );
            })}
          </div>
          <Pagination
            currentPage={page}
            totalCount={total_results as number}
            siblingCount={1}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
          />
        </>
      )}
      {!hasData && !isLoading && !error && (
        <EmptyState
          icon={NoCourses}
          title={'There are no Bundles at the moment.'}
          buttonName={'Create Bundle'}
          onClick={() => navigate('/bundle/create')}
        />
      )}
    </div>
  );
};

export default CreatedBundles;
