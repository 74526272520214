import axios from 'axios';
import { QueryClient } from '@tanstack/react-query';
import { fetchApi } from 'utils/requests';
import {
  ICourseDto,
  IEditVideoDto,
  IEditCourseDto,
  IEditCourseFormFields,
  IVideoDto,
  IEditVideoOrderDto,
  IPublishCourseDto,
  ISignatureDto,
  IBuyCourseDto,
  IBuyCourseResponseDto,
  ICourse,
  IPlatformSettingsDto,
  IRepublishSignatureDto,
  ICourseBundleDto
} from './dto';
import {
  IBlogDataFormFields,
  ICourseBundleFormFields,
  ICourseFormFields
} from 'utils/yupSchemas';
import { COURSE_REVIEW_STATUS } from 'utils/constants';
import { buildQueryKey } from './queries';
import { payloadData } from 'contracts/contracts';

// Create course
const createCourse = async (data: ICourseDto) =>
  await fetchApi('courses', '/course', {
    method: 'POST',
    auth: true,
    data: {
      ...data,
      category: data.category?.value || null,
      subcategory: data.subcategory?.value || null,
      currency: data.currency?.value || null,
      price: parseFloat(data.price),
      template_id: data.template_id || null,
      expiration: parseInt(data.expiration.toString())
    }
  });

// Create bundle
const createBundle = async (data: ICourseBundleDto) =>
  await fetchApi('courses', '/bundles', {
    method: 'POST',
    auth: true,
    data: {
      ...data,
      category: data.category?.value || null,
      currency: data.currency?.value || null,
      discount: +data.discount,
      thumbnail: {
        data: data.thumbnail
      },
      expiration: +(data?.expiration?.[0]?.value as any),
      courses: data.courses.map((course) => course._id),
      listSignature: {
        ...payloadData
      }
    }
  });

// Edit bundle
const editBundle = async (data: ICourseBundleDto) =>
  await fetchApi('courses', `/bundles/${data._id}`, {
    method: 'PUT',
    auth: true,
    data: {
      ...data,
      category: data.category?.value || null,
      currency: data.currency?.value || null,
      thumbnail: {
        data: data.thumbnail
      },
      discount: +data.discount,
      price: +data.price,
      expiration: +(data?.expiration?.[0]?.value as any),
      courses: data.courses.map((course) => course._id),
      listSignature: {
        ...payloadData
      }
    }
  });

export const editBundleSignatureMutation = (
  bundleId: string,
  data: IPublishCourseDto
) => ({
  mutationKey: ['edit-bundle-signature'],
  mutationFn: async (): Promise<IRepublishSignatureDto> => {
    return await fetchApi(
      'courses',
      `/bundles/${bundleId}/signature/update-item`,
      {
        method: 'POST',
        auth: true,
        data
      }
    );
  }
});

const createBlog = async (data: IBlogDataFormFields) =>
  await fetchApi('courses', '/blog', {
    method: 'POST',
    auth: true,
    data: {
      ...data,
      status: 1,
      thumbnail: {
        data: data.thumbnail
      },
      image: {
        data: data.image
      }
    }
  });

const editBlog = async (id: number, data: IBlogDataFormFields) => {
  const prepData: any = { ...data };

  if (prepData.thumbnail && !prepData.thumbnail.includes('data:image/'))
    delete prepData.thumbnail;
  if (prepData.image && !prepData.image.includes('data:image/'))
    delete prepData.image;

  if (prepData.thumbnail) {
    prepData.thumbnail = {
      data: data.thumbnail
    };
  }
  if (prepData.image) {
    prepData.image = {
      data: data.image
    };
  }

  return await fetchApi('courses', `/blog/${id}`, {
    method: 'PUT',
    auth: true,
    data: {
      ...prepData,
      status: 1
    }
  });
};

const deleteBlog = async (id: number) => {
  return await fetchApi('courses', `/blog/${id}`, {
    method: 'DELETE',
    auth: true
  });
};

export const createCourseMutation = () => ({
  mutationKey: ['create-course'],
  mutationFn: async (data: ICourseFormFields) => {
    const prepData: ICourseDto = {
      ...data,
      difficulty_level: +data.difficulty_level[0].value,
      expiration: +data.expiration[0].value,
      time_to_complete: +data.time_to_complete,
      cpe: !data.cpe.length,
      template_id: !!data.template_id.length
        ? (data.template_id[0].value as string)
        : '',
      thumbnail: {
        data: data.thumbnail,
        is_default: !!data.isThumbnailPlaceholder
      },
      video_preview: {
        data: data.video_preview,
        is_default: false
      }
    };
    if (
      !!prepData.thumbnail?.data &&
      !prepData.thumbnail.data.includes('data:image/')
    )
      delete prepData.thumbnail;
    if (
      prepData.activation_nft_image &&
      !prepData.activation_nft_image.includes('data:image/')
    )
      delete prepData.activation_nft_image;
    if (
      !!prepData.video_preview?.data &&
      !prepData.video_preview.data.includes('data:video/')
    )
      delete prepData.video_preview;
    return await createCourse(prepData);
  }
});

export const createBundleMutation = () => ({
  mutationKey: ['create-bundle'],
  mutationFn: async (data: ICourseBundleFormFields) => {
    return await createBundle(data);
  }
});

export const editBundleMutation = () => ({
  mutationKey: ['edit-bundle'],
  mutationFn: async (data: ICourseBundleFormFields) => {
    const prepData: any = { ...data };
    if (
      !!prepData.thumbnail &&
      !prepData.thumbnail.includes('data:image/') &&
      prepData.thumbnail !== ''
    )
      delete prepData.thumbnail;

    return await editBundle(prepData);
  }
});

export const createBlogMutation = () => ({
  mutationKey: ['create-blog'],
  mutationFn: async (data: IBlogDataFormFields) => await createBlog(data)
});

export const editBlogMutation = (id: number) => ({
  mutationKey: ['edit-blog'],
  mutationFn: async (data: IBlogDataFormFields) => await editBlog(id, data)
});

export const deleteBlogMutation = (id: number) => ({
  mutationKey: ['delete-blog', { id }],
  mutationFn: async ({ id }: { id: number }) => await deleteBlog(id)
});

const editCourse = async (data: ICourseDto, id: string) =>
  await fetchApi('courses', `/course/${id}`, {
    method: 'PUT',
    auth: true,
    data: {
      ...data,
      category: data.category?.value || null,
      subcategory: data.subcategory?.value || null,
      currency: data.currency?.value || null,
      price: parseFloat(data.price),
      template_id: data.template_id ? data.template_id : null,
      expiration: parseInt(data.expiration.toString())
    }
  });

export const editCourseMutation = (id: string) => ({
  mutationKey: ['edit-course', { id }],
  mutationFn: async ({ data, id }: IEditCourseFormFields) => {
    const prepData: IEditCourseDto = {
      id,
      data: {
        ...data,
        time_to_complete: +data.time_to_complete,
        expiration: data.expiration[0].value as number,
        difficulty_level: data.difficulty_level[0].value as number,
        cpe: !data.cpe.length,
        template_id: !!data.template_id.length
          ? (data.template_id[0]?.value as string)
          : '',
        thumbnail: {
          data: data.thumbnail,
          is_default: !!data.isThumbnailPlaceholder,
          thumbnail_is_generated: data.thumbnail_is_generated
        },
        video_preview: {
          data: data.video_preview,
          is_default: false,
          video_preview_is_generated: data.video_preview_is_generated
        }
      }
    };
    if (
      !!prepData.data.thumbnail &&
      !prepData.data.thumbnail.data.includes('data:image/') &&
      prepData.data.thumbnail.data !== ''
    )
      delete prepData.data.thumbnail;
    if (
      prepData.data.activation_nft_image &&
      !prepData.data.activation_nft_image.includes('data:image/')
    )
      delete prepData.data.activation_nft_image;
    if (
      !!prepData.data?.video_preview?.data &&
      !prepData.data.video_preview.data.includes('data:video/')
    )
      delete prepData.data.video_preview;
    return await editCourse(prepData.data, prepData.id);
  }
});

export const createDraftMutation = (id: string) => ({
  mutationKey: ['create-draft', { id }],
  mutationFn: async () => {
    return await fetchApi('courses', `/course/${id}/create-draft`, {
      method: 'POST',
      auth: true
    });
  }
});

export const submitForApprovalMutation = (
  id: string,
  setPopupLoading: (isLoading: boolean) => void
) => ({
  mutationKey: [`submit-for-approval-${id}`],
  mutationFn: async (
    action: 'submit' | 'withdraw'
  ): Promise<'submit' | 'withdraw'> => {
    setPopupLoading(true);
    await fetchApi('courses', `/course/${id}/status`, {
      method: 'POST',
      auth: true,
      data: {
        review_status:
          action === 'submit'
            ? COURSE_REVIEW_STATUS['IN_REVIEW']
            : COURSE_REVIEW_STATUS['INITIAL']
      }
    });
    return action;
  }
});

export const deleteDraftMutation = (id: string) => ({
  mutationKey: [`delete-draft-${id}`],
  mutationFn: async () => {
    return await fetchApi('courses', `/course/draft/${id}`, {
      method: 'DELETE',
      auth: true
    });
  }
});

export const deleteCourseMutation = (id: string) => ({
  mutationKey: [`delete-course-${id}`],
  mutationFn: async () => {
    return await fetchApi('courses', `/course/${id}`, {
      method: 'DELETE',
      auth: true
    });
  }
});

export const deleteBundleMutation = (id: string) => ({
  mutationKey: [`delete-bundle-${id}`],
  mutationFn: async () => {
    return await fetchApi('courses', `/bundles/${id}`, {
      method: 'DELETE',
      auth: true
    });
  }
});

export const approveCourseMutation = (
  id: string,
  setPopupLoading: (isLoading: boolean) => void
) => ({
  mutationKey: [`approve-course-${id}`],
  mutationFn: async (id: string) => {
    setPopupLoading(true);
    await fetchApi('courses', `/admin/course/${id}/approve`, {
      method: 'PUT',
      auth: true
    });
    return id;
  }
});

export const rejectCourseMutation = (
  id: string,
  setPopupLoading: (isLoading: boolean) => void
) => ({
  mutationKey: [`reject-course-${id}`],
  mutationFn: async (reject_reason: string) => {
    setPopupLoading(true);
    await fetchApi('courses', `/admin/course/${id}/reject`, {
      method: 'PUT',
      auth: true,
      data: {
        reject_reason
      }
    });
    return id;
  }
});

export const takedownCourseMutation = (data: {
  id: string;
  isTakedown: boolean;
  setPopupLoading: (isLoading: boolean) => void;
}) => ({
  mutationKey: [
    `${data.isTakedown ? 'takedown' : 'restore'}-course-${data.id}`
  ],
  mutationFn: async (reason: string) => {
    data.setPopupLoading(true);
    await fetchApi('courses', `/admin/course/${data.id}/takedown`, {
      method: 'PUT',
      auth: true,
      data: {
        reason,
        takedown: data.isTakedown
      }
    });
    return { id: data.id, isTakedown: data.isTakedown };
  }
});

export const publishCourseMutation = (id: string, data: IPublishCourseDto) => ({
  mutationKey: ['publish'],
  mutationFn: async (): Promise<ISignatureDto> => {
    return await fetchApi('courses', `/course/${id}/signature/list`, {
      method: 'POST',
      auth: true,
      data
    });
  }
});

export const republishCourseMutation = (
  id: string,
  data: IPublishCourseDto
) => ({
  mutationKey: ['republish'],
  mutationFn: async (): Promise<IRepublishSignatureDto> => {
    return await fetchApi('courses', `/course/${id}/signature/update-item`, {
      method: 'POST',
      auth: true,
      data
    });
  }
});

export const buyCourseMutation = (id: string, data: IBuyCourseDto) => ({
  mutationKey: ['publish'],
  mutationFn: async (): Promise<IBuyCourseResponseDto> => {
    return await fetchApi('courses', `/course/${id}/signature/buy`, {
      method: 'POST',
      auth: true,
      data
    });
  }
});

export const buyCourseBundleMutation = (id: string, data: IBuyCourseDto) => ({
  mutationKey: ['publish-bundle'],
  mutationFn: async (): Promise<IBuyCourseResponseDto> => {
    return await fetchApi('courses', `/bundles/${id}/signature/buy`, {
      method: 'POST',
      auth: true,
      data
    });
  }
});

export const highlightCourseMutation = () => ({
  mutationKey: [`highlight-course`],
  mutationFn: async (course: { id: string; is_highlighted: boolean }) => {
    return await fetchApi('courses', `/admin/course/${course.id}`, {
      method: 'PUT',
      auth: true,
      data: {
        is_highlighted: course.is_highlighted
      }
    });
  }
});

export const requestCertificateMutation = () => ({
  mutationKey: ['request-certificate'],
  mutationFn: async (courseId: string) => {
    return await fetchApi('courses', `/course/${courseId}/finalize`, {
      method: 'POST',
      auth: true
    });
  }
});

export const reorderCourseMutation = () => ({
  mutationKey: [`reorder-course`],
  mutationFn: async (data: ICourse[]) => {
    const indexes = data.reduce(
      (acc, cur, i) => ({ ...acc, [i + 1]: cur._id }),
      {}
    );
    return await fetchApi('courses', '/admin/course/highlighted/reorder', {
      method: 'PUT',
      auth: true,
      data: { indexes }
    });
  }
});

// Create video doc
const createVideoDoc = async (courseId: string) =>
  await fetchApi('courses', `/course/${courseId}/video`, {
    method: 'POST',
    auth: true
  });

export const createVideoDocMutation = () => ({
  mutationKey: ['create-video-doc'],
  mutationFn: async (courseId: string) => await createVideoDoc(courseId)
});

// Get video upload link
const getVideoUploadLink = async (courseId: string, videoId: string) =>
  await fetchApi(
    'courses',
    `/course/${courseId}/video/${videoId}/get-signed-link`,
    {
      method: 'POST',
      auth: true
    }
  );

export const getVideoUploadLinkMutation = () => ({
  mutationKey: ['get-video-upload-link'],
  mutationFn: async ({
    courseId,
    videoId
  }: {
    courseId: string;
    videoId: string;
  }) => await getVideoUploadLink(courseId, videoId)
});

// Post video
const postVideo = async (
  file: {
    stream: ReadableStream;
    size: number;
    name: string;
    type: 'video/mp4';
  },
  courseId: string,
  videoId: string
) =>
  await fetchApi('videos', `/course/${courseId}/video/${videoId}`, {
    method: 'POST',
    auth: true,
    isStream: true,
    fileStream: file
  });

export const postVideoMutation = (videoId: string, courseId: string) => ({
  mutationKey: ['post-video', { videoId, courseId }],
  mutationFn: async ({
    file,
    courseId,
    videoId
  }: {
    file: {
      stream: ReadableStream;
      size: number;
      name: string;
      type: 'video/mp4';
    };
    courseId: string;
    videoId: string;
  }) => await postVideo(file, courseId, videoId)
});

export const finalizeUploadMutation = (videoId: string, courseId: string) => ({
  mutationKey: ['finalize-upload', { videoId, courseId }],
  mutationFn: async ({
    courseId,
    videoId
  }: {
    courseId: string;
    videoId: string;
  }) =>
    await fetchApi(
      'courses',
      `/course/${courseId}/video/${videoId}/finalize-upload`,
      {
        method: 'PUT',
        auth: true
      }
    )
});

export const postFileChunks = async (
  url: string,
  start: number,
  totalSize: number,
  chunk: any
) => {
  try {
    const end = start + chunk.size - 1;

    const headers = {
      'Content-Length': chunk.length,
      'Content-Range': `bytes ${start}-${end}/${totalSize}`
    };

    return await axios.put(url, chunk, { headers });
  } catch (e: any) {
    if (e.response?.status === 308) {
      // Chunk Upload Successful
    } else {
      throw e;
    }
  }
};

const postVideoProgress = async (
  data: ReadableStream,
  courseId: string,
  videoId: string
) =>
  await fetchApi('videos', `/course/${courseId}/video/${videoId}`, {
    method: 'POST',
    auth: true,
    isProgress: true,
    data
  });

export const postVideoProgressMutation = (
  videoId: string,
  courseId: string
) => ({
  mutationKey: ['post-video-progress', { videoId, courseId }],
  mutationFn: async ({
    data,
    courseId,
    videoId
  }: {
    data: ReadableStream;
    courseId: string;
    videoId: string;
  }) => await postVideoProgress(data, courseId, videoId)
});

// Edit video
const editVideo = async (data: IVideoDto, courseId: string, videoId: string) =>
  await fetchApi('courses', `/course/${courseId}/video/${videoId}`, {
    method: 'PUT',
    auth: true,
    data: {
      ...data,
      completion_score: data.completion_score?.value || 0
    }
  });

export const editVideoMutation = () => ({
  mutationKey: ['edit-video'],
  mutationFn: async ({ data, courseId, videoId }: IEditVideoDto) => {
    if (data.thumbnail && !data.thumbnail.includes('data:image/'))
      delete data.thumbnail;
    return await editVideo(data, courseId, videoId);
  }
});

// Delete video
const deleteVideo = async (
  videoId: string,
  courseId: string,
  searchParams?: string
) => {
  return await fetchApi(
    'courses',
    `/course/${courseId}/video/${videoId}${searchParams || ''}`,
    {
      method: 'DELETE',
      auth: true
    }
  );
};

export const deleteVideoMutation = (
  videoId: string,
  courseId: string,
  searchParams?: string
) => {
  const queryKeyParams = buildQueryKey(searchParams);

  return {
    mutationKey: ['delete-video', { videoId, courseId, ...queryKeyParams }],
    mutationFn: async ({
      videoId,
      courseId
    }: {
      videoId: string;
      courseId: string;
    }) => await deleteVideo(videoId, courseId, searchParams || '')
  };
};

// Edit videos order
const editVideoOrder = async (
  data: { indexes: { [key: number]: string } },
  courseId: string
) =>
  await fetchApi('courses', `/course/${courseId}/video/reorder`, {
    method: 'POST',
    auth: true,
    data
  });

export const editVideoOrderMutation = (courseId: string) => ({
  mutationKey: ['edit-video-order', { id: courseId }],
  mutationFn: async ({ data, courseId }: IEditVideoOrderDto) => {
    const indexes = data.reduce(
      (acc, cur, i) => ({ ...acc, [i + 1]: cur._id }),
      {}
    );
    return await editVideoOrder({ indexes }, courseId);
  }
});

export const activateCourseMutation = (courseId: string) => ({
  mutationKey: ['activate-query'],
  mutationFn: async () => {
    fetchApi('courses', `/course/${courseId}/activate-trx`, {
      method: 'POST',
      auth: true
    });
  }
});

export const activateBundleMutation = (id: string) => ({
  mutationKey: ['activate-bundle'],
  mutationFn: async () => {
    fetchApi('courses', `/bundles/${id}/activate-trx`, {
      method: 'POST',
      auth: true
    });
  }
});

export const resetActivateBundleMutation = (id: string) => ({
  mutationKey: ['reset-bundle'],
  mutationFn: async () => {
    fetchApi('courses', `/bundles/${id}/activate-trx/reset`, {
      method: 'POST',
      auth: true
    });
  }
});

export const resetActivateCourseMutation = (courseId: string) => ({
  mutationKey: ['activate-query'],
  mutationFn: async () => {
    fetchApi('courses', `/course/${courseId}/activate-trx/reset`, {
      method: 'POST',
      auth: true
    });
  }
});

export const rejectListMutation = (courseId: string) => ({
  mutationKey: ['reject-list-query'],
  mutationFn: async () => {
    fetchApi('courses', `/course/${courseId}/signature/list/reset`, {
      method: 'POST',
      auth: true
    });
  }
});

export const rejectRepublishMutation = (courseId: string) => ({
  mutationKey: ['reject-republish-query'],
  mutationFn: async () => {
    fetchApi('courses', `/course/${courseId}/signature/update-item/reset`, {
      method: 'POST',
      auth: true
    });
  }
});

export const rejectBuyMutation = (courseId: string, recepients: string[]) => ({
  mutationKey: ['reject-buy-query'],
  mutationFn: async () => {
    return await fetchApi(
      'courses',
      `/course/${courseId}/signature/buy/reset`,
      {
        method: 'POST',
        auth: true,
        data: { wallets: recepients }
      }
    );
  }
});

export const rejectBuyBundleMutation = (id: string, recepients: string[]) => ({
  mutationKey: ['reject-buy-query'],
  mutationFn: async () => {
    return await fetchApi('courses', `/bundles/${id}/signature/buy/reset`, {
      method: 'POST',
      auth: true,
      data: { wallets: recepients }
    });
  }
});

export const videoProgressMutation = (courseId: string, videoId: string) => {
  return {
    mutationKey: ['on-progress'],
    mutationFn: async (data: {
      playedSeconds: number;
      is_finished: boolean;
    }) => {
      return await fetchApi(
        'courses',
        `/course/${courseId}/video/${videoId}/progress`,
        {
          method: 'PUT',
          auth: true,
          data: {
            last_duration: data.playedSeconds,
            is_completed: data.is_finished
          }
        }
      );
    }
  };
};

export const submitTestMutation = (
  courseId: string,
  videoId: string,
  answers: number[]
) => ({
  mutationKey: ['submit-test-query'],
  mutationFn: async () => {
    return await fetchApi(
      'courses',
      `/course/${courseId}/video/${videoId}/test`,
      {
        method: 'POST',
        auth: true,
        data: { answers }
      }
    );
  }
});

export const updateNotificationMutation = (
  courseId: string,
  queryClient: QueryClient
) => {
  return {
    mutationKey: ['update-notification'],
    mutationFn: async () => {
      await fetchApi('courses', `/user-course/certificates/${courseId}`, {
        method: 'PUT',
        auth: true,
        data: {
          seen: true
        }
      }).then(() => {
        queryClient.invalidateQueries({ queryKey: ['certificates-data'] });
      });
    }
  };
};

export const createCategory = (name: string, icon: string) => ({
  mutationKey: ['create-category'],
  mutationFn: async () => {
    return await fetchApi('courses', `/admin/category`, {
      method: 'POST',
      auth: true,
      data: { name, icon }
    });
  }
});

export const updateCategory = (id: string, name?: string, icon?: string) => ({
  mutationKey: ['update-category'],
  mutationFn: async () => {
    return await fetchApi('courses', `/admin/category/${id}`, {
      method: 'PUT',
      auth: true,
      data: { name, icon }
    });
  }
});

export const createSubcategory = (categoryId: string, name: string) => ({
  mutationKey: ['create-subcategory'],
  mutationFn: async () => {
    return await fetchApi(
      'courses',
      `/admin/category/${categoryId}/subcategory`,
      {
        method: 'POST',
        auth: true,
        data: { name }
      }
    );
  }
});

export const updateSubcategory = (
  categoryId: string,
  subcategoryId: string,
  name: string
) => ({
  mutationKey: ['update-subcategory'],
  mutationFn: async () => {
    return await fetchApi(
      'courses',
      `/admin/category/${categoryId}/subcategory/${subcategoryId}`,
      {
        method: 'PUT',
        auth: true,
        data: { name }
      }
    );
  }
});

export const deleteCategory = (categoryId: string) => ({
  mutationKey: ['delete-category'],
  mutationFn: async () => {
    return await fetchApi('courses', `/admin/category/${categoryId}`, {
      method: 'DELETE',
      auth: true
    });
  }
});

export const deleteSubcategory = (
  categoryId: string,
  subcategoryId: string
) => ({
  mutationKey: ['delete-subcategory'],
  mutationFn: async () => {
    return await fetchApi(
      'courses',
      `/admin/category/${categoryId}/subcategory/${subcategoryId}`,
      {
        method: 'DELETE',
        auth: true
      }
    );
  }
});

export const platformSettingsMutation = () => {
  return {
    mutationKey: ['platform-fee-mutation'],
    mutationFn: async (settings: IPlatformSettingsDto) =>
      await fetchApi('courses', '/settings', {
        method: 'PUT',
        auth: true,
        data: settings
      })
  };
};

export const claimFundsMutation = (purchaseIds: string[]) => ({
  mutationKey: ['claim-funds'],
  mutationFn: async () => {
    return await fetchApi('courses', '/creator/claim-trx', {
      method: 'POST',
      auth: true,
      data: { purchase_ids: purchaseIds }
    });
  }
});

export const claimFundsResetMutation = (purchaseIds: string[]) => ({
  mutationKey: ['claim-funds-reset'],
  mutationFn: async () => {
    return await fetchApi('courses', '/creator/claim-trx/reset', {
      method: 'POST',
      auth: true,
      data: { purchase_ids: purchaseIds }
    });
  }
});

export const claimFundsMutationAdmin = (purchaseIds: string[]) => ({
  mutationKey: ['claim-funds-admin'],
  mutationFn: async () => {
    return await fetchApi('courses', '/admin/collect-fee-trx', {
      method: 'POST',
      auth: true,
      data: { purchase_ids: purchaseIds }
    });
  }
});

export const claimFundsResetMutationAdmin = (purchaseIds: string[]) => ({
  mutationKey: ['claim-funds-reset-admin'],
  mutationFn: async () => {
    return await fetchApi('courses', '/admin/collect-fee-trx/reset', {
      method: 'POST',
      auth: true,
      data: { purchase_ids: purchaseIds }
    });
  }
});

export const manualRefundCourseMutation = (
  userId: string,
  courseId: string,
  transactionHash: string
) => ({
  mutationKey: ['manual-refund-course'],
  mutationFn: async () => {
    return await fetchApi('courses', `/creator/${courseId}/refund/${userId}`, {
      method: 'POST',
      auth: true,
      data: { refund_tx: transactionHash }
    });
  }
});

export const manualRefundBundleMutation = (
  userId: string,
  bundleId: string,
  transactionHash: string
) => ({
  mutationKey: ['manual-refund-bundle'],
  mutationFn: async () => {
    return await fetchApi(
      'courses',
      `/bundle-creator/${bundleId}/refund/${userId}`,
      {
        method: 'POST',
        auth: true,
        data: { refund_tx: transactionHash }
      }
    );
  }
});

export const refundMutation = (purchaseIds: string[]) => ({
  mutationKey: ['refund'],
  mutationFn: async () => {
    return await fetchApi('courses', '/user-course/refund-trx', {
      method: 'POST',
      auth: true,
      data: { purchase_ids: purchaseIds }
    });
  }
});

export const resetRefundMutation = (purchaseIds: string[]) => ({
  mutationKey: ['reset-refund'],
  mutationFn: async () => {
    return await fetchApi('courses', '/user-course/refund-trx/reset', {
      method: 'POST',
      auth: true,
      data: { purchase_ids: purchaseIds }
    });
  }
});

export const banUserMutation = (userIds: string[]) => ({
  mutationKey: ['ban-user'],
  mutationFn: async () => {
    return await fetchApi('courses', `/admin/user/blacklist`, {
      auth: true,
      method: 'POST',
      data: {
        id_list: userIds
      }
    });
  }
});

export const activateUserMutation = (userIds: string[]) => ({
  mutationKey: ['activate-user'],
  mutationFn: async () => {
    return await fetchApi('courses', `/admin/user/blacklist`, {
      auth: true,
      method: 'DELETE',
      data: {
        id_list: userIds
      }
    });
  }
});
