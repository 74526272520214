import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import queryString from 'query-string';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import GenericPopup from 'components/popup/GenericPopup';
import FormSteps from 'components/forms/formSteps/FormSteps';
import { CircularProggress } from 'components/progress/CircularProgress';
import { IOption } from 'components/input/InputChoice';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import Step4 from './Step4';
import Summary from './Summary';
import NavIsland from '../../courses/components/NavIsland';
import { Web3Context } from 'context/web3Context';
import { popupContext } from 'context/popupContext';
import { toastContext } from 'context/toastContext';
import { windowContext } from 'context/windowsContext';
import {
  createBundleMutation,
  editBundleMutation,
  editBundleSignatureMutation
} from 'query/course-module/mutations';
import { CourseBundleSchema, ICourseBundleFormFields } from 'utils/yupSchemas';
import { ICourseBundle } from 'query/course-module/dto';
import { getContractInstance, payloadData } from 'contracts/contracts';
import useCurrencyContract from 'hooks/useCurrencyContract';
import { useEthersSigner } from 'hooks/useEthersSigner';
import {
  REACT_APP_BLOCK_EXPLORER_URL,
  REACT_APP_CURRENCY_CONTRACT_ADDRESS
} from 'utils/constants';
import { submitTx } from 'utils/requests';
import classes from './Form.module.scss';

export interface IFormik {
  errors: FormikErrors<ICourseBundleFormFields>;
  touched: FormikTouched<ICourseBundleFormFields>;
  values: ICourseBundleFormFields;
  setFieldValue: (
    fieldName: string,
    value: string | boolean | IOption[] | null
  ) => void;
  setFieldError: (fieldName: string, value: string) => void;
  setFieldTouched: (fieldName: string, value: boolean) => void;
  submitForm: () => void;
  handleBlur: (e: React.FocusEvent<HTMLElement>) => void;
  isValid: boolean;
}

interface IRenderStep extends IFormik {
  step: number;
}

export interface ICourseForm {
  steps: string[];
  finishedSteps: number;
  setCurrentStep: (step: number) => void;
  currentStep: number;
  setStep: (step: number) => void;
  defaultFormData: ICourseBundleFormFields;
  isEdit?: boolean;
  bundle?: ICourseBundle;
}

const CourseBundleForm = ({
  steps,
  finishedSteps,
  setCurrentStep,
  currentStep,
  setStep,
  defaultFormData,
  isEdit,
  bundle
}: ICourseForm) => {
  const { signer } = useEthersSigner();
  const { decimals } = useCurrencyContract(REACT_APP_CURRENCY_CONTRACT_ADDRESS);
  const { increasePendingTransactions, decreasePendingTransactions } =
    useContext(Web3Context);
  const { setToast } = useContext(toastContext);
  const { setPopup } = useContext(popupContext);
  const { appWrapperElement } = useContext(windowContext);
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const { not_finished } = bundle || {};
  const navigate = useNavigate();
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);

  const setErrorPopup = (msg: string) =>
    setPopup(
      <GenericPopup
        type="error"
        msg={msg}
        buttonName="Close"
        buttonVariant="neutral"
        isClosable={false}
      />
    );

  const { isLoading: isLoadingCreate, mutate: handleCreate } = useMutation({
    mutationFn: async (data: ICourseBundleFormFields) => {
      const response = await createBundleMutation().mutationFn(data);

      increasePendingTransactions();
      const contract = getContractInstance('payment', signer);

      // Convert price to 6 decimals (USDC)
      const price = BigInt(
        +response.courseBundle.price * Math.pow(10, decimals)
      );
      response.itemData = {
        ...response.signature_data.itemData,
        purchasePrice: price
      };
      delete response.itemData.lister;

      const contractData = [
        response.itemData,
        response.signature_data.paymentData,
        response.signature_data.sigExpiry,
        response.signature_data.signature
      ];
      const receipt = await submitTx(contract, 'list', contractData);
      return receipt;
    },
    onSuccess: (receipt) => {
      decreasePendingTransactions();
      setToast({
        type: 'success',
        title: 'Successful Transaction',
        msg: 'Your bundle is now publishing. View',
        linkMsg: 'transaction',
        linkTo: `${REACT_APP_BLOCK_EXPLORER_URL}/tx/${receipt.hash}`,
        position: 'top',
        autoClose: true
      });
      navigate('/user/bundles');
    },
    onError: (err: Error) => {
      decreasePendingTransactions();
      setPopup(
        <GenericPopup type="error" msg={err.message} buttonName="Close" />
      );
    }
  });
  const { isLoading, mutate: handleEdit } = useMutation({
    mutationFn: async (data: ICourseBundleFormFields) => {
      const response = await editBundleSignatureMutation(
        data._id,
        payloadData
      ).mutationFn();

      increasePendingTransactions();
      const contract = getContractInstance('payment', signer);

      // Convert price to 6 decimals (USDC)
      const price = BigInt(+data.price * Math.pow(10, decimals));
      response.updateItemData = {
        ...response.updateItemData,
        purchasePrice: price
      };

      const contractData = [
        response.item_id,
        response.updateItemData,
        response.paymentData,
        response.sigExpiry,
        response.signature
      ];

      const receipt = await submitTx(contract, 'updateItem', contractData);

      return {
        data: {
          ...data,
          slug: response?.bundle?.slug
        },
        receipt
      };
    },
    onSuccess: async ({ data, receipt }) => {
      await editBundleMutation().mutationFn(data);
      decreasePendingTransactions();
      setToast({
        type: 'success',
        title: 'Successful Transaction',
        msg: 'Your bundle is now updated. View',
        linkMsg: 'transaction',
        linkTo: `${REACT_APP_BLOCK_EXPLORER_URL}/tx/${receipt.hash}`,
        position: 'top',
        autoClose: true
      });
      navigate(`/bundles/${data.slug}`);
    },
    onError: (err: Error) => {
      decreasePendingTransactions();
      setPopup(
        <GenericPopup type="error" msg={err.message} buttonName="Close" />
      );
    }
  });

  const fieldsByStep: { [key: number]: string[] } = {
    1: ['courseIds'],
    2: ['courseIds'],
    3: ['name', 'category', 'description', 'thumbnail'],
    4: ['price', 'discount'],
    5: []
  };

  const handleStepClick = (
    step: number,
    fields: string[],
    setFieldTouched: (fieldName: string, value: boolean) => void
  ) => {
    if (step > 0) fields.forEach((f: string) => setFieldTouched(f, true));
    setStep(step <= 0 ? 0 : step);
  };

  const renderStep = ({
    step,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleBlur
  }: IRenderStep) => {
    if (step === 0) {
      return (
        <Step1
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          handleBlur={handleBlur}
        />
      );
    }
    if (step === 1) {
      return (
        <Step2
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          setFieldError={setFieldError}
        />
      );
    }

    if (step === 2) {
      return (
        <Step3
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          setFieldError={setFieldError}
          handleBlur={handleBlur}
        />
      );
    }

    if (step === 3) {
      return (
        <Step4
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
        />
      );
    }

    return <Summary data={values} errors={errors} setStep={setStep} />;
  };

  useEffect(() => {
    appWrapperElement?.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <Formik
      initialValues={defaultFormData}
      validationSchema={CourseBundleSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (
          isLoadingCreate
          // || isLoadingEdit
        )
          return;
        if (isEdit) {
          handleEdit(values, {
            onSuccess: () => {
              localStorage.removeItem('selectedCourses');
            }
          });
        } else {
          handleCreate(values, {
            onSuccess: () => {
              navigate('/user/bundles');
              localStorage.removeItem('selectedCourses');
            }
          });
        }
        setSubmitting(false);
      }}
      validateOnBlur
      validateOnMount
    >
      {(props) => (
        <Form className={classes['form-wrapper']}>
          {!isLgMobile && (
            <FormSteps
              steps={steps}
              currentStep={currentStep}
              finishedSteps={finishedSteps}
              setCurrentStep={(step: number) => setCurrentStep(step)}
              errors={props.errors}
              fieldsByStep={fieldsByStep}
              onStepClick={(i) => {
                handleStepClick(i, fieldsByStep[i], props.setFieldTouched);
              }}
            />
          )}
          <div className={classes['form']}>
            {isLgMobile ? (
              <div className={classes['progress-bar-wrapper']}>
                <CircularProggress
                  steps={steps.length}
                  step={currentStep + 1}
                  percentage={((currentStep + 1) / steps.length) * 100}
                />
                <div className={classes['progress-bar-wrapper__labels']}>
                  <h5
                    className={`${classes['u-semiBold']} ${classes['u-body1']}`}
                  >
                    {steps[currentStep]}
                  </h5>
                  {currentStep < steps.length - 1 && (
                    <h5
                      className={`${classes['u-text--content']} ${classes['u-body3']}`}
                    >
                      {`Next: ${steps[currentStep + 1]}`}
                    </h5>
                  )}
                </div>
              </div>
            ) : (
              <h4 className={classes['u-light']}>{steps[currentStep]}</h4>
            )}
            {renderStep({
              step: currentStep,
              ...props
            })}
            <NavIsland
              isBundle
              steps={steps.length}
              course={bundle?.courses[0]}
              isEdit={!!isEdit}
              isValid={!!props.isValid}
              currentStep={currentStep}
              setStep={setStep}
              onNextStepClick={() =>
                handleStepClick(
                  currentStep + 1,
                  fieldsByStep[currentStep + 1],
                  props.setFieldTouched
                )
              }
              submitForm={props.submitForm}
              isSubmitLoading={isLoadingCreate || isLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CourseBundleForm;
