import Button from 'components/buttons/Button';
import { IFormik } from './OnboardingForm';
import {
  IOnboardingDtoExtended,
  IUserInfo,
  MARKETING_INFO_TYPE
} from 'query/acc-module/dto';
import classes from './OnboardingForm.module.scss';

interface IStep3
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldError'
    | 'handleBlur'
  > {
  steps: number;
  currentStep: number;
  setStep: (step: number) => void;
  renderPrevBtn: () => {};
  submitForm: () => void;
  defaultFormData: IOnboardingDtoExtended;
}

export const Step3 = ({
  currentStep,
  renderPrevBtn,
  values,
  steps,
  errors,
  setFieldValue,
  submitForm,
  defaultFormData
}: IStep3) => {
  return (
    <>
      <div className={classes['header']}>
        <h4 className={classes['u-semiBold']}>What brings you to OpusEdu</h4>
        <h5 className={`${classes['u-body2']} ${classes['u-text--content']}`}>
          We will use this in order to recommend the most suitable courses for
          you.
        </h5>
      </div>
      <div className={classes['user-info-fields']}>
        <div className={classes['user-info-fields__usage']}>
          {defaultFormData.userInfo.map(
            (userInfo: IUserInfo, index: number) =>
              userInfo.type === MARKETING_INFO_TYPE.PLATFORM_USAGE_REASON && (
                <div
                  key={index}
                  className={`${classes['user-info-fields__wrapper__button']} ${
                    classes[
                      values.platform_usage_reasons?.includes(userInfo._id)
                        ? 'selected'
                        : ''
                    ]
                  }`}
                  onClick={() => {
                    if (values.platform_usage_reasons) {
                      if (
                        values.platform_usage_reasons.includes(userInfo._id)
                      ) {
                        const updated = [...values.platform_usage_reasons];
                        updated.splice(
                          values.platform_usage_reasons.indexOf(userInfo._id),
                          1
                        );

                        setFieldValue('platform_usage_reasons', updated);
                      } else {
                        const updated = [...values.platform_usage_reasons];
                        updated.push(userInfo._id);

                        setFieldValue('platform_usage_reasons', updated);
                      }
                    }
                  }}
                >
                  {userInfo.value}
                </div>
              )
          )}
        </div>
      </div>
      <div className={classes['btns-container']}>
        <div className={classes['u-text--content']}>{`Step ${
          currentStep + 1
        } of ${steps}`}</div>
        <div className={classes['btns-wrapper']}>
          <>
            {renderPrevBtn()}
            <Button
              type="submit"
              minWidth="lg"
              onClick={submitForm}
              isDisabled={!!errors.platform_usage_reasons}
            >
              Submit
            </Button>
          </>
        </div>
      </div>
    </>
  );
};

export default Step3;
