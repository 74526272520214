import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { useAccount } from 'wagmi';
import { Web3Context } from 'context/web3Context';
import { popupContext } from 'context/popupContext';
import { userContext } from 'context/userContext';
import Tooltip from 'components/tooltip/Tooltip';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import ContextMenu from 'components/buttons/ContextMenu';
import Button from 'components/buttons/Button';
import ActivatePopup from '../../components/popups/ActivatePopup';
import VerifyAccountPopup from 'components/popup/VerifyAccountPopup';
import BuyActivationPopup from '../../components/popups/BuyActivationPopup';
import BuyActivationPopupNoQuoter from '../../components/popups/BuyActivationPopupNoQuoter';
import { tokenPairsDataQuery } from 'query/course-module/queries';
import { USER_KYC_STATUS } from 'query/acc-module/dto';
import {
  COURSE_RELATIONSHIP,
  ICourseBundle,
  ITokenPairsData
} from 'query/course-module/dto';
import { REACT_APP_CHAIN_ID } from 'utils/constants';
import classes from './Buttons.module.scss';

interface IBundleButtonsProps {
  bundle: ICourseBundle;
}

const BundleButtons = ({ bundle }: IBundleButtonsProps) => {
  const navigate = useNavigate();
  const { search: urlParams } = useLocation();
  const { is_public_view } = queryString.parse(urlParams);
  const { isWalletUnique, isCorrectChain } = useContext(Web3Context);
  const {
    isTokenExpired,
    userData: { kyc_status }
  } = useContext(userContext);
  const { setPopup } = useContext(popupContext);
  const { isConnected } = useAccount();

  const { _id, purchased, bundle_relationship, courses } = bundle;

  const isCreatorPublicView = is_public_view === 'true';

  // Get token pairs
  const {
    isLoading: tokenPairsLoading,
    error: tokenPairsError,
    data: tokenPairsData
  } = useQuery<boolean, Error, ITokenPairsData>(tokenPairsDataQuery());

  if (tokenPairsLoading) return <Loader size="lg" hasText withPadding />;
  if (tokenPairsError) return <Error error={tokenPairsError} />;

  const renderBuyButton = (isBuyAgain: boolean) => (
    <Tooltip
      id={_id as string}
      text={
        isTokenExpired || !isConnected || !isCorrectChain || !isWalletUnique
          ? `In order to buy Activation NFT, please 
    ${
      isTokenExpired
        ? 'log in first.'
        : !isConnected
        ? 'connect your wallet first.'
        : !isCorrectChain
        ? 'switch to the Polygon network.'
        : !isWalletUnique
        ? 'switch your wallet. This one is associated with another account.'
        : ''
    }`
          : !purchased?.active && !!purchased?.buy_tx_started_at
          ? "You'll be able to access the course content shortly."
          : ''
      }
    >
      <Button
        className={!isBuyAgain ? classes['u-m8-bot'] : ''}
        onClick={() => {
          /* 
            Use popup with quoter only on prod, since amoy
            doesn't have quoter contracts deployed
          */
          setPopup(
            kyc_status === USER_KYC_STATUS.APPROVED ? (
              +REACT_APP_CHAIN_ID === 137 ? (
                <BuyActivationPopup
                  data={bundle}
                  type="bundle"
                  tokenPairs={tokenPairsData}
                  relationship={bundle_relationship}
                />
              ) : (
                <BuyActivationPopupNoQuoter
                  type="bundle"
                  data={bundle}
                  tokenPairs={tokenPairsData}
                  relationship={bundle_relationship}
                />
              )
            ) : (
              <VerifyAccountPopup />
            )
          );
        }}
        variant={isBuyAgain ? 'link-neutral' : 'contrast'}
        minWidth="full"
        isDisabled={
          !purchased?.active &&
          (isTokenExpired || !!purchased?.buy_tx_started_at)
        }
        isWeb3
      >
        {!purchased?.active
          ? !purchased?.buy_tx_started_at
            ? 'Buy Activation NFT'
            : 'Processing Payment...'
          : 'Buy As Gift'}
      </Button>
    </Tooltip>
  );

  const renderContextMenu = () => {
    return (
      <span>
        <ContextMenu
          children={
            <>
              {/* ================= BUY AS A GIFT BUTTON ================= */}
              {
                <Button
                  variant="neutral"
                  onClick={() => {
                    /* 
                      Use popup with quoter only on prod, since amoy
                      doesn't have quoter contracts deployed
                    */
                    setPopup(
                      +REACT_APP_CHAIN_ID === 137 ? (
                        <BuyActivationPopup
                          type="bundle"
                          data={bundle}
                          tokenPairs={tokenPairsData}
                          relationship={bundle_relationship}
                          isBuyAsGiftOnly
                        />
                      ) : (
                        <BuyActivationPopupNoQuoter
                          type="bundle"
                          data={bundle}
                          isBuyAsGiftOnly
                          tokenPairs={tokenPairsData}
                          relationship={bundle_relationship}
                        />
                      )
                    );
                  }}
                  isWeb3
                >
                  Buy as a Gift
                </Button>
              }
            </>
          }
        />
      </span>
    );
  };

  return (
    <div className={classes['btns-row']}>
      <div className={classes['btns-row__primary']}>
        {/* Logged and non-logged in users see the Buy button */}
        {(bundle_relationship !== COURSE_RELATIONSHIP.PURCHASED ||
          isCreatorPublicView) &&
          renderBuyButton(false)}
        {!purchased?.active && !!purchased?.buy_tx_confirmed_at && (
          <Tooltip
            id={_id as string}
            text={
              isTokenExpired ||
              !isConnected ||
              !isCorrectChain ||
              !isWalletUnique
                ? `In order to activate your course, please
              ${
                isTokenExpired
                  ? 'log in first.'
                  : !isConnected
                  ? 'connect your wallet first.'
                  : !isCorrectChain
                  ? 'switch to the Polygon network.'
                  : !isWalletUnique
                  ? 'switch your wallet. This one is associated with another account.'
                  : ''
              }`
                : !!purchased.activate_tx_started_at
                ? "You'll be able to access the course content shortly."
                : ''
            }
          >
            <Button
              className={classes['u-m8-bot']}
              onClick={() => {
                // If course is received as gift, the user must be able to activate and watch the course
                if (purchased.purchased_by_user_id !== purchased.user_id)
                  return setPopup(
                    <ActivatePopup data={bundle} type="bundle" />
                  );
                setPopup(
                  kyc_status === USER_KYC_STATUS.APPROVED ? (
                    <ActivatePopup data={bundle} type="bundle" />
                  ) : (
                    <VerifyAccountPopup />
                  )
                );
              }}
              variant="contrast"
              minWidth="full"
              isDisabled={
                isTokenExpired ||
                !!purchased.activate_tx_started_at ||
                !!purchased.refund_tx_started_at
              }
              isWeb3
            >
              {!purchased.activate_tx_started_at
                ? 'Activate Bundle'
                : 'Processing Activation'}
            </Button>
          </Tooltip>
        )}
        {purchased?.active && (
          <Button
            onClick={() => navigate(`/courses/${courses[0]._id}/?changes=0`)}
            minWidth="full"
          >
            Start Courses
          </Button>
        )}
      </div>
      {bundle_relationship === COURSE_RELATIONSHIP.PURCHASED &&
        renderContextMenu()}
    </div>
  );
};

export default BundleButtons;
