import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { InputWrapper } from 'components/input/InputWrapper';
import SwitchInput from 'components/buttons/SwitchInput';
import { InputMedia } from 'components/input/InputMedia';
import Button from 'components/buttons/Button';
import { IFormik } from './CreateBlogForm';
import { courseCategoriesDataQuery } from 'query/course-module/queries';
import { ICourseCategoryData, ICourseCategory } from 'query/course-module/dto';
import { handleMediaUpload } from 'utils/processing';
import classes from './CreateBlogForm.module.scss';

interface IStep1
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldTouched'
    | 'setFieldError'
    | 'handleBlur'
  > {}

export const Step1 = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  handleBlur
}: IStep1) => {
  const [tag, setTag] = useState('');

  const { data } = useQuery<boolean, Error, ICourseCategoryData>(
    courseCategoriesDataQuery()
  );

  return (
    <div className={classes['blog-form-step']}>
      <InputWrapper
        name="title"
        type="text"
        variant="outline"
        onChange={(e) => setFieldValue('title', e)}
        onBlur={(e) => handleBlur(e)}
        value={values.title}
        title="Title"
        placeholder="Enter Post Title"
        isRequired
        error={errors.title && touched.title ? errors.title : ''}
        limit={60}
        subtitle={`${values.title?.length} of 60 characters used`}
      />
      <InputMedia
        name="thumbnail"
        title="Blog Post Thumbnail"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleMediaUpload(
            e,
            'thumbnail',
            setFieldValue,
            setFieldTouched,
            setFieldError
          );
        }}
        onClear={async () => {
          setFieldValue('thumbnail', '');
        }}
        hasTooltip
        tooltipText={
          'Upload Blog Post Thumbnail image. It will be visible in the Blog Post Cards on the landing page.'
        }
        isRequired
        pic={values.thumbnail}
        error={errors.thumbnail && touched.thumbnail ? errors.thumbnail : ''}
      />
      <InputWrapper
        name="topic"
        type="text"
        variant="outline"
        onChange={(e) => setFieldValue('topic', e)}
        onBlur={(e) => handleBlur(e)}
        value={values.topic}
        title="Topic"
        placeholder="Enter Topic"
        isRequired
        error={errors.topic && touched.topic ? errors.topic : ''}
        limit={60}
        subtitle={`${values.topic?.length} of 60 characters used`}
      />
      <InputWrapper
        name="description"
        type="textarea"
        variant="outline"
        onChange={(e) => setFieldValue('description', e)}
        onBlur={(e) => handleBlur(e)}
        value={values.description}
        title="Post Description"
        isRequired
        placeholder="Enter Post Description"
        hasTooltip
        tooltipText="A description of your Post. This description will be visible on bundle card components."
        error={
          errors.description && touched.description ? errors.description : ''
        }
        subtitle={`${values.description?.length} of 500 characters used`}
      />
      <SwitchInput
        size="sm"
        value={values.isTopPost}
        label="Set as Top Post"
        onClick={(e) => setFieldValue('isTopPost', e)}
        error={errors.isTopPost && touched.isTopPost ? errors.isTopPost : ''}
      />
      <div className={classes['tags-wrapper']}>
        <h5 className={`${classes['u-body3']} ${classes['u-semiBold']}`}>
          Add Tags
        </h5>
        <div className={classes['categories-wrapper']}>
          {data?.result?.map(
            (category: ICourseCategory, index: number) =>
              !values.tags.includes(category.name) && (
                <Button
                  size="sm"
                  icon={PlusIcon}
                  variant="neutral"
                  iconPosition="left"
                  className={classes['u-regular']}
                  onClick={() => {
                    if (!!values.tags) {
                      setFieldValue('tags', [...values.tags, category.name]);
                    } else {
                      setFieldValue('tags', [category.name as any]);
                    }
                  }}
                >
                  {category.name}
                </Button>
              )
          )}
        </div>
        <div className={classes['input-wrapper']}>
          <InputWrapper
            value={tag}
            name="tags"
            type="text"
            variant="outline"
            placeholder="Enter Custom Tag"
            onChange={(e) => setTag(e)}
            onBlur={(e) => handleBlur(e)}
          />
          <Button
            size="sm"
            variant="neutral"
            onClick={() => {
              setTag('');
              if (tag) {
                if (!!values.tags) {
                  setFieldValue('tags', [...values.tags, tag]);
                } else {
                  setFieldValue('tags', [tag as any]);
                }
              }
            }}
          >
            Add
          </Button>
        </div>
        {!!values.tags.length && (
          <div className={`${classes['categories-wrapper']} ${classes['tag']}`}>
            {values.tags.map((tag: string, index: number) => (
              <Button
                size="sm"
                icon={Close}
                variant="neutral"
                className={classes['u-regular']}
                onClick={() => {
                  const updated = [...values.tags];
                  updated.splice(index, 1);

                  setFieldValue('tags', updated);
                }}
              >
                {tag}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Step1;
