import React, { useContext } from 'react';
import { TransactionReceipt } from 'ethers';
import { useAccount } from 'wagmi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Web3Context } from 'context/web3Context';
import { toastContext } from 'context/toastContext';
import { popupContext } from 'context/popupContext';
import { useEthersSigner } from 'hooks/useEthersSigner';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import Card from 'components/card/Card';
import Button from 'components/buttons/Button';
import InfoBox from 'components/info-box/InfoBox';
import { ICourse, ICourseBundle } from 'query/course-module/dto';
import { getContractInstance } from 'contracts/contracts';
import { submitTx } from 'utils/requests';
import { REACT_APP_BLOCK_EXPLORER_URL } from 'utils/constants';
import {
  activateBundleMutation,
  activateCourseMutation,
  resetActivateBundleMutation,
  resetActivateCourseMutation
} from 'query/course-module/mutations';
import classes from './ActivatePopup.module.scss';
import { capitalizeFirstLetter } from 'utils/format';

interface IActivatePopupProps {
  type: 'course' | 'bundle';
  data: ICourse | ICourseBundle;
}

const ActivatePopup = ({ data, type }: IActivatePopupProps) => {
  const queryClient = useQueryClient();
  const { clearPopup } = useContext(popupContext);

  const { increasePendingTransactions, decreasePendingTransactions } =
    useContext(Web3Context);

  const { setToast } = useContext(toastContext);
  const { address } = useAccount();
  const { signer } = useEthersSigner();

  const { mutate: activateMutation } = useMutation({
    ...activateCourseMutation(data._id),
    onSuccess: () =>
      queryClient.invalidateQueries([
        'course-data',
        { changes: 0, id: data._id }
      ])
  });

  const { mutate: activateCourseBundleMutation } = useMutation({
    ...activateBundleMutation(data._id),
    onSuccess: () =>
      queryClient.invalidateQueries([
        'bundle-data',
        { changes: 0, id: data._id }
      ])
  });

  const { mutate: resetMutation } = useMutation({
    ...resetActivateCourseMutation(data._id),
    onSuccess: () =>
      queryClient.invalidateQueries([
        'course-data',
        { changes: 0, id: data._id }
      ])
  });

  const { mutate: resetCourseBundleMutation } = useMutation({
    ...resetActivateBundleMutation(data._id),
    onSuccess: () =>
      queryClient.invalidateQueries([
        'bundle-data',
        { changes: 0, id: data._id }
      ])
  });

  const { isLoading, mutate: activateCourseHandler } = useMutation({
    mutationKey: ['activate-course'],
    mutationFn: async () => {
      increasePendingTransactions();
      type === 'course' ? activateMutation() : activateCourseBundleMutation();

      // Contract Instance
      const contract = getContractInstance('access', signer);
      const txData = [address, data.token_id];

      // Initialise Activate Tx
      const receipt = await submitTx(contract, 'activate', txData);
      return receipt;
    },
    onSuccess: (receipt: TransactionReceipt) => {
      clearPopup();
      decreasePendingTransactions();
      setToast({
        type: 'success',
        title: 'Successful Transaction',
        msg: `You have successfully activated this ${type}! View`,
        linkMsg: 'transaction',
        linkTo: `${REACT_APP_BLOCK_EXPLORER_URL}/tx/${receipt.hash}`,
        position: 'top',
        autoClose: true
      });
    },
    onError: async (err: Error) => {
      type === 'course' ? resetMutation() : resetCourseBundleMutation();
      decreasePendingTransactions();
      clearPopup();
      setToast({
        type: 'error',
        position: 'top',
        title: 'Transaction Failed',
        msg: err.message || '',
        autoClose: true
      });
    }
  });

  return (
    <ModalWrapper size="md" onClose={() => clearPopup()}>
      <div className={classes['popup-container']}>
        <div className={classes['content']}>
          <h4 className={classes['u-semiBold']}>
            {`Activate ${capitalizeFirstLetter(type)}`}
          </h4>
          {/* <p className={classes['u-body2']}>
            In order to activate your course you need to burn your activation
            token. This means you won’t be able to transfer it to other
            addresses.
          </p> */}
        </div>
        <div className={classes['card']}>
          <Card course={data as ICourse} />
        </div>
        <InfoBox
          type="info"
          msg="Please note, that it may take a few minutes until the activation is verified on the blockchain. Once the transaction is verified, you'll be able to access all course materials."
        />
        <Button
          variant="contrast"
          onClick={() => activateCourseHandler()}
          minWidth="full"
          isLoading={isLoading}
          isWeb3
        >
          {`Activate ${capitalizeFirstLetter(type)}`}
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default ActivatePopup;
